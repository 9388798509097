<template>
  <div class="px-4 pb-20 min-h-screen">
    <div class="w-full m-auto" style="max-width: 440px">
      <div class="space-y-6">
        <div class="space-y-4">
          <a href="#" class="text-primary-500 flex items-center space-x-1" @click="goBack">
            <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
            <span> {{ $t('system.btns.back') }} </span>
          </a>
          <AtTitle> {{ $t('system.fields.card.payment') }} </AtTitle>
        </div>
        <div class="flex space-x-3 text-gray-500 p-4 border border-gray-100 border-solid rounded-2xl shadow">
          <InformationCircleIcon class="h-6 w-6" style="min-width: 24px" />
          <span class="text-sm">
            {{ $t('system.fields.card.warning') }}
          </span>
        </div>
        <div class="flex flex-col space-y-2 md:space-y-4">
          <template v-if="service.cards.get.loading">
            <AtSkeleton style="height: 96px" />
          </template>
          <template v-else>
            <component
              v-for="(card, index) in service.cards.get.response"
              :key="card?.id || index"
              :is="selectedCard?.id === card?.id ? 'div' : 'button'"
              class="border border-transparent rounded-2xl text-left"
              :class="{
                'border-primary-600': selectedCard?.id === card?.id,
              }"
              @click="selectCard(card)"
            >
              <AppPaymentCard :card="card">
                <template v-if="selectedCard?.id === card?.id" #end>
                  <CheckCircleIcon class="h-6 w-6 text-primary-600" style="min-width: 24px" />
                </template>
              </AppPaymentCard>
            </component>
            <router-link
              :to="{ name: 'App.Cards.Add' }"
              class="bg-gray-100 p-4 md:p-6 rounded-2xl flex items-center justify-between space-x-2 hover:bg-gray-200"
            >
              <div class="space-x-4 flex items-center">
                <div
                  class="flex items-center justify-center rounded-lg border border-gray-300 text-gray-500"
                  :style="{
                    width: '64px',
                    height: '48px',
                  }"
                >
                  <PlusCircleIcon class="h-6 w-6" style="min-width: 24px" />
                </div>
                <div class="flex justify-center flex-col space-y-1">
                  <div class="font-medium">
                    {{ $t('app.btns.profile.cards.add') }}
                  </div>
                </div>
              </div>
              <ChevronRightIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
            </router-link>
          </template>
        </div>
        <BaseAlert :alert="service.cards.set.alert" :errors="service.cards.set.errors" />
        <AtButton
          :disabled="!isChanged || service.cards.get?.loading"
          :loading="service.cards.set?.loading"
          color="primary"
          size="lg"
          class="w-full"
          @click="changeMainCard"
        >
          {{ $t('system.btns.save') }}
        </AtButton>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter: (_, from, next) => {
      next(vm => {
        vm.$nextTick(function () {
          const { name, params, query } = from;
          this.prevRoute = {
            name,
            params,
            query,
          };
        });
      });
    },
  };
</script>

<script setup>
  import { computed, reactive, onBeforeMount, ref, inject } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { InformationCircleIcon, PlusCircleIcon } from '@heroicons/vue/24/outline';
  import { ChevronRightIcon, CheckCircleIcon, ChevronLeftIcon } from '@heroicons/vue/20/solid';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import { getCards, setMainCard } from '~/services/profile.api';
  import { useService } from '~/hooks/useService';
  import AppPaymentCard from '~/components/app/AppPaymentCard.vue';
  import { $_at_user } from '~/shared/at-user';

  const { t } = useI18n();

  const route = useRoute();
  const router = useRouter();
  const service = {
    cards: {
      get: reactive(useService(getCards)),
      set: reactive(useService(setMainCard)),
    },
  };
  const $toast = inject('$toast');

  const selectedCard = ref(null);
  const prevRoute = ref({});

  const installmentId = computed(() => route?.params?.id);
  const mainCard = computed(() => service.cards.get?.response?.find(card => card?.is_main));
  const isChanged = computed(() => mainCard.value?.id !== selectedCard.value?.id);

  const selectCard = card => {
    if (card?.id === selectedCard.value?.id || service.cards.set?.loading) return;
    selectedCard.value = { ...card };
  };
  const changeMainCard = () => {
    service.cards.set.execute(selectedCard.value?.id).then(() => {
      $toast.success({
        title: t('system.fields.card.mainCardChanged'),
        subTitle: t('system.fields.card.cardChangedForAll'),
      });
      router.push({
        name: 'Installments.Show',
        params: { ...route.params, id: installmentId.value },
        query: { ...route.query },
      });
    });
  };
  const goBack = () => {
    const { name, params, query } = prevRoute.value;
    if (['Installments.Show.Custom'].includes(name)) {
      router.go(-1);
    } else {
      router.push({
        name: 'Installments.Show',
        params,
        query,
      });
    }
  };

  onBeforeMount(async () => {
    await service.cards.get.execute($_at_user.value.clientId);

    selectedCard.value = mainCard.value;
  });
</script>
