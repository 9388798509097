<template>
  <AtContainer short class="pb-14">
    <div class="space-y-8">
      <div v-if="hasServiceAlert" class="space-y-2">
        <BaseAlert :alert="service.credit.alert" />
        <BaseAlert :alert="service.delays.alert" />
        <BaseAlert :alert="service.cards.alert" />
        <BaseAlert :alert="service.penalty.alert" />
        <BaseAlert :alert="service.application.alert" />
      </div>
      <div class="space-y-4">
        <a href="#" class="text-primary-500 flex items-center space-x-1" @click.prevent="goBack">
          <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
          <span> {{ $t('system.btns.back') }} </span>
        </a>
        <div class="flex items-end gap-2">
          <AtTitle> {{ $t('app.titles.installment.title') }}</AtTitle>
          <span class="text-base leading-6 font-normal text-gray-500"
            >№{{ service?.credit?.response?.contract_number }}</span
          >
        </div>
      </div>
      <div class="grid grid-cols-2 gap-y-8 md:gap-x-16 lg:gap-x-18 xl:gap-x-20">
        <div class="col-span-2 space-y-8 order-1 md:col-span-1 md:order-none">
          <div class="space-y-4 md:space-y-0">
            <div class="md:hidden text-xl font-bold">
              {{ $t('app.titles.installment.charts.payments') }}
            </div>
            <template v-if="service.credit.loading">
              <div class="space-y-3">
                <AtSkeleton style="height: 94px" />
                <AtSkeleton style="height: 94px" />
                <AtSkeleton style="height: 94px" />
              </div>
            </template>
            <template v-else>
              <template v-for="(payments, year) in paymentsWithDelay" :key="year">
                <div class="text-sm font-medium text-gray-500 my-2">
                  {{ year }} {{ $t('system.fields.birth.year').toLowerCase() }}
                </div>
                <div>
                  <InstallmentsPaymentItem
                    v-for="(payment, paymentIdx) in payments"
                    :key="paymentIdx"
                    :payment="payment"
                    :type="payment._type"
                    :can-pay="payment._canPay"
                    :clickable="
                      payment._type === INSTALLMENT_PAYMENTS_TYPES.delay ||
                      (payment._type === INSTALLMENT_PAYMENTS_TYPES.monthly && payment.paid)
                    "
                    @pay="openPayModal"
                    @click="openDetailPaymentModal(payment)"
                  />
                </div>
              </template>
            </template>
          </div>
          <!-- TODO: v-if (useBreakpoint) or css mediaQueries -->
          <div class="md:hidden space-y-4">
            <div class="space-y-4 lg:flex lg:space-y-0 lg:space-x-4">
              <div class="space-y-6">
                <template v-if="!service?.credit?.response?.pf">
                  <template v-if="service.credit.loading">
                    <AtSkeleton class="w-full" style="height: 96px" />
                  </template>
                  <template v-else-if="!hasExpenses">
                    <button
                      class="w-full flex-grow space-x-4 bg-gray-100 p-4 flex items-center justify-between rounded-2xl lg:p-6 hover:bg-gray-200 text-left"
                      style="min-height: 80px"
                      @click="openPenaltyModal"
                    >
                      <span class="space-x-4 flex items-center">
                        <InformationCircleIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                        <span class="space-y-1 whitespace-nowrap">
                          <div class="font-bold">
                            {{ $t('app.titles.installment.penalties.amount') }}
                          </div>
                          <span class="text-sm">
                            {{ service?.credit?.response?._penalty }}
                            {{ $t('system.titles.sum') }}
                          </span>
                        </span>
                      </span>
                      <ChevronRightIcon class="ml-auto h-6 w-6 text-gray-500" style="min-width: 24px" />
                    </button>
                  </template>
                </template>
                <div class="space-y-2">
                  <template v-if="service.credit.loading">
                    <AtSkeleton class="w-full h-14" />
                  </template>
                  <template v-else>
                    <router-link
                      v-if="!isDelayForbidden"
                      class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-gray-200"
                      :to="{ name: 'Installments.Show.CreateDelay' }"
                    >
                      <div class="flex items-center space-x-4">
                        <InformationCircleIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                        <div class="font-bold">{{ $t('app.fields.delays.title') }}</div>
                      </div>
                      <ChevronRightIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
                    </router-link>
                  </template>
                  <template v-if="service.credit.loading || service.application.loading">
                    <AtSkeleton class="w-full h-14" />
                  </template>
                  <template v-else-if="service.credit.response">
                    <InstallmentsFileContract
                      :application="service.application.response"
                      :credit-type="service?.credit?.response?.type"
                      :credit-id="service?.credit?.response?.id"
                    />
                  </template>
                  <template v-if="service.credit.loading || service.certificate.loading">
                    <AtSkeleton class="w-full h-14" />
                  </template>
                  <template v-else-if="certificateOfClosureLink">
                    <InstallmentsCertificateOfClosure :link="certificateOfClosureLink" />
                  </template>
                  <template v-if="service.credit.loading">
                    <AtSkeleton class="w-full h-14" />
                  </template>
                  <template v-else>
                    <router-link
                      :to="{ name: 'Installments.Show.CreateReject' }"
                      class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-gray-200"
                    >
                      <div class="flex items-center space-x-4">
                        <ArrowPathIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                        <div class="font-bold">{{ $t('app.reject.title') }}</div>
                      </div>
                      <ChevronRightIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
                    </router-link>
                  </template>
                  <template v-if="service.credit.loading">
                    <AtSkeleton class="w-full h-14" />
                  </template>
                  <template v-else>
                    <router-link
                      class="w-full text-left bg-red-50 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-red-200"
                      :to="{ name: 'Installments.Show.CreateProblemCase' }"
                    >
                      <div class="flex items-center space-x-4">
                        <HandThumbDownIcon class="h-6 w-6 text-red-500" style="min-width: 24px" />
                        <div class="font-bold text-red-500">{{ $t('app.problemCase.panel.title') }}</div>
                      </div>
                      <ChevronRightIcon class="h-5 w-5 text-red-500" style="min-width: 24px" />
                    </router-link>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-2 space-y-8 md:col-span-1">
          <template v-if="service.credit.loading">
            <AtSkeleton style="height: 250px" />
          </template>
          <template v-else>
            <div class="space-y-6">
              <AppExpensesPanel v-if="hasExpenses" :expenses="expensesElements" :total-expenses="totalExpensesAmount" />
              <div v-if="isOverdue" class="bg-orange-500 rounded-2xl py-5 px-4 text-white md:p-6 space-y-2">
                <div class="text-lg font-bold">
                  {{
                    $t('app.titles.installment.delayed.title', {
                      days: creditStatus === CREDIT_STATUSES.problem ? '8+' : '1+',
                    })
                  }}
                </div>
              </div>
              <div class="bg-primary-600 rounded-2xl py-5 divide-y divide-primary-700 text-white md:py-6">
                <div class="p-4 pt-0 md:p-6 md:pt-0">
                  <div class="flex items-center justify-between">
                    <div class="space-y-1">
                      <div class="text-xl font-bold">
                        {{ service?.credit?.response?._total_amount }}
                        {{ $t('system.titles.sum') }}
                      </div>
                      <div class="text-sm">
                        {{ service?.credit?.response?._remainder }}
                        {{ $t('system.titles.sum') }} /
                        {{ $t('system.fields.birth.month').toLowerCase() }}
                      </div>
                    </div>
                    <div
                      class="h-12 w-12 rounded-full flex items-center justify-center"
                      style="background: rgba(255, 255, 255, 0.2)"
                    >
                      <img :src="alifLogoLight" alt="Alif-logo" />
                    </div>
                  </div>
                </div>
                <div class="p-4 pb-0 md:px-6">
                  <div class="space-y-6">
                    <div class="grid grid-cols-2">
                      <div class="col-span-1">
                        <div class="font-bold">
                          {{ $t('app.doughnut.left') }}
                        </div>
                        <div class="text-sm">
                          {{ leftToPay }}
                          {{ $t('system.titles.sum') }}
                        </div>
                      </div>
                      <div v-if="creditStatus !== CREDIT_STATUSES.closed" class="col-span-1">
                        <div class="font-bold">
                          {{ $t('app.titles.installment.next') }}
                        </div>
                        <div class="text-sm">
                          <template v-if="daysToPay > 0">
                            {{
                              $t('app.titles.installment.after', {
                                count: $t('system.day', { count: daysToPay, postfixUz: 'dan' }),
                              })
                            }}
                          </template>
                          <template v-else>
                            {{
                              $t('app.titles.installment.before', {
                                day: $t('system.day', { count: Math.abs(daysToPay) }),
                              })
                            }}
                          </template>
                        </div>
                      </div>
                    </div>
                    <AtButton class="w-full" @click="modals.detail.isOpen = true">
                      {{ $t('app.titles.installment.detail.title') }}
                    </AtButton>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="space-y-4">
            <div class="text-xl font-bold">
              {{ $t('system.fields.card.payment') }}
            </div>
            <template v-if="service.cards.loading">
              <AtSkeleton style="height: 96px" />
            </template>
            <template v-else-if="mainCard">
              <div>
                <router-link
                  :to="{
                    name: 'Installments.Show.Cards',
                    params: { id: installmentId },
                  }"
                >
                  <AppPaymentCard :card="mainCard">
                    <template #end>
                      <ChevronRightIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                    </template>
                  </AppPaymentCard>
                </router-link>
              </div>
            </template>
            <template v-else>
              {{ $t('app.titles.app.installments.noMainCard') }}
            </template>
          </div>
          <!-- TODO: v-if (useBreakpoint) or css mediaQueries -->
          <div class="hidden md:block space-y-4">
            <div class="space-y-8">
              <template v-if="!service?.credit?.response?.pf">
                <template v-if="service.credit.loading">
                  <AtSkeleton class="w-full" style="height: 96px" />
                </template>
                <template v-else-if="!hasExpenses">
                  <button
                    class="w-full flex-grow space-x-4 bg-gray-100 p-4 flex items-center justify-between rounded-2xl lg:p-6 hover:bg-gray-200 text-left"
                    style="min-height: 80px"
                    @click="openPenaltyModal"
                  >
                    <span class="space-x-4 flex items-center">
                      <InformationCircleIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                      <div class="space-y-1 whitespace-nowrap">
                        <div class="font-bold">
                          {{ $t('app.titles.installment.penalties.amount') }}
                        </div>
                        <span class="text-sm">
                          {{ service?.credit?.response?._penalty }}
                          {{ $t('system.titles.sum') }}
                        </span>
                      </div>
                    </span>
                    <ChevronRightIcon class="ml-auto h-6 w-6 text-gray-500" style="min-width: 24px" />
                  </button>
                </template>
              </template>
              <div class="space-y-2">
                <template v-if="service.credit.loading">
                  <AtSkeleton class="w-full h-14" />
                </template>
                <template v-else>
                  <router-link
                    v-if="!isDelayForbidden"
                    class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-gray-200"
                    :to="{ name: 'Installments.Show.CreateDelay' }"
                  >
                    <div class="flex items-center space-x-4">
                      <InformationCircleIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                      <div class="font-bold">{{ $t('app.fields.delays.title') }}</div>
                    </div>
                    <ChevronRightIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
                  </router-link>
                </template>
                <template v-if="service.credit.loading || service.application.loading">
                  <AtSkeleton class="w-full h-14" />
                </template>
                <template v-else-if="service.credit.response">
                  <InstallmentsFileContract
                    :application="service.application.response"
                    :credit-type="service?.credit?.response?.type"
                    :credit-id="service?.credit?.response?.id"
                  />
                </template>
                <template v-if="service.credit.loading || service.certificate.loading">
                  <AtSkeleton class="w-full h-14" />
                </template>
                <template v-else-if="certificateOfClosureLink">
                  <InstallmentsCertificateOfClosure :link="certificateOfClosureLink" />
                </template>
                <template v-if="service.credit.loading">
                  <AtSkeleton class="w-full h-14" />
                </template>
                <template v-else>
                  <router-link
                    :to="{ name: 'Installments.Show.CreateReject' }"
                    class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-gray-200"
                  >
                    <div class="flex items-center space-x-4">
                      <ArrowPathIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                      <div class="font-bold">{{ $t('app.reject.title') }}</div>
                    </div>
                    <ChevronRightIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
                  </router-link>
                </template>
                <template v-if="service.credit.loading">
                  <AtSkeleton class="w-full h-14" />
                </template>
                <template v-else>
                  <router-link
                    class="w-full text-left bg-red-50 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-4 hover:bg-red-200"
                    :to="{ name: 'Installments.Show.CreateProblemCase' }"
                  >
                    <div class="flex items-center space-x-4">
                      <HandThumbDownIcon class="h-6 w-6 text-red-500" style="min-width: 24px" />
                      <div class="font-bold text-red-500">{{ $t('app.problemCase.panel.title') }}</div>
                    </div>
                    <ChevronRightIcon class="h-5 w-5 text-red-500" style="min-width: 24px" />
                  </router-link>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AtContainer>
  <InstallmentsPayModal
    v-model="modals.pay.isOpen"
    :payment="modals.pay.payment"
    :card="mainCard"
    :installment-id="service?.credit?.response?.id"
    @pay="onPay"
  />
  <InstallmentsDetailModal
    v-model="modals.detail.isOpen"
    :installment="paymentDetails"
    :receiptLink="service?.credit?.response?.gnk_receipt"
  />
  <InstallmentsPenaltyModal
    v-model="modals.penalty.isOpen"
    :items="penaltyTableItems"
    :loading="service.penalty?.loading"
  />
  <InstallmentsPaymentDetailModal
    v-model="modals.paymentDetail.isOpen"
    :details="modals.paymentDetail.details"
    :title="modals.paymentDetail.title"
  />
</template>

<script>
  export default {
    beforeRouteEnter: (_, from, next) => {
      next(vm => {
        vm.$nextTick(function () {
          const { name, params, query } = from;
          this.prevRoute = {
            name,
            params,
            query,
          };
        });
      });
    },
  };
</script>

<script setup>
  import { reactive, ref, computed, onBeforeMount, inject } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import {
    InformationCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ArrowPathIcon,
    HandThumbDownIcon,
  } from '@heroicons/vue/24/outline';
  import { differenceInCalendarDays } from 'date-fns';
  import { useI18n } from 'vue-i18n';

  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtTitle from '~/components/ui/AtTitle.vue';
  import { useService } from '~/hooks/useService';
  import { getCertificateOfClosure, getCredit, getCreditPenalty, getDelays } from '~/services/credits.api';
  import { getCards, getApplication } from '~/services/profile.api';
  import InstallmentsPaymentItem from '~/components/installments/InstallmentsPaymentItem.vue';
  import { INSTALLMENT_PAYMENTS_TYPES } from '~/constants/installments';
  import InstallmentsPayModal from '~/components/installments/InstallmentsPayModal.vue';
  import alifLogoLight from '~/assets/alif-logo-light.svg';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import { formatMoney } from '~/utils';
  import AppPaymentCard from '~/components/app/AppPaymentCard.vue';
  import InstallmentsDetailModal from '~/components/installments/InstallmentsDetailModal.vue';
  import InstallmentsPenaltyModal from '~/components/installments/InstallmentsPenaltyModal.vue';
  import InstallmentsPaymentDetailModal from '~/components/installments/InstallmentsPaymentDetailModal.vue';
  import InstallmentsFileContract from '~/components/installments/InstallmentsFileContract.vue';
  import InstallmentsCertificateOfClosure from '~/components/installments/InstallmentsCertificateOfClosure.vue';
  import { transformDate } from '~/utils';
  import { CREDIT_STATUSES } from '@/constants';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import { $_at_user } from '~/shared/at-user';
  import AppExpensesPanel from '~/components/app/AppExpensesPanel.vue';

  const { t } = useI18n();
  const DELAYS_PER_PAGE = 100;
  const $toast = inject('$toast');

  const route = useRoute();
  const router = useRouter();
  const service = {
    credit: reactive(useService(getCredit)),
    delays: reactive(useService(getDelays)),
    cards: reactive(useService(getCards)),
    penalty: reactive(useService(getCreditPenalty)),
    application: reactive(useService(getApplication)),
    certificate: reactive(useService(getCertificateOfClosure)),
  };
  const hasServiceAlert = computed(() =>
    Boolean(service.credit.alert || service.delays.alert || service.cards.alert || service.penalty.alert)
  );

  const paymentsWithDelay = ref([]);
  const prevRoute = ref({});
  const modals = reactive({
    pay: {
      isOpen: false,
      payment: null,
    },
    detail: {
      isOpen: false,
    },
    penalty: {
      isOpen: false,
    },
    paymentDetail: {
      isOpen: false,
      title: null,
      details: [],
    },
  });

  const leftToPay = computed(() => {
    return formatMoney(
      Number(
        ((service?.credit?.response?.total_amount - service?.credit?.response?.total_paid_amount) / 100).toFixed(2)
      ),
      2
    );
  });
  const daysToPay = computed(() => {
    return differenceInCalendarDays(new Date(service?.credit?.response?.next_payment?.payment_date), new Date());
  });
  const mainCard = computed(() => {
    return service?.cards?.response?.find(card => card.is_main);
  });
  const creditStatus = computed(() => {
    return service?.credit?.response?.status_key;
  });
  const isDelayForbidden = computed(() => {
    return [CREDIT_STATUSES.closed, CREDIT_STATUSES.deleted, CREDIT_STATUSES.completed].includes(creditStatus.value);
  });
  const isOverdue = computed(() => [CREDIT_STATUSES.problem, CREDIT_STATUSES.overdue].includes(creditStatus.value));
  const installmentId = computed(() => route?.params?.id);
  const penaltyTableItems = computed(
    () =>
      service.penalty?.response?.map(penalty => ({
        date: transformDate(penalty?.date, {
          dateFormat: 'dd MMMM',
        }),
        amount: penalty?._formatted_amount,
      })) || []
  );
  const expensesElements = computed(() => {
    const {
      court_penalty_expense,
      notary_fee_expense,
      notary_penalty_expense,
      postal_service_expense,
      state_duty_expense,
    } = service?.credit?.response?.additional_expenses || {};
    const elements = [
      {
        title: t('app.expenses.modal.notaryFeeExpense'),
        value: notary_fee_expense ? (Number(notary_fee_expense?.unpaid_amount) / 100).toFixed(2) : null,
      },
      {
        title: t('app.expenses.modal.stateDutyExpense'),
        value: state_duty_expense ? (Number(state_duty_expense?.unpaid_amount) / 100).toFixed(2) : null,
      },
      {
        title: t('app.expenses.modal.postalServiceExpense'),
        value: postal_service_expense ? (Number(postal_service_expense?.unpaid_amount) / 100).toFixed(2) : null,
      },
      {
        title: t('app.expenses.modal.courtPenaltyExpense'),
        value: court_penalty_expense ? (Number(court_penalty_expense?.unpaid_amount) / 100).toFixed(2) : null,
      },
      {
        title: t('app.expenses.modal.notaryPenaltyExpense'),
        value: notary_penalty_expense ? (Number(notary_penalty_expense?.unpaid_amount) / 100).toFixed(2) : null,
      },
    ];

    return elements.filter(element => element.value);
  });
  const totalExpensesAmount = computed(
    () => expensesElements.value.reduce((acc, item) => acc + Number(item.value), 0) || 0
  );
  const hasExpenses = computed(() => expensesElements.value.length);

  const paymentDetails = computed(() => {
    return Object.assign({}, { ...service.credit?.response, commission: service.application?.response?.commission });
  });

  const fetchInstallment = async () => {
    // TODO: delay pagination
    await Promise.all([
      service.credit.execute(installmentId.value),
      service.delays.execute(installmentId.value, {
        per_page: DELAYS_PER_PAGE,
      }),
    ]);

    paymentsWithDelay.value = service?.credit?.response?.schedule_cup_payments?.reduce((acc, payment, i) => {
      const prevDate = new Date(service?.credit?.response?.schedule_cup_payments[i - 1]?.payment_date);
      const nextDate = new Date(payment.payment_date);

      const paymentYear = new Date(payment?.payment_date).getFullYear();
      const template = {
        [paymentYear]: [{ ...payment, _type: payment?.payment_type }],
      };
      const result = { ...acc };

      service.delays.response.data.reverse().forEach(delay => {
        const delayDate = new Date(delay.date_from);
        if (delayDate > prevDate && delayDate <= nextDate) {
          const delayYear = new Date(delay?._payment_date).getFullYear();
          const delayItem = { ...delay, _type: 'delay' };

          if (Object.prototype.hasOwnProperty.call(template, delayYear)) {
            template[delayYear] = [delayItem, ...template[delayYear]];
          } else {
            template[delayYear] = [delayItem];
          }
        }
      });

      Object.keys(template).forEach(item => {
        if (Object.prototype.hasOwnProperty.call(acc, item)) {
          result[item] = [...result[item], ...template[item]];
        } else {
          result[item] = [...template[item]];
        }
      });

      return result;
    }, {});
  };
  const goBack = () => {
    const { name, params, query } = prevRoute.value;
    if (['App.Index', 'Installments.Index'].includes(name)) {
      router.push({
        name: name,
        params: { ...params, id: params?.id },
        query,
      });
    } else {
      router.push({
        name: 'App.Index',
        params,
        query,
      });
    }
  };
  const openPayModal = payment => {
    modals.pay.isOpen = true;
    modals.pay.payment = payment;
  };
  const onPay = () => {
    fetchInstallment().then(() => {
      $toast.success({ title: t('app.titles.profile.cards.payments.paid') });
    });
  };
  const openPenaltyModal = () => {
    modals.penalty.isOpen = true;
    service.penalty.execute(installmentId.value);
  };
  const openDetailPaymentModal = payment => {
    if (
      payment._type === INSTALLMENT_PAYMENTS_TYPES.delay ||
      (payment._type === INSTALLMENT_PAYMENTS_TYPES.monthly && payment.paid)
    ) {
      modals.paymentDetail.isOpen = true;

      switch (payment._type) {
        case INSTALLMENT_PAYMENTS_TYPES.delay:
          modals.paymentDetail.title = t('app.titles.installment.delayed.detail');
          modals.paymentDetail.details = [
            {
              title: t('app.titles.installment.dates.payment'),
              value: transformDate(payment?.date, {
                dateFormat: 'dd MMM yyyy',
              }),
            },
            {
              title: t('app.titles.installment.dates.requested'),
              value: transformDate(payment?.credit_delay_case?.created_at, {
                dateFormat: 'dd MMM yyyy',
              }),
            },
            {
              title: t('app.titles.installment.dates.overdue'),
              value: t('system.day', { count: payment?.days || 0 }),
            },
            {
              title: t('app.titles.installment.dates.active'),
              value: transformDate(payment.date_to, {
                dateFormat: 'dd MMM yyyy',
              }),
            },
            {
              title: t('app.titles.installment.dates.shift'),
              value: payment?.is_shifted ? t('system.titles.yes') : t('system.titles.no'),
            },
            {
              title: t('app.titles.installment.dates.delayed'),
              value: t('system.day', { count: payment?.days_fact || 0, postfixUz: 'ga' }),
            },
          ];
          break;
        case INSTALLMENT_PAYMENTS_TYPES.monthly:
          modals.paymentDetail.title = t('app.titles.installment.payment.title');
          modals.paymentDetail.details = [
            {
              title: t('app.titles.profile.cards.payments.initial'),
              value: transformDate(payment?.payment_date, {
                dateFormat: 'dd MMMM',
              }),
            },
            {
              title: t('app.titles.profile.cards.payments.actual'),
              value: transformDate(payment?.fully_paid_date, {
                dateFormat: 'dd MMMM',
              }),
            },
          ];
          break;
        default:
          break;
      }
    }
  };

  const certificateOfClosureLink = ref(null);
  const fetchCertificateOfClosure = () => {
    return service.certificate.execute(installmentId.value).then(response => {
      const blob = new Blob([response?.data], { type: 'application/pdf' });
      certificateOfClosureLink.value = window.URL.createObjectURL(blob);
    });
  };

  onBeforeMount(async () => {
    await Promise.all([fetchInstallment(), service.cards.execute($_at_user.value.clientId)]);

    if (service.credit.response?.application_id) {
      await service.application.execute(service.credit.response?.application_id);
    }
    if (creditStatus.value === CREDIT_STATUSES.closed) await fetchCertificateOfClosure();
  });
</script>
