import { checkType } from '~/plugins/aliftech-ui/utils';
/**
 * Number.prototype.format(n, x, s, c)
 * @param integer number: input number
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
const formatMoney = (number, n = 2, x = 3, s = ' ', c = ',') => {
  if (!checkType(number, 'number')) return number;
  let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = number.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export { formatMoney };
