<template>
  <div class="px-4 min-h-screen">
    <div class="w-full m-auto" style="max-width: 440px">
      <div class="space-y-6">
        <div class="space-y-4">
          <router-link
            href="#"
            class="text-primary-500 flex items-center space-x-1"
            :to="{ name: 'Installments.Show', params: { id: installmentId } }"
          >
            <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
            <span> {{ $t('system.btns.back') }} </span>
          </router-link>
          <AtTitle>
            {{ $t('app.titles.installment.payment.setAmount') }}
          </AtTitle>
        </div>
        <div class="space-y-2">
          <div>{{ $t('app.titles.applications.modal.monthlyPayment') }}</div>
          <template v-if="service.credit.loading">
            <AtSkeleton class="h-7" />
          </template>
          <template v-else>
            <div class="text-xl font-bold">
              {{ service?.credit?.response?._remainder }}
              {{ $t('system.titles.sum') }}
            </div>
          </template>
        </div>
        <div class="space-y-2">
          <div>{{ $t('system.fields.card.payment') }}</div>
          <template v-if="service.cards.loading">
            <AtSkeleton style="height: 96px" />
          </template>
          <template v-else>
            <div>
              <router-link
                :to="{
                  name: 'Installments.Show.Cards',
                  params: { id: installmentId },
                }"
              >
                <AppPaymentCard :card="mainCard">
                  <template #end>
                    <ChevronRightIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                  </template>
                </AppPaymentCard>
              </router-link>
            </div>
          </template>
        </div>
        <AtInput
          v-model.number="amount"
          type="number"
          :label="$t('app.titles.installment.payment.amount')"
          :disabled="service.credit.loading"
          :error="hasLocalError"
          @update:model-value="validate"
          @input="onAmountInput"
        >
          <template #addOnAfter>
            <div class="text-gray-500">{{ $t('system.titles.sum') }}</div>
          </template>
        </AtInput>
        <span v-if="localError" class="text-red-600 text-sm">
          {{ localError }}
        </span>
        <BaseAlert :alert="service.pay.alert" :errors="service.pay.errors" />
        <AtButton
          :disabled="service.credit.loading"
          :loading="service.pay.loading"
          class="w-full"
          color="primary"
          size="lg"
          @click="pay"
        >
          {{ $t('app.btns.pay') }}
        </AtButton>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, computed, onBeforeMount, ref, inject } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import { ChevronRightIcon } from '@heroicons/vue/20/solid';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import AppPaymentCard from '~/components/app/AppPaymentCard.vue';
  import { useService } from '~/hooks/useService';
  import { getCards } from '~/services/profile.api';
  import { getCredit, payCredit } from '~/services/credits.api';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import { $_at_user } from '~/shared/at-user';

  const $toast = inject('$toast');
  const { t } = useI18n();

  const route = useRoute();
  const router = useRouter();
  const service = {
    cards: reactive(useService(getCards)),
    credit: reactive(useService(getCredit)),
    pay: reactive(useService(payCredit)),
  };

  const amount = ref(null);
  const localError = ref(null);

  const installmentId = computed(() => route?.params?.id);
  const mainCard = computed(() => {
    return service?.cards?.response?.find(card => card.is_main);
  });
  const hasLocalError = computed(() => {
    return Boolean(localError.value);
  });

  const validate = () => {
    if (!amount.value || amount.value <= 0) {
      localError.value = t('validations.amountMinLength', { amount: 0 });
    } else {
      localError.value = null;
      return false;
    }
  };
  const pay = () => {
    if (!validate() && hasLocalError.value) return;
    service.pay.execute(installmentId.value, Number(amount.value) * 100).then(() => {
      $toast.success({ title: t('app.titles.profile.cards.payments.paid') });
      router.push({
        name: 'Installments.Show',
        params: { ...route.params, id: installmentId.value },
        query: { ...route.query },
      });
    });
  };

  const onAmountInput = e => {
    const value = e.target.value;

    if (value.includes('.')) {
      const parts = value.split('.');

      if (parts[1].length > 2) {
        const fixedAmount = Number(value).toFixed(2);
        e.target.value = fixedAmount;
        amount.value = fixedAmount;
      }
    }
  };

  onBeforeMount(() => {
    Promise.all([service.cards.execute($_at_user.value.clientId), service.credit.execute(installmentId.value)]);
  });
</script>
