<template>
  <div class="border border-gray-200 shadow-sm rounded-2xl divide-y divide-gray-200">
    <div class="py-3.5 px-4">
      <div class="text-base leading-6 font-medium">{{ $t('app.reject.hint.title') }}</div>
    </div>
    <div class="p-4 space-y-4">
      <div class="space-x-3 flex items-center">
        <div class="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center" style="min-width: 40px">
          <CursorArrowRaysIcon class="text-gray-500 w-5 h-5" style="min-width: 20px" />
        </div>
        <div class="space-y-1">
          <div class="text-sm leading-5">{{ $t('app.reject.hint.items[0]') }}</div>
        </div>
      </div>
      <div class="space-x-3 flex">
        <div class="w-10 h-10 bg-gray-100 rounded-full flex items-center justify-center" style="min-width: 40px">
          <ArrowUpTrayIcon class="text-gray-500 w-5 h-5" style="min-width: 20px" />
        </div>
        <div class="space-y-1">
          <div class="text-sm leading-5">{{ $t('app.reject.hint.items[1]') }}</div>
          <button class="text-sm leading-5 text-primary-600 font-medium" @click="modals.example.isOpen = true">
            {{ $t('app.reject.hint.showExample') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <AtModal v-model="modals.example.isOpen" size="sm" :title="$t('app.reject.hint.modal.title')">
    <div class="space-y-5">
      <div class="grid grid-cols-2 gap-y-1.5 gap-x-3">
        <div class="col-span-1">
          <div class="text-xs">{{ $t('app.reject.hint.modal.exampleTitles[0]') }}</div>
        </div>
        <div class="col-span-1">
          <div class="text-xs">{{ $t('app.reject.hint.modal.exampleTitles[1]') }}</div>
        </div>
        <img class="w-full object-cover rounded-lg h-56 border border-gray-200" :src="RefundExampleImage1" alt="" />
        <img class="w-full object-cover rounded-lg h-56 border border-gray-200" :src="RefundExampleImage2" alt="" />
      </div>
      <AtButton size="lg" class="w-full" @click="modals.example.isOpen = false">{{
        $t('app.reject.hint.modal.button')
      }}</AtButton>
    </div>
  </AtModal>
</template>

<script setup>
  import { reactive } from 'vue';
  import { CursorArrowRaysIcon, ArrowUpTrayIcon } from '@heroicons/vue/20/solid';

  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';

  import RefundExampleImage1 from '~/assets/images/refund-example-1.png';
  import RefundExampleImage2 from '~/assets/images/refund-example-2.png';

  const modals = reactive({
    example: {
      isOpen: false,
    },
  });
</script>

<script>
  export default {
    name: 'InstallmentsRefundHintPanel',
  };
</script>

<style scoped></style>
