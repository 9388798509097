<template>
  <div
    class="h-20 fixed bottom-0 bg-gray-100 py-2.5 px-4 flex items-center gap-2.5 z-50 w-full"
    v-if="isBannerVisible && route.name === LandingRouteName"
  >
    <XMarkIcon class="block h-5 w-5 cursor-pointer flex-shrink-0" aria-hidden="true" @click="isBannerVisible = false" />
    <AlifMobileIcon class="text-primary-600 w-10 h-10 flex-shrink-0" />
    <span class="text-sm leading-5 font-normal text-gray-900 mr-auto">{{ $t('landing.titles.downloadMobile') }}</span>
    <AtButton
      class="whitespace-nowrap"
      style="background-color: #37a134"
      color="primary"
      rounded
      to="https://app.adjust.com/97orbzg?campaign=mobi&adgroup=nasiya&creative=landing"
      href="https://app.adjust.com/97orbzg?campaign=mobi&adgroup=nasiya&creative=landing"
      target="_blank"
      >{{ $t('landing.buttons.download_mobile') }}</AtButton
    >
  </div>
  <AppAuthHeader
    v-if="route.name === LandingRouteName || !isAuth || !isUserOnboarded"
    :fixed="fixedHeader"
    :compact="compactHeader"
    :is-auth="isAuth"
  />
  <div class="min-h-screen bg-white flex flex-col">
    <template v-if="LandingRouteName !== route.name && isAuth && isUserOnboarded">
      <BaseAppNavBar
        :links="[
          { title: $t('app.header.main'), to: { name: 'App.Index' } },
          { title: $t('app.header.shops'), to: { name: 'Shops.Index' } },
          {
            title: $t('app.header.applications'),
            to: { name: 'App.Applications' },
          },
          {
            title: $t('app.header.installments'),
            to: { name: 'Installments.Index' },
          },
          { title: $t('app.header.cards'), to: { name: 'App.Cards' } },
          { title: $t('app.header.aboutUs'), to: { name: 'Landing' } },
        ]"
        :user="at_user"
        @logout="logout"
      >
        <template #action>
          <BaseLangChange />
        </template>
      </BaseAppNavBar>
    </template>
    <main class="flex-grow pt-12 flex flex-col">
      <router-view @intersectionLandingHeroBlock="onIntersectionLandingHeroBlock" />
    </main>
    <BaseFooter :is-banner-visible="Boolean(isBannerVisible)" />
  </div>
</template>

<script>
  import { ref, computed, onBeforeMount, watch, onBeforeUnmount } from 'vue';
  import { useRoute, onBeforeRouteUpdate } from 'vue-router';

  import { logout as serviceLogout } from '~/services/auth.api';
  import BaseAppNavBar from '~/components/base/BaseAppNavBar.vue';
  import { $_at_user } from '~/shared/at-user';
  import BaseLangChange from '~/components/base/BaseLangChange.vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AlifMobileIcon from '~/components/icons/AlifMobileIcon.vue';
  import { hasMobileDevice } from '~/utils/hasMobileDevice';
  import AppAuthHeader from '~/components/app/AppAuthHeader.vue';
  import BaseFooter from '~/components/base/BaseFooter.vue';
  import { CLIENT_STATUSES } from '~/constants';

  export default {
    components: {
      AppAuthHeader,
      AlifMobileIcon,
      BaseAppNavBar,
      BaseLangChange,
      XMarkIcon,
      AtButton,
      BaseFooter,
    },
    setup() {
      const route = useRoute();
      const LandingRouteName = 'Landing';

      const logout = () => {
        serviceLogout();
      };

      const isBannerVisible = ref(hasMobileDevice());

      const isAuth = computed(() => Boolean($_at_user.value));

      const isUserOnboarded = computed(
        () => ![CLIENT_STATUSES.created, CLIENT_STATUSES.fixed].includes($_at_user.value?.status_key)
      );

      const fixedHeader = ref(true);
      const compactHeader = ref(false);
      const onIntersectionLandingHeroBlock = isIntersecting => {
        if (!isAuth.value) {
          fixedHeader.value = !isIntersecting;
          compactHeader.value = isIntersecting;
        }
      };
      onBeforeMount(() => {
        if (route.name === LandingRouteName && !isAuth.value) {
          fixedHeader.value = false;
          compactHeader.value = true;
        }
      });
      onBeforeRouteUpdate(() => {
        onIntersectionLandingHeroBlock(!isAuth.value);

        const isLandingRoute = route.name !== LandingRouteName;
        toggleBodyBannerClass(isLandingRoute);
      });
      onBeforeUnmount(() => toggleBodyBannerClass(false));
      const toggleBodyBannerClass = value => document.body.classList.toggle('landing-alifmobi-banner', value);

      watch(
        isBannerVisible,
        value => {
          toggleBodyBannerClass(value);
        },
        { immediate: true }
      );

      return {
        onIntersectionLandingHeroBlock,
        isAuth,
        fixedHeader,
        compactHeader,
        isBannerVisible,
        logout,
        at_user: $_at_user,
        route,
        LandingRouteName,
        isUserOnboarded,
      };
    },
  };
</script>
