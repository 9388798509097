<template>
  <div class="p-4 lg:p-6 bg-red-500 rounded-2xl relative">
    <div class="space-y-6 relative z-10">
      <div class="text-base font-bold text-white lg:text-lg" data-testid="expenses_title">
        {{ $t('app.expenses.panel.title') }}
      </div>
      <AtButton @click="modals.detail.isOpen = true" data-testid="expenses_button">{{
        $t('app.expenses.panel.button')
      }}</AtButton>
    </div>
    <div class="absolute -bottom-2 right-4">
      <CourtHammerIcon />
    </div>
  </div>
  <AtModal v-model="modals.detail.isOpen" :title="$t('app.expenses.modal.title')">
    <div class="space-y-5 pt-0.5">
      <div class="flex items-center shadow px-3 py-3.5 rounded-2xl space-x-3 border border-gray-200">
        <div class="flex items-center justify-center h-11 w-11 bg-orange-500 rounded-lg" style="min-width: 44px">
          <CircleStackIcon class="w-5 h-5 text-white" style="min-width: 20px" />
        </div>
        <div class="space-y-1">
          <div class="text-sm text-gray-500">{{ $t('app.expenses.modal.totalAmount') }}</div>
          <div class="text-base font-semibold text-gray-900">{{ formatMoney(totalExpenses) }} сум</div>
        </div>
      </div>
      <AtTable :head="tableHead" :elements="expenses">
        <template #element="{ element }">
          <AtTableItem data-testid="expenses_title_table">
            <div class="text-sm font-medium text-gray-900">{{ element.title }}</div>
          </AtTableItem>
          <AtTableItem data-testid="expenses_sum_table">
            <div class="text-sm text-gray-500">
              {{ formatMoney(Number(element.value)) ?? '—' }} {{ $t('system.titles.sum') }}
            </div>
          </AtTableItem>
        </template>
      </AtTable>
      <AtButton size="lg" block @click="modals.detail.isOpen = false" data-testid="close_modal_button">
        {{ $t('app.expenses.modal.close') }}
      </AtButton>
    </div>
  </AtModal>
</template>

<script setup>
  import { reactive, computed } from 'vue';
  import { CircleStackIcon } from '@heroicons/vue/24/outline';
  import { useI18n } from 'vue-i18n';

  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtTable from '~/plugins/aliftech-ui/components/AtTable/AtTable';
  import AtTableItem from '~/plugins/aliftech-ui/components/AtTableItem/AtTableItem';

  import { formatMoney } from '~/utils';
  import CourtHammerIcon from '~/assets/icons/CourtHammerIcon.vue';

  const { t } = useI18n();
  const tableHead = computed(() => [
    {
      title: t('app.expenses.modal.name'),
    },
    {
      title: t('app.expenses.modal.value'),
    },
  ]);

  defineProps({
    expenses: {
      type: Array,
      default: () => [],
    },
    totalExpenses: {
      type: Number,
      default: 0,
    },
  });

  const modals = reactive({
    detail: {
      isOpen: false,
    },
  });
</script>
