<template>
  <AtContainer short>
    <AtTitle class="mb-4">{{ $t('app.fields.applications.title') }}</AtTitle>
    <AtLoading v-if="service.applications.loading" />
    <div v-else class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <div class="mb-4 sm:col-span-2 lg:col-span-3">
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          <AtSelect v-model="selectedStatus" :options="statusesOptions" />
        </div>
      </div>

      <template v-if="filteredApplictions.length">
        <ApplicationCard v-for="application in filteredApplictions" :key="application.id" :application="application">
          <template #action>
            <AtButton block color="primary" @click="getApplicaionInfo(application)">
              {{ $t('app.fields.applications.moreInfo') }}
            </AtButton>
          </template>
        </ApplicationCard>
      </template>
      <template v-else>
        <div class="text-center space-y-4 py-14 flex flex-col justify-center col-span-3">
          <div class="rounded-full w-16 h-16 flex justify-center items-center bg-gray-100 mx-auto">
            <CircleStackIcon class="w-7 h-7 text-gray-500" />
          </div>
          <p class="text-gray-500">
            {{ $t('app.titles.app.applications.notFound.title') }}
          </p>
        </div>
      </template>
    </div>
    <AtModal size="3xl" v-model="modal.isOpen" :title="$t('app.titles.applications.modal.title')">
      <AtLoading v-if="service.stores.loading && service.stores.response" />
      <div v-else-if="modal.application" class="space-y-6 my-6">
        <AtPanel>
          <p class="text-base text-gray-500">
            {{ $t('app.titles.applications.modal.storeName') }}
          </p>
          <h3 class="mt-1 text-xl leading-6 font-medium text-gray-900">
            {{ service.stores.response?.name }}
          </h3>
        </AtPanel>

        <AtPanel :overflow="false">
          <!-- px-4 sm:px-6 w-full overflow-x-auto py-5 sm:pb-6 -->
          <div class="-mt-5 -mb-6">
            <div class="bg-gray-100 text-center rounded-t-md -mx-4 sm:-mx-6 py-4">
              <p class="text-sm text-gray-500">
                {{ $t('app.titles.applications.modal.monthlyPayment') }}
              </p>
              <AtLoading v-if="service.calculate.loading && service.calculate.response" />
              <h3 v-else class="mt-1 text-xl leading-6 font-medium text-gray-900">
                {{ service.calculate.response?._monthly_payment_amount }}
                {{ $t('system.titles.sum') }}
              </h3>
            </div>
            <div>
              <dl class="grid grid-cols-1 md:grid-cols-3 divide-y divide-gray-200 md:divide-y-0 md:divide-x py-4">
                <div class="py-2">
                  <dt class="text-sm font-normal text-gray-500 text-center">
                    {{ $t('app.titles.applications.modal.month') }}
                  </dt>
                  <dd class="mt-1">
                    <h3 class="text-base font-medium text-gray-900 text-center">
                      {{ modal.application.duration }}
                    </h3>
                  </dd>
                </div>
                <div class="py-2">
                  <dt class="text-sm font-normal text-gray-500 text-center">
                    {{ $t('app.titles.applications.modal.commission') }}
                  </dt>
                  <dd class="mt-1">
                    <h3 class="text-base font-medium text-gray-900 text-center">
                      {{ modal.application.commission }} %
                    </h3>
                  </dd>
                </div>
                <div class="py-2">
                  <dt class="text-sm font-normal text-gray-500 text-center">
                    {{ $t('app.titles.applications.modal.credit') }}
                  </dt>
                  <dd class="mt-1">
                    <AtLoading v-if="service.calculate.loading && service.calculate.response" />
                    <h3 v-else class="text-base font-medium text-gray-900 text-center">
                      {{ service.calculate.response?._total_amount }}
                      {{ $t('system.titles.sum') }}
                    </h3>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </AtPanel>
        <AtTable
          :head="[
            { title: $t('app.titles.applications.modal.table.name') },
            { title: $t('app.titles.applications.modal.table.cost') },
            { title: $t('app.titles.applications.modal.table.quantity') },
          ]"
          :elements="modal.application.items"
        >
          <template #header>
            <h3 class="text-sm text-gray-900 font-medium">
              {{ $t('app.titles.applications.modal.table.title') }}
            </h3>
          </template>
          <template #element="{ element }">
            <AtTableItem>
              {{ element.good_name }}
            </AtTableItem>
            <AtTableItem>
              {{ element._price }}
            </AtTableItem>
            <AtTableItem>
              {{ element.quantity }}
            </AtTableItem>
          </template>
        </AtTable>
      </div>
    </AtModal>
  </AtContainer>
</template>

<script setup>
  import { reactive, ref, computed } from 'vue';

  import { getApplications } from '~/services/profile.api';
  import { getStores } from '~/services/public.api';
  import { calculateCredits } from '~/services/credits.api';

  import { useService } from '~/hooks/useService';
  import { $_at_app } from '~/shared/at-app';

  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';
  import ApplicationCard from '~/components/app/ApplicationCard.vue';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtSelect from '~/plugins/aliftech-ui/components/AtSelect/AtSelect';
  import AtPanel from '~/plugins/aliftech-ui/components/AtPanel/AtPanel';
  import AtTable from '~/plugins/aliftech-ui/components/AtTable/AtTable';
  import AtTableItem from '~/plugins/aliftech-ui/components/AtTableItem/AtTableItem';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import { useI18n } from 'vue-i18n';
  import { CircleStackIcon } from '@heroicons/vue/20/solid';

  const { t } = useI18n({ useScope: 'global' });

  const service = {
    applications: reactive(useService(getApplications)),
    stores: reactive(useService(getStores)),
    calculate: reactive(useService(calculateCredits)),
  };

  service.applications.execute();

  const selectedStatus = ref(null);
  const statusesOptions = computed(() => {
    const statuses = $_at_app.value.application_statuses?.map(status => ({
      title: status?.body,
      value: status?.key,
    }));

    return [{ title: t('app.btns.shops.type.all'), value: null }, ...statuses];
  });
  const filteredApplictions = computed(() => {
    if (selectedStatus.value) {
      return (
        service.applications.response?.data.filter(application => application.status.key === selectedStatus.value) ?? []
      );
    } else return service.applications.response?.data ?? [];
  });

  const modal = reactive({
    isOpen: false,
    application: null,
  });

  const getApplicaionInfo = application => {
    modal.application = application;
    const params = {
      store_id: application.store_id,
      object: true,
    };
    service.stores.execute(params);

    const body = {
      data: [
        {
          condition_id: application.condition_id,
          application_amount: application.amount,
          commission: application.commission,
          duration: application.duration,
          down_payment_amount: application.down_payment_amount,
          prepayment: application.prepayment,
        },
      ],
    };
    service.calculate.execute(body);

    modal.isOpen = true;
  };
</script>

<style lang="scss" scoped></style>
