<template>
  <div v-if="errors.length" class="text-red-600 dark:text-red-500 text-sm mt-1">
    <template v-if="checkType(errors, 'array')">
      <template v-for="(error, index) in errors" :key="index">
        <p v-html="error.$message"></p>
      </template>
    </template>
    <template v-else-if="checkType(errors, 'string')">
      <p v-html="errors"></p>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import { checkType } from '~/plugins/aliftech-ui/utils/checkType';

  export default defineComponent({
    name: 'BaseFieldErrors',
    props: { errors: { type: [Array, String], default: () => '' } },
    setup() {
      return {
        checkType,
      };
    },
  });
</script>
