<template>
  <div ref="rootEl">
    <slot name="before" v-bind="{ prev, disabled: prevDisabled }" />
    <div ref="carouselEl" v-bind="$attrs" class="keen-slider">
      <slot />
    </div>
    <slot name="after" v-bind="{ next, disabled: nextDisabled }" />
  </div>
</template>

<script setup>
  import { computed, watch, onBeforeUnmount, ref, reactive, onMounted } from 'vue';
  import KeenSlider from 'keen-slider';
  import 'keen-slider/keen-slider.css';

  const props = defineProps({
    modelValue: {
      type: Number,
      default: 0,
    },
    autoAdjustSlidesPerView: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object,
      default: undefined,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    dragSpeed: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 500,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'snap',
    },
    resetSlide: {
      type: Boolean,
      default: false,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    rubberband: {
      type: Boolean,
      default: true,
    },
    slidesPerView: {
      type: Number,
      default: 1,
    },
    spacing: {
      type: Number,
      default: 0,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    autoplayTimeout: {
      type: Number,
      default: 3000,
    },
  });
  const emit = defineEmits(['update:modelValue']);

  const options = computed(() => ({
    autoAdjustSlidesPerView: props.autoAdjustSlidesPerView,
    breakpoints: props.breakpoints,
    centered: props.centered,
    controls: props.controls,
    dragSpeed: props.dragSpeed,
    duration: props.duration,
    loop: props.loop,
    mode: props.mode,
    resetSlide: props.resetSlide,
    rtl: props.rtl,
    rubberband: props.rubberband,
    slidesPerView: props.slidesPerView,
    spacing: props.spacing,
    vertical: props.vertical,
  }));
  const carouselEl = ref(null);
  const carousel = ref(null);

  const isInitialized = ref(false);
  const details = reactive({});
  const currentSlide = ref(props.modelValue);

  let timeout = 0;
  const autoplay = run => {
    clearTimeout(timeout);
    if (run && carousel.value) {
      timeout = setTimeout(() => {
        carousel.value.next();
        autoplay(run);
      }, props.autoplayTimeout);
    }
  };

  const rootEl = ref(null);
  const onMouseover = () => {
    autoplay(false);
  };
  const onMouseout = () => {
    autoplay(true);
  };
  onMounted(() => {
    if (carouselEl.value) {
      carousel.value = new KeenSlider(carouselEl.value, {
        ...options.value,
        initial: props.modelValue,
        created(e) {
          Object.assign(details, e.details());
        },
        mounted() {
          isInitialized.value = true;
        },
        destroyed() {
          if (!isInitialized.value) return;
          isInitialized.value = true;
        },
        slideChanged(e) {
          if (!isInitialized.value) return;
          const { relativeSlide } = e.details();
          currentSlide.value = relativeSlide;
        },
        afterChange() {
          if (!isInitialized.value) return;
          emit('update:modelValue', currentSlide.value);
        },
        dragStart: () => {
          props.autoplay && autoplay(false);
        },
        dragEnd: () => {
          props.autoplay && autoplay(true);
        },
      });

      if (props.autoplay) {
        autoplay(true);

        rootEl.value.addEventListener('mouseover', onMouseover);
        rootEl.value.addEventListener('mouseout', onMouseout);
      }
    }
  });

  watch(
    () => props.modelValue,
    slide => {
      if (slide !== currentSlide.value) {
        carousel.value.moveToSlide(slide);
        currentSlide.value = slide;
      }
    }
  );
  const destroy = () => {
    if (!isInitialized.value) return;

    clearTimeout(timeout);
    carousel.value.destroy();
    carousel.value = null;
  };
  const prev = () => {
    if (!isInitialized.value) return;

    carousel.value.prev();
  };
  const prevDisabled = computed(() => {
    return currentSlide.value === 0;
  });
  const next = () => {
    if (!isInitialized.value) return;

    carousel.value.next();
  };
  const nextDisabled = computed(() => {
    return currentSlide.value === details.size;
  });

  onBeforeUnmount(() => {
    destroy();
    rootEl.value.removeEventListener('mouseover', onMouseover);
    rootEl.value.removeEventListener('mouseout', onMouseout);
  });
</script>

<script>
  export default {
    name: 'BaseCarousel',
  };
</script>
