<template>
  <template v-if="isSuccess">
    <div class="relative flex-grow flex items-center justify-center">
      <div class="w-full m-auto" style="max-width: 353px">
        <div class="space-y-4">
          <div class="bg-green-50 rounded-full w-16 h-16 flex justify-center items-center text-primary-600 mx-auto">
            <CheckCircleIcon class="w-7 h-7" style="min-width: 28px" />
          </div>
          <div class="space-y-2 text-center">
            <div class="text-base font-bold">
              {{ $t('app.titles.profile.password.messages.success') }}
            </div>
          </div>
          <AtButton size="lg" color="primary" class="w-full" :to="{ name: 'Profile' }">
            {{ $t('system.btns.back') }}
          </AtButton>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="px-4 pb-20 min-h-screen">
      <div class="w-full m-auto" style="max-width: 440px">
        <form class="space-y-6" @submit.prevent="changePassword">
          <div class="space-y-4">
            <router-link :to="{ name: 'Profile' }" class="text-primary-500 flex items-center space-x-1">
              <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
              <span> {{ $t('system.btns.back') }} </span>
            </router-link>
            <AtTitle> {{ $t('app.titles.profile.password.title') }} </AtTitle>
            <div class="space-y-2">
              <AtInput
                v-model="passwordFields.password"
                :label="$t('app.fields.profile.newPassword')"
                :type="isHidden.password ? 'password' : 'text'"
                :error="$v.password?.$error"
                :disabled="service.changePassword.loading"
                autocomplete="off"
                addOnAfterSelect
              >
                <template #addOnAfter>
                  <AtInputAddOn
                    side="right"
                    :select="true"
                    class="border-r-0 border-t-0 border-b-0 cursor-pointer px-2.5 text-gray-500"
                    @click="isHidden.password = !isHidden.password"
                  >
                    <EyeIcon v-if="isHidden.password" class="h-5 w-5 dark:text-white" />
                    <EyeSlashIcon v-else class="h-5 w-5 dark:text-white" />
                  </AtInputAddOn>
                </template>
              </AtInput>
              <BaseFieldErrors
                :errors="$v.password?.$errors.length ? $v.password?.$errors : service?.changePassword?.errors?.password"
              />
              <div class="text-sm text-gray-500">
                {{ $t('validations.minPasswordLengthAndOneLatinLetter') }}
              </div>
            </div>
            <div class="space-y-2">
              <AtInput
                v-model="passwordFields.passwordRepeat"
                :label="$t('app.fields.profile.repeatNewPassword')"
                :type="isHidden.passwordRepeat ? 'password' : 'text'"
                :error="$v.passwordRepeat?.$error"
                :disabled="service.changePassword.loading"
                autocomplete="off"
                addOnAfterSelect
              >
                <template #addOnAfter>
                  <AtInputAddOn
                    side="right"
                    :select="true"
                    class="border-r-0 border-t-0 border-b-0 cursor-pointer px-2.5 text-gray-500"
                    @click="isHidden.passwordRepeat = !isHidden.passwordRepeat"
                  >
                    <EyeIcon v-if="isHidden.passwordRepeat" class="h-5 w-5 dark:text-white" />
                    <EyeSlashIcon v-else class="h-5 w-5 dark:text-white" />
                  </AtInputAddOn>
                </template>
              </AtInput>
              <BaseFieldErrors
                :errors="
                  $v.passwordRepeat?.$errors.length
                    ? $v.passwordRepeat?.$errors
                    : service?.changePassword?.errors?.password_confirmation
                "
              />
            </div>
          </div>
          <BaseAlert :alert="service.changePassword.alert" :errors="service.changePassword.errors" />
          <AtButton size="lg" class="w-full" color="primary" :loading="service.changePassword.loading" type="submit">
            {{ $t('system.btns.changePassword') }}
          </AtButton>
        </form>
      </div>
    </div>
  </template>
</template>

<script setup>
  import { ref, reactive } from 'vue';
  import { EyeIcon, EyeSlashIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength, helpers } from '@vuelidate/validators';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtInputAddOn from '~/plugins/aliftech-ui/components/AtInputAddOn/AtInputAddOn';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import { useService } from '~/hooks/useService';
  import { updatePassword as updatePasswordService } from '~/services/profile.api';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const MIN_PASSWORD_LENGTH = 8;

  const defaultPasswordFields = {
    password: '',
    passwordRepeat: '',
  };

  const isSuccess = ref(false);
  const service = {
    changePassword: reactive(useService(updatePasswordService)),
  };
  const isHidden = reactive({
    password: true,
    passwordRepeat: true,
  });
  const passwordFields = reactive({ ...defaultPasswordFields });

  const passwordValidationRules = {
    password: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(
        t('app.titles.profile.password.requirements.list[1]'),
        minLength(MIN_PASSWORD_LENGTH)
      ),
    },
    passwordRepeat: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(
        t('app.titles.profile.password.requirements.list[1]'),
        minLength(MIN_PASSWORD_LENGTH)
      ),
      sameAsPassword: helpers.withMessage(
        t('validations.passwordNotMatch'),
        value => value === passwordFields.password
      ),
    },
  };
  const $v = useVuelidate(passwordValidationRules, passwordFields);

  const changePassword = async () => {
    if (!(await $v.value.$validate())) return;

    const payload = {
      password: passwordFields.password,
      password_confirmation: passwordFields.passwordRepeat,
    };

    service.changePassword.execute(payload).then(() => {
      isSuccess.value = true;
    });
  };
</script>
