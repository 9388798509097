<template>
  <div class="grid auto-cols-fr grid-flow-col gap-1">
    <div v-for="(step, index) in steps" :key="index" class="flex flex-col items-center space-y-2">
      <span class="text-sm font-medium" :class="step.isActive ? 'text-gray-900' : 'text-gray-500'">{{
        step.label
      }}</span>
      <div
        class="h-1 w-full rounded-3xl"
        :class="{
          'bg-primary-600': step.isActive,
          'bg-primary-300': step.isCompleted && !step.isActive,
          'bg-gray-300': !step.isActive && !step.isCompleted,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormsStepsMobile',
  };
</script>

<script setup>
  defineProps({
    steps: {
      type: Array,
      required: true,
    },
  });
</script>

<style scoped></style>
