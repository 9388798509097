<template>
  <template v-if="isCreditLoading">
    <div class="flex flex-col justify-center w-full items-center">
      <AlifLoaderIcon class="w-16 h-16 text-primary-600" />
    </div>
  </template>
  <template v-else-if="creditNumber">
    <div class="max-w-md mx-auto w-full space-y-8">
      <div class="space-y-4">
        <a class="text-primary-500 flex items-center space-x-1" href="#" @click.prevent="goBack">
          <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
          <span> {{ $t('system.btns.back') }} </span>
        </a>
        <AtTitle> {{ $t('app.problemCase.title') }} </AtTitle>
      </div>
      <div class="space-y-3">
        <span class="text-sm leading-5 font-medium text-gray-700">
          {{ $t('app.problemCase.fileInputLabel') }}
        </span>
        <div class="space-y-2">
          <AtFileSelect
            :title="$t('app.problemCase.form.selectFile')"
            :title-more="$t('app.problemCase.form.selectMore')"
            show-signature
            accept="image/jpeg,image/png,image/svg+xml,image/jpg,image/heic"
            @onSelect="onFileSelect"
          />
          <BaseFieldErrors :errors="v$.file.$errors" />
        </div>
      </div>
      <div class="space-y-3">
        <p class="mb-3 text-sm leading-5 font-medium text-gray-900">
          {{ $t('app.reject.form.reasonLabel') }}
        </p>
        <div class="space-y-2">
          <div>
            <AtRadioGroup
              v-model="fields.description"
              :disabled="isCreateProblemCaseLoading"
              no-border
              :items="reasonOptions"
              value-type="title"
            >
              <template #item="{ item }">
                <div class="w-full">
                  <span class="block w-full text-base leading-6 font-normal text-gray-900">{{ item.title }}</span>
                </div>
              </template>
            </AtRadioGroup>
            <div v-if="isOtherDescription" class="block pl-6 -mt-2">
              <AtInput
                v-model="fields.descriptionFromInput"
                :disabled="isCreateProblemCaseLoading"
                :placeholder="$t('app.reject.form.typeReason')"
              />
              <BaseFieldErrors :errors="v$.descriptionFromInput.$errors" />
            </div>
          </div>
          <BaseFieldErrors :errors="v$.description.$errors" />
        </div>
      </div>
      <BaseAlert :errors="services.create.errors" :alert="services.create.alert" />
      <AtButton color="primary" block :loading="isCreateProblemCaseLoading" @click="submit">{{
        $t('app.problemCase.buttonText')
      }}</AtButton>
    </div>
  </template>
  <template v-else>
    <div class="flex flex-col items-center space-y-4">
      <div class="text-center">
        {{ $t('app.problemCase.error.description') }}
      </div>
      <AtButton :to="{ name: 'App.Index' }" color="primary">
        {{ $t('app.problemCase.error.btn') }}
      </AtButton>
    </div>
  </template>
</template>

<script setup>
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { useRoute, useRouter } from 'vue-router';
  import useVuelidate from '@vuelidate/core';
  import { helpers, required, requiredIf } from '@vuelidate/validators';
  import { useI18n } from 'vue-i18n';
  import { computed, inject, onBeforeMount, reactive, ref } from 'vue';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtFileSelect from '~/plugins/aliftech-ui/components/AtFileSelect/AtFileSelect';
  import AtRadioGroup from '~/plugins/aliftech-ui/components/AtRadioGroup/AtRadioGroup';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import { useService } from '~/hooks/useService';
  import { createProblemCase } from '~/services/problemCase.api';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import { getCredit } from '~/services/credits.api';
  import AlifLoaderIcon from '~/components/icons/AlifLoaderIcon';

  const route = useRoute();
  const router = useRouter();
  const { t } = useI18n();
  const creditId = computed(() => route.params?.id);

  const reasonOptions = computed(() => [
    {
      title: t('app.problemCase.reasons[0]'),
    },
    {
      title: t('app.problemCase.reasons[1]'),
    },
    {
      title: t('app.problemCase.reasons[2]'),
    },
    {
      title: t('app.problemCase.reasons[3]'),
    },
    {
      title: t('app.problemCase.reasons[4]'),
    },
  ]);

  const defaultFields = {
    description: null,
    descriptionFromInput: null,
    credit_number: null,
    file: null,
  };
  const fields = reactive({
    ...defaultFields,
  });
  const isOtherDescription = computed(() => fields.description === t('app.problemCase.reasons[4]'));
  const rules = computed(() => ({
    description: {
      required: helpers.withMessage(t('validations.required'), required),
    },
    descriptionFromInput: {
      requiredIf: helpers.withMessage(
        t('validations.required'),
        requiredIf(() => isOtherDescription.value)
      ),
    },
    file: {
      required: helpers.withMessage(t('validations.required'), required),
    },
    credit_number: {},
  }));
  const v$ = useVuelidate(rules, fields);

  const services = ref({
    create: useService(createProblemCase),
    getCredit: useService(getCredit),
  });
  const isCreateProblemCaseLoading = computed(() => services.value.create.loading);
  const isCreditLoading = computed(() => services.value.getCredit.loading);

  const $toast = inject('$toast');
  const submit = async () => {
    if (!(await v$.value.$validate())) return;

    const payload = new FormData();

    payload.append('description', isOtherDescription.value ? fields.descriptionFromInput : fields.description);
    payload.append('file', fields.file);
    payload.append('credit_number', creditNumber.value);

    services.value.create.execute(payload).then(() => {
      $toast.success({
        title: t('app.problemCase.successText'),
      });
      goBack();
    });
  };

  const goBack = () => {
    router.replace({ name: 'Installments.Show', params: { id: creditId.value } });
  };

  const onFileSelect = formData => {
    const file = formData.get('files');

    fields.file = file;
  };

  const creditNumber = ref(null);
  const getCreditNumber = () => {
    return services.value.getCredit.execute(creditId.value).then(response => {
      creditNumber.value = response.data?.contract_number;
    });
  };

  onBeforeMount(getCreditNumber);
</script>

<script>
  export default {
    name: 'InstallmentsCreateProblemCasePage',
  };
</script>
