<template>
  <AtButton
    :to="
      locale === 'ru'
        ? 'https://alifshop.uz/ru?utm_source=nasiya&utm_medium=landing'
        : 'https://alifshop.uz/uz?utm_source=nasiya&utm_medium=landing'
    "
    :href="
      locale === 'ru'
        ? 'https://alifshop.uz/ru?utm_source=nasiya&utm_medium=landing'
        : 'https://alifshop.uz/uz?utm_source=nasiya&utm_medium=landing'
    "
    target="_blank"
    color="alifshop"
    size="lg"
  >
    {{ $t('app.titles.banners.alifshop.button') }}
  </AtButton>
</template>

<script setup>
  import { useI18n } from 'vue-i18n';

  import AtButton from '~/components/aliftech-ui/AtButton/AtButton';

  const { locale } = useI18n();
</script>

<style lang="scss" scoped></style>
