import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import uz from 'date-fns/locale/uz';

import i18n from '../plugins/i18n';
// import { globalState } from "~/shared/globalState";

export const transformDate = (date, { dateFormat = 'dd MMMM yyyy' } = {}, lang = i18n.global.locale.value) => {
  if (!date) return date;
  if (date.length > 10) {
    date = date.replace(/\s/g, 'T');
  }
  let parsed = date;
  if (Object.prototype.toString.call(date) === '[object String]') {
    parsed = new Date(parsed);
  }
  if (Object.prototype.toString.call(parsed) === '[object Date]' && parsed.toString() !== 'Invalid Date') {
    return format(new Date(parsed.getFullYear(), parsed.getMonth(), parsed.getDate()), dateFormat, {
      locale: lang === 'uz' ? uz : ru,
      // locale: ru,
    });
  }
  return date;
};
