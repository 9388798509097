<template>
  <div class="bg-primary-600 text-white overflow-hidden rounded-2xl border border-gray-100">
    <div class="px-4 py-5 sm:p-6 rounded-b-2xl">
      <div class="space-y-8">
        <div class="flex space-x-3">
          <div class="flex-1">
            <div class="text-base leading-6">
              {{ $t('app.titles.app.limit.availableLimit') }}
            </div>
            <h3 class="text-3xl leading-9 font-bold">
              {{ limits?.frozen ? 0 : limits?._current_amount }} {{ $t('system.titles.sum') }}
            </h3>
          </div>
        </div>
        <div>
          <slot />
        </div>
      </div>
    </div>
    <div class="bg-primary-700 px-6 py-5 rounded-b-2xl">
      <a @click="modal.isOpen = true" href="#" class="inline-flex items-center space-x-3">
        <span>{{ $t('app.titles.app.limit.modalBtn') }}</span>
        <ChevronRightIcon class="w-5 h-5" />
      </a>
    </div>
    <AtModal :title="$t('app.titles.app.limit.modal.title')" v-model="modal.isOpen">
      <p class="leading-6 my-6" data-testid="modal_description">
        {{ $t('app.titles.app.limit.modal.description') }}
      </p>
      <AtButton block @click="modal.isOpen = false" data-testid="close_modal_button">{{
        $t('system.btns.understand')
      }}</AtButton>
    </AtModal>
  </div>
</template>

<script setup>
  import { ChevronRightIcon } from '@heroicons/vue/20/solid';
  import { reactive } from 'vue';

  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';

  defineProps({
    limits: {
      type: Object,
      required: true,
    },
    recoverLimits: {
      type: Object,
      required: true,
    },
  });

  const modal = reactive({
    isOpen: false,
  });
</script>
