/**
 * Formats a phone number into the pattern +998 (00) 000-00-00.
 *
 * @param {string} phone - The raw phone number string.
 * @param {object} options - Options object.
 * @param {boolean} [options.masked=false] - Whether to mask the phone number.
 * @returns {string | null} The formatted phone number, or the masked version if `masked` is true.
 *
 */

export const formatPhoneNumber = (phone, options = { masked: false }) => {
  if (typeof phone !== 'string') {
    return null;
  }

  let formatted = phone.trim();

  if (formatted.length === 12) {
    formatted = formatted.slice(3);
  }

  const [providerCode, midTwoDigits, lastTwoDigits] = [
    formatted.slice(0, 2),
    formatted.slice(5, 7),
    formatted.slice(7, 9),
  ];

  return options.masked
    ? `+998 (${providerCode}) ${formatted.slice(2, 3)}**-**-${lastTwoDigits}`
    : `+998 (${providerCode}) ${formatted.slice(2, 5)}-${midTwoDigits}-${lastTwoDigits}`;
};
