<template>
  <component
    :is="clickable ? 'button' : 'div'"
    class="root group flex items-center space-x-4"
    :class="{
      'w-full text-left': clickable,
    }"
  >
    <div
      class="h-12 w-12 rounded-full flex justify-center items-center font-medium border"
      :class="{
        'border-gray-500 group-hover:border-primary-500': isDelay || isPrepayment,
        'bg-gray-100 border-gray-100 group-hover:bg-green-100 group-hover:text-green-800 group-hover:border-green-100':
          !isDelay && !canPay && !isPrepayment,
        'bg-primary-600 border-primary-600 text-white': canPay,
      }"
      style="min-width: 48px"
    >
      <template v-if="isDelay">
        <ClockIcon class="h-6 w-6 text-gray-500 group-hover:text-primary-600" />
      </template>
      <template v-else-if="isPrepayment">
        <CircleStackIcon class="h-6 w-6 text-gray-500 group-hover:text-primary-600" />
      </template>
      <template v-else>
        {{ payment?._payment_number }}
      </template>
    </div>
    <div class="flex items-center py-5 lg:py-6 w-full border-b border-gray-200">
      <div class="space-y-1 mr-4">
        <div
          class="text-base font-medium flex items-center space-x-1"
          :class="!canPay && 'group-hover:text-primary-500'"
        >
          <template v-if="isPrepayment">
            {{ $t('app.titles.installment.payment.labels.hold') }}
          </template>
          <template v-else>
            <span>
              {{ transformDate(payment?.payment_date, { dateFormat: 'dd MMMM' }) }}
            </span>
            <ChevronRightIcon
              v-if="clickable"
              class="w-5 h-5 text-gray-400 group-hover:text-primary-500"
              style="min-width: 20px"
            />
          </template>
        </div>
        <div class="text-sm text-gray-400">
          <template v-if="isDelay">
            {{
              $t('app.titles.installment.delayed.daysShift', {
                days: $t('system.day', { count: payment?.days, postfixUz: 'ga' }),
              })
            }}
          </template>
          <template v-else>
            {{ payment?._formatted_payment_amount || 0 }}
            {{ $t('system.titles.sum') }}
          </template>
        </div>
      </div>
      <div class="ml-auto">
        <template v-if="isDelay">
          <InformationCircleIcon class="h-6 w-6 text-gray-500 group-hover:text-primary-500" />
        </template>
        <template v-else-if="payment?.paid">
          <CheckIcon class="h-6 w-6 text-primary-500" />
        </template>
        <template v-else-if="canPay">
          <AtButton color="primary" rounded @click="$emit('pay', payment)">
            {{ $t('app.btns.pay') }}
          </AtButton>
        </template>
      </div>
    </div>
  </component>
</template>

<script setup>
  import { computed } from 'vue';
  import { InformationCircleIcon, ClockIcon, CheckIcon, CircleStackIcon } from '@heroicons/vue/24/outline';
  import { ChevronRightIcon } from '@heroicons/vue/20/solid';

  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import { INSTALLMENT_PAYMENTS_TYPES } from '~/constants/installments';
  import { transformDate } from '~/utils';

  const isDelay = computed(() => props.type === INSTALLMENT_PAYMENTS_TYPES.delay);
  const isPrepayment = computed(() =>
    [INSTALLMENT_PAYMENTS_TYPES.downPaymentAmount, INSTALLMENT_PAYMENTS_TYPES.prepayment].includes(props.type)
  );

  const props = defineProps({
    type: {
      type: String,
      default: null,
      validator: value => value && Object.values(INSTALLMENT_PAYMENTS_TYPES).includes(value),
    },
    payment: {
      type: Object,
      default: () => ({}),
    },
    canPay: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  });
  defineEmits(['pay']);
</script>

<style lang="scss" scoped>
  // TODO: Find best solution
  .root {
    &:last-child {
      div:last-child {
        border-bottom: 0;
      }
    }
  }
</style>
