import { ref } from 'vue';
import { getRequirements } from '~/services/auth.api';

export const $_clientRequirements = ref(null);

export const updateRequirements = () =>
  getRequirements().then(res => {
    $_clientRequirements.value =
      res.data?.requirements.reduce(
        (acc, requirement) => ({
          ...acc,
          [requirement.key]: {
            ...requirement,
          },
        }),
        {}
      ) ?? {};
    return res;
  });
