<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex py-4 items-center justify-between">
    <div class="flex items-center">
      <router-link to="/" class="mr-10" data-testid="base_logo_link">
        <BaseLogo class="w-28 md:w-auto" />
      </router-link>
      <div class="hidden items-center gap-6 md:flex">
        <router-link
          :to="{ name: 'Shops.Index' }"
          class="text-sm leading-5 font-normal text-gray-900 transition hover:text-primary-600"
          data-testid="shops_link"
          >{{ $t('app.header.shops') }}</router-link
        >
        <a
          :href="`${merchantUrl}/sign-up`"
          target="_blank"
          class="text-sm leading-5 font-normal text-gray-900 transition hover:text-primary-600"
          data-testid="partners_link"
          >{{ $t('app.header.partner') }}</a
        >
        <router-link
          :to="{ name: 'Juma' }"
          class="text-sm leading-5 text-gray-900 transition hover:text-primary-600 normal-case"
          data-testid="juma_link"
        >
          alif Juma
        </router-link>
      </div>
    </div>
    <div class="flex items-center">
      <div class="flex space-x-4">
        <slot name="action" />
      </div>
      <div v-if="!user" class="md:hidden bg-gray-100 relative w-10 h-10 rounded-lg cursor-pointer">
        <Bars3Icon class="w-6 h-6 absolute top-2 left-2" @click="$emit('handleBars3Icon')" data-testid="menu_icon" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { inject } from 'vue';
  import { Bars3Icon } from '@heroicons/vue/24/outline';

  import BaseLogo from '~/components/base/Logo';
  import { $_at_user as user } from '~/shared/at-user';

  const merchantUrl = inject('merchantUrl');
</script>
