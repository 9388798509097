import Layout from '~/layouts/FormLayout.vue';

const FilesPage = () => import('~/views/forms/FilesPage.vue');

export default [
  {
    path: 'forms',
    component: Layout,
    meta: { form: true },
    name: 'Forms',
    children: [
      {
        path: 'files',
        name: 'Forms.Files',
        component: FilesPage,
        meta: { public: false },
      },
      {
        path: 'cards',
        name: 'Forms.Cards',
        component: () => import('~/views/forms/CardsPage.vue'),
        meta: { public: false },
      },
      {
        path: 'questionnaire',
        name: 'Forms.Questionnaire',
        component: () => import('~/views/forms/QuestionnairePage.vue'),
        meta: { public: false },
      },
    ],
  },
];
