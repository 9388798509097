<template>
  <form class="space-y-6" @submit.prevent="validatePhone">
    <div class="space-y-4">
      <div class="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0">
        <div class="md:w-full">
          <AtInput
            v-model="phoneFields.phone"
            :disabled="service.checkPhone.loading"
            :label="$t('system.fields.phone')"
            type="tel"
            mask="## ### ## ##"
            placeholder="00 000 00 00"
            :error="Boolean($v.phone?.$error || service?.checkPhone?.errors?.phone)"
            @update:model-value="$emit('update:model-value', $event)"
          >
            <template #addOnBefore> +998 </template>
          </AtInput>
          <BaseFieldErrors
            :errors="$v.phone?.$errors.length ? $v.phone?.$errors : service?.checkPhone?.errors?.phone"
          />
        </div>
      </div>
    </div>
    <BaseAlert :alert="service?.checkPhone?.alert" />
    <AtButton :loading="service.checkPhone.loading" color="primary" size="lg" class="w-full" type="submit">
      {{ $t('system.btns.send') }}
    </AtButton>
  </form>
</template>

<script setup>
  import { computed, reactive, watch } from 'vue';
  import useVuelidate from '@vuelidate/core';
  import { helpers, minLength, required } from '@vuelidate/validators';
  import { useI18n } from 'vue-i18n';

  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';

  import BaseAlert from '~/components/base/BaseAlert.vue';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import { useService } from '~/hooks/useService';
  import { checkPhone } from '~/services/public.api';

  const { t } = useI18n();

  const emit = defineEmits(['submit']);
  const props = defineProps({
    modelValue: {
      type: [String, Number],
      default: null,
    },
  });

  const service = {
    checkPhone: reactive(useService(checkPhone)),
  };

  const defaultFields = {
    phone: '',
  };
  const phoneFields = reactive({ ...defaultFields });
  watch(
    () => props.modelValue,
    value => {
      if (value !== phoneFields.phone) phoneFields.phone = value;
    },
    {
      immediate: true,
    }
  );

  const phoneFieldRules = {
    phone: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(t('validations.phoneMinLength'), minLength(9)),
    },
  };

  const phoneServerErrors = reactive({});
  const $v = useVuelidate(phoneFieldRules, phoneFields, { $externalResults: phoneServerErrors });
  const payloadPhone = computed(() => (phoneFields.phone.length <= 9 ? '998' + phoneFields.phone : phoneFields.phone));
  const validatePhone = async () => {
    if (!(await $v.value.$validate())) return;

    const params = { phone: payloadPhone.value };
    service.checkPhone
      .execute(params)
      .then(() => {
        emit('submit', payloadPhone.value);
      })
      .catch(e => {
        Object.assign(phoneServerErrors, e);
      });
  };

  const reset = () => {
    Object.assign(phoneFields, defaultFields);
  };

  defineExpose({ reset });
</script>

<script>
  export default {
    name: 'BasePhoneCheckForm',
  };
</script>

<style scoped></style>
