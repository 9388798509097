import { ref } from 'vue';
import AliftechDeviceProfiler from '@packages/aliftech-device-profiler';

import { collectMeta } from '~/services/clientMeta.api';

const isCollectAllowed = ref(false);

export const useDeviceProfiler = () => {
  const collect = clientId => {
    const deviceProfiler = new AliftechDeviceProfiler();
    const deviceInfo = deviceProfiler.collect();

    return deviceInfo.then(async device => {
      const payload = {
        client_id: clientId,
        ...device,
      };
      return collectMeta(payload);
    });
  };

  const allowCollect = () => {
    isCollectAllowed.value = true;
  };
  const denyCollect = () => {
    isCollectAllowed.value = false;
  };

  return {
    collect,
    allowCollect,
    denyCollect,
    isCollectAllowed,
  };
};
