<template>
  <BaseFaceShot
    ref="faceShotEl"
    :loading="service.request.loading || service.check.loading || isIdentifying"
    :error="service.request.hasError"
    @error="onBaseShotError"
    @submit="onFaceSubmit"
  >
    <template #alert>
      <slot name="alert" />
      <BaseAlert :alert="service.check.alert" :errors="service.check.errors" />
      <BaseAlert :alert="service.request.alert" :errors="service.request.errors" />
    </template>
  </BaseFaceShot>
  <div class="flex fixed w-full left-0 bottom-0 p-4 z-10 md:static md:p-0">
    <div class="max-w-md mx-auto flex w-full space-x-2">
      <slot name="buttons" v-bind="{ loading: identificationLoading || faceShotIsCameraStarting }" />
      <template v-if="!service.request.hasError && !faceShotMediaDevice?.hasError">
        <AtButton
          color="primary"
          block
          size="md"
          :loading="identificationLoading || faceShotIsCameraStarting"
          @click="faceShotAction"
          data-testid="face_shot_button"
        >
          <div v-if="faceShotPreviewFaceImageUrl" data-testid="selfie_again_text">
            {{ $t('form.myid.btns.selfieAgain') }}
          </div>
          <div v-else data-testid="next_button">
            {{ $t('system.btns.next') }}
          </div>
        </AtButton>
      </template>
      <template v-else>
        <AtButton
          v-if="service.request.hasError || hasCameraError"
          color="primary"
          class="w-full"
          block
          size="md"
          type="button"
          @click="hasSkip ? $emit('skip') : faceShotAction()"
          data-testid="skip_button"
        >
          {{ $t('system.btns.next') }}
        </AtButton>
      </template>
    </div>
  </div>
</template>

<script setup>
  import { reactive, ref, computed, watch } from 'vue';

  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';

  import { useService } from '~/hooks/useService';
  import BaseFaceShot from '~/components/base/BaseFaceShot.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';

  const props = defineProps({
    serviceCheck: {
      type: Function,
      default: () => Promise.resolve(),
    },
    serviceRequest: {
      type: Function,
      default: () => Promise.resolve(),
    },
    serviceCheckPayload: {
      type: Object,
      default: () => ({}),
    },
    serviceRequestPayload: {
      type: Object,
      default: () => ({}),
    },
    hasSkip: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits([
    'check',
    'check-success',
    'check-error',
    'request',
    'request-success',
    'request-error',
    'skip',
    'camera-error',
    'retry-image-to-my-id',
  ]);

  const service = {
    check: reactive(useService(props.serviceCheck)),
    request: reactive(useService(props.serviceRequest)),
  };

  const isIdentifying = ref(false);
  const identificationLoading = computed(() => service.request.loading || service.check.loading || isIdentifying.value);

  const hasCameraError = ref(false);
  const onBaseShotError = () => {
    emit('camera-error');
    hasCameraError.value = true;
  };

  const REQUEST_INTERVAL = 5000;
  const intervalCheckIdentificationStatus = () => {
    isIdentifying.value = true;
    const payload = {
      ...props.serviceCheckPayload,
    };
    return (function run() {
      let timeout;
      emit('check');
      return service.check
        .execute(payload)
        .then(res => {
          timeout = setTimeout(run, REQUEST_INTERVAL);
          const data = res.data;
          if (data.code?.toString()?.toLowerCase() === 'success') {
            emit('check-success', data);
            clearTimeout(timeout);
            isIdentifying.value = false;
            return res;
          }
        })
        .catch(error => {
          emit('check-error', error);
          clearTimeout(timeout);
          isIdentifying.value = false;
          return error;
        });
    })();
  };
  const onFaceSubmit = file => {
    const fd = new FormData();
    Object.entries(props.serviceRequestPayload).forEach(([key, value]) => {
      fd.append(key, value);
    });
    fd.append('photo_face', file);
    emit('request');
    service.request
      .execute(fd)
      .then(response => {
        emit('request-success', response);
        intervalCheckIdentificationStatus();
      })
      .catch(error => {
        emit('request-error', error);
      });
  };

  const faceShotEl = ref(null);
  const faceShotIsUserMediaStart = computed(() => faceShotEl.value?.isUserMediaStart);
  const faceShotMediaDevice = computed(() => faceShotEl.value?.mediaDevice);
  const faceShotIsCameraStarting = computed(() => faceShotEl.value?.isCameraStarting);
  const faceShotPreviewFaceImageUrl = computed(() => faceShotEl.value?.previewFaceImageUrl);

  const faceShotStart = () => {
    if (faceShotEl.value?.start) {
      faceShotEl.value.start();

      service.check.alert = null;
      service.request.alert = null;
      service.request.hasError = false;
    }
  };
  const faceShotAction = () => {
    if (faceShotPreviewFaceImageUrl.value) {
      emit('retry-image-to-my-id');
    }
    if (faceShotIsUserMediaStart.value) faceShotEl.value?.capture();
    else faceShotStart();
  };

  defineExpose({ intervalCheckIdentificationStatus });

  watch(
    () => props.isPending,
    value => {
      if (value) {
        intervalCheckIdentificationStatus();
      }
    },
    { immediate: true }
  );
</script>

<script>
  export default {
    name: 'BaseMyIdIdentification',
  };
</script>

<style scoped></style>
