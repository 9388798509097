<template>
  <AtContainer short class="pb-10">
    <div class="w-full max-w-sm">
      <div class="space-y-8">
        <AtTitle data-testid="profile_title"> {{ $t('app.titles.profile.title') }} </AtTitle>
        <div class="space-y-6 divide-y">
          <div class="space-y-6">
            <div class="space-x-4 flex items-center">
              <div
                class="flex justify-center rounded-full border border-gray-300 w-14 h-14 overflow-hidden bg-gray-200 relative"
                style="min-width: 56px"
              >
                <template v-if="localProfilePhoto">
                  <img class="w-full object-cover" :src="localProfilePhoto" alt="" />
                </template>

                <template v-else>
                  <UserIcon class="w-14 h-14 text-gray-400 relative top-2" style="min-width: 56px" />
                </template>
              </div>
              <div class="space-y-1">
                <div class="text-xl leading-7" data-testid="client_name">
                  {{ $_at_user.name }} {{ $_at_user.surname }}
                </div>
              </div>
            </div>
          </div>
          <div class="space-y-4 pt-6 md:grid md:grid-cols-2 md:space-x-4 md:space-y-0">
            <div class="space-x-3 flex items-center md:col-span-1 md:items-start">
              <PhoneIcon class="h-6 w-6 text-gray-400" style="min-width: 24px" />
              <div class="text-base">{{ $t('system.fields.phone') }}</div>
            </div>
            <div class="flex flex-col space-y-3 items-start pl-9 md:col-span-1 md:items-start md:pl-0">
              <a :href="`tel:+${$_at_user.phone}`" class="text-base text-gray-700">{{ phone }}</a>
              <AtButton size="xs" :to="{ name: 'Profile.ChangePhone' }" data-testid="change_phone_number_button">{{
                $t('system.btns.change')
              }}</AtButton>
            </div>
          </div>
          <div class="space-y-4 pt-6 md:grid md:grid-cols-2 md:space-x-4 md:space-y-0">
            <div class="space-x-3 flex items-center md:col-span-1 md:items-start">
              <LockClosedIcon class="h-6 w-6 text-gray-400" style="min-width: 24px" />
              <div class="text-base">{{ $t('system.fields.password') }}</div>
            </div>
            <div class="pl-9 md:col-span-1 md:pl-0">
              <AtButton size="xs" :to="{ name: 'Profile.ChangePassword' }" data-testid="change_password_button">
                {{ $t('system.btns.changePassword') }}
              </AtButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AtContainer>
</template>

<script setup>
  import { computed, ref, onMounted } from 'vue';
  import { UserIcon, PhoneIcon, LockClosedIcon } from '@heroicons/vue/20/solid';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import { $_at_user } from '~/shared/at-user';
  import { getBase64Image } from '~/plugins/aliftech-ui/utils/getBase64Image';

  const localProfilePhoto = ref(null);

  const phone = computed(() => {
    const { phone } = $_at_user.value;

    if (phone.length < 12) return phone;

    return `+${phone.slice(0, 3)} ${phone.slice(3, 5)} ${phone.slice(5, 8)} ${phone.slice(8, 10)} ${phone.slice(
      10,
      12
    )}`;
  });

  onMounted(() => {
    getBase64Image.onmessage = ({ data }) => {
      localProfilePhoto.value = data;
      $_at_user.value.avatar_link = data;
    };

    localProfilePhoto.value = $_at_user.value.avatar_link;
  });
</script>
