<template>
  <button
    class="flex justify-center items-center space-x-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
    :class="{
      'cursor-not-allowed opacity-50': isLocaleChanging,
    }"
    :disabled="isLocaleChanging"
    @click="changeLang(currentLanguage.switchTo)"
  >
    <template v-if="isLocaleChanging">
      <AtLoading />
    </template>
    <template v-else>
      <component :is="currentLanguage.icon" class="h-5 w-auto" />
    </template>
    <slot name="title" :language="currentLanguage">
      <span class="font-medium text-sm text-gray-500">
        {{ currentLanguage.title }}
      </span>
    </slot>
  </button>
</template>

<script setup>
  import { defineAsyncComponent, computed } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRouter, useRoute } from 'vue-router';

  import AtLoading from '@/plugins/aliftech-ui/components/AtLoading/AtLoading';

  import { useGlobals } from '~/hooks/useGlobals';

  const props = defineProps({
    public: {
      type: Boolean,
      default: false,
    },
  });

  const { locale } = useI18n({ useScope: 'global' });
  const { isLocaleChanging, changeLocale } = useGlobals();

  const router = useRouter();
  const route = useRoute();
  const changeLang = lang => {
    changeLocale(lang, locale.value, props.public);
    router.replace({ ...route, params: { ...route.params, locale: lang } });
  };

  const languages = {
    ru: {
      title: 'Узб',
      code: 'ru',
      switchTo: 'uz',
      icon: defineAsyncComponent(() => import('../icons/FlagUzIcon.js')),
    },
    uz: {
      title: 'Рус',
      code: 'uz',
      switchTo: 'ru',
      icon: defineAsyncComponent(() => import('../icons/FlagRuIcon.js')),
    },
  };
  const currentLanguage = computed(() => languages?.[locale.value]);
</script>

<style lang="scss" scoped></style>
