import * as heroicons from '@heroicons/vue/24/solid';

const nameList = [];

for (const key in heroicons) {
  if (Object.prototype.hasOwnProperty.call(heroicons, key)) {
    const iconName = key.toString().replace(/Icon/g, '');
    nameList.push(iconName);
  }
}

const heroiconsV1toV2 = {
  AdjustmentsVerticalIcon: 'AdjustmentsVerticalIcon',
  ChatBubbleBottomCenterTextIcon: 'ChatBubbleBottomCenterTextIcon',
  ArchiveBoxIcon: 'ArchiveBoxIcon',
  ArrowDownCircleIcon: 'ArrowDownCircleIcon',
  ArrowLeftCircleIcon: 'ArrowLeftCircleIcon',
  ArrowRightCircleIcon: 'ArrowRightCircleIcon',
  ArrowUpCircleIcon: 'ArrowUpCircleIcon',
  ArrowLongDownIcon: 'ArrowLongDownIcon',
  ArrowLongLeftIcon: 'ArrowLongLeftIcon',
  ArrowLongRightIcon: 'ArrowLongRightIcon',
  ArrowLongUpIcon: 'ArrowLongUpIcon',
  ArrowsPointingOutIcon: 'ArrowsPointingOutIcon',
  ArrowSmallDownIcon: 'ArrowSmallDownIcon',
  ArrowSmallLeftIcon: 'ArrowSmallLeftIcon',
  ArrowSmallRightIcon: 'ArrowSmallRightIcon',
  ArrowSmallUpIcon: 'ArrowSmallUpIcon',
  CheckBadgeIcon: 'CheckBadgeIcon',
  NoSymbolIcon: 'NoSymbolIcon',
  BookmarkSquareIcon: 'BookmarkSquareIcon',
  BanknotesIcon: 'BanknotesIcon',
  ChartBarSquareIcon: 'ChartBarSquareIcon',
  ChatBubbleLeftRightIcon: 'ChatBubbleLeftRightIcon',
  ChatBubbleLeftEllipsisIcon: 'ChatBubbleLeftEllipsisIcon',
  ChatBubbleOvalLeftEllipsisIcon: 'ChatBubbleOvalLeftEllipsisIcon',
  CpuChipIcon: 'CpuCpuChipIcon',
  ClipboardDocumentCheckIcon: 'ClipboardDocumentCheckIcon',
  ClipboardDocumentIcon: 'ClipboardDocumentIcon',
  ClipboardDocumentListIcon: 'ClipboardDocumentListIcon',
  CloudArrowDownIcon: 'CloudArrowDownIcon',
  CloudArrowUpIcon: 'CloudArrowUpIcon',
  CodeBracketIcon: 'CodeBracketIcon',
  RectangleStackIcon: 'RectangleStackIcon',
  SwatchIcon: 'SwatchIcon',
  CursorArrowRaysIcon: 'CursorArrowRaysIcon',
  CircleStackIcon: 'CircleStackIcon',
  ComputerDesktopIcon: 'ComputerDesktopIcon',
  DevicePhoneMobileIcon: 'DevicePhoneMobileIcon',
  DocumentPlusIcon: 'DocumentPlusIcon',
  DocumentArrowDownIcon: 'DocumentArrowDownIcon',
  DocumentMinusIcon: 'DocumentMinusIcon',
  DocumentChartBarIcon: 'DocumentChartBarIcon',
  DocumentMagnifyingGlassIcon: 'DocumentMagnifyingGlassIcon',
  EllipsisHorizontalCircleIcon: 'EllipsisHorizontalCircleIcon',
  EllipsisHorizontalIcon: 'EllipsisHorizontalIcon',
  EllipsisVerticalIcon: 'EllipsisVerticalIcon',
  ArrowDownTrayIcon: 'ArrowDownTrayIcon',
  Square2StackIcon: 'Square2StackIcon',
  FaceSmileIcon: 'FaceSmileIcon',
  FaceFrownIcon: 'FaceFrownIcon',
  ExclamationTriangleIcon: 'ExclamationTriangleIcon',
  ArrowTopRightOnSquareIcon: 'ArrowTopRightOnSquareIcon',
  EyeSlashIcon: 'EyeSlashIcon',
  ForwardIcon: 'ForwardIcon',
  FunnelIcon: 'FunnelIcon',
  FolderPlusIcon: 'FolderPlusIcon',
  FolderArrowDownTrayIcon: 'FolderArrowDownIcon',
  FolderMinusIcon: 'FolderMinusIcon',
  GlobeAmericasIcon: 'GlobeAmericasIcon',
  HandRaisedIcon: 'HandRaisedIcon',
  InboxArrowDownIcon: 'InboxArrowDownIcon',
  BuildingLibraryIcon: 'BuildingBuildingLibraryIcon',
  BoltIcon: 'BoltIcon',
  MapPinIcon: 'MapPinIcon',
  ArrowLeftOnRectangleIcon: 'ArrowLeftOnRectangleIcon',
  ArrowRightOnRectangleIcon: 'ArrowRightOnRectangleIcon',
  EnvelopeIcon: 'EnvelopeIcon',
  EnvelopeOpenIcon: 'EnvelopeOpenIcon',
  Bars3CenterLeftIcon: 'Bars3CenterLeftIcon',
  Bars3BottomLeftIcon: 'Bars3BottomLeftIcon',
  Bars3BottomRightIcon: 'Bars3BottomRightIcon',
  Bars2Icon: 'Bars2Icon',
  Bars3Icon: 'Bars3Icon',
  MinusSmallIcon: 'MinusSmallIcon',
  MusicalNoteIcon: 'MusicalNoteIcon',
  BuildingOfficeIcon: 'BuildingOfficeIcon',
  PencilSquareIcon: 'PencilSquareIcon',
  PhoneArrowDownLeftIcon: 'PhoneArrowDownLeftIcon',
  PhoneXMarkIcon: 'PhoneXMarkIcon',
  PhoneArrowUpRightIcon: 'PhoneArrowUpRightIcon',
  PhotoIcon: 'PhotoIcon',
  PlusSmallIcon: 'PlusSmallIcon',
  PuzzlePieceIcon: 'PuzzlePieceIcon',
  QrCodeIcon: 'QrCodeBracketIcon',
  ReceiptPercentIcon: 'ReceiptPercentIcon',
  ArrowPathIcon: 'ArrowPathIcon',
  ArrowUturnLeftIcon: 'ArrowUturnLeftIcon',
  BackwardIcon: 'BackwardIcon',
  ArrowDownOnSquareStackIcon: 'ArrowDownOnSquareStackIcon',
  ArrowDownOnSquareIcon: 'ArrowDownOnSquareIcon',
  MagnifyingGlassCircleIcon: 'MagnifyingGlassCircleIcon',
  MagnifyingGlassIcon: 'MagnifyingGlassIcon',
  ChevronUpDownIcon: 'ChevronUpDownIcon',
  BarsArrowUpIcon: 'BarsArrowUpIcon',
  BarsArrowDownIcon: 'BarsArrowDownIcon',
  MegaphoneIcon: 'MegaphoneIcon',
  SignalSlashIcon: 'SignalSlashIcon',
  SignalIcon: 'SignalIcon',
  LifebuoyIcon: 'LifebuoyIcon',
  ArrowsRightLeftIcon: 'ArrowsRightLeftIcon',
  ArrowsUpDownIcon: 'ArrowsUpDownIcon',
  TableCellsIcon: 'TableCellsIcon',
  RectangleGroupIcon: 'RectangleGroupIcon',
  CommandLineIcon: 'CommandLineIcon',
  HandThumbDownIcon: 'HandThumbDownIcon',
  HandThumbUpIcon: 'HandThumbUpIcon',
  LanguageIcon: 'LanguageIcon',
  ArrowTrendingDownIcon: 'ArrowArrowTrendingDownIcon',
  ArrowTrendingUpIcon: 'ArrowArrowTrendingUpIcon',
  ArrowUpTrayIcon: 'ArrowUpTrayIcon',
  UserPlusIcon: 'UserPlusIcon',
  UserMinusIcon: 'UserMinusIcon',
  ViewColumnsIcon: 'ViewColumnsIcon',
  SquaresPlusIcon: 'SquaresPlusIcon',
  Squares2X2Icon: 'Squares2x2Icon',
  Bars4Icon: 'Bars4Icon',
  SpeakerXMarkIcon: 'SpeakerXMarkIcon',
  SpeakerWaveIcon: 'SpeakerWaveIcon',
  XMarkIcon: 'XMarkIcon',
  MagnifyingGlassPlusIcon: 'MagnifyingGlassPlusIcon',
  MagnifyingGlassMinusIcon: 'MagnifyingGlassMinusIcon',
};

export { nameList, heroiconsV1toV2 };
