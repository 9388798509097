<template>
  <AtContainer short>
    <AtTitle class="mb-10">{{ $t('app.header.installments') }}</AtTitle>
    <AtLoading v-if="service.credits.loading" />
    <div v-else class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <template v-if="filteredCredits.length">
        <PaymentCard class="col-span-1" v-for="credit in filteredCredits" :key="credit.id" :credit="credit" />
      </template>
      <template v-else>
        <div class="text-center space-y-4 py-14 flex flex-col justify-center col-span-3">
          <div class="rounded-full w-16 h-16 flex justify-center items-center bg-gray-100 mx-auto">
            <CircleStackIcon class="w-7 h-7 text-gray-500" />
          </div>
          <p class="text-gray-500">
            {{ $t('app.titles.app.installments.notFound.title') }}
          </p>
        </div>
      </template>
    </div>
  </AtContainer>
</template>

<script setup>
  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtTitle from '~/components/ui/AtTitle.vue';
  import { getCredits } from '~/services/credits.api';
  import { useService } from '~/hooks/useService';
  import { computed, reactive } from 'vue';
  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';
  import PaymentCard from '~/components/app/PaymentCard.vue';
  import { CREDITS_STATUSES_KEYS } from '~/constants/installments';
  import { CircleStackIcon } from '@heroicons/vue/20/solid';

  const service = {
    credits: reactive(useService(getCredits)),
  };

  service.credits.execute();

  const filteredCredits = computed(() =>
    service.credits.response?.data?.filter(credit => credit?.status_key !== CREDITS_STATUSES_KEYS.deleted)
  );
</script>

<style lang="scss" scoped></style>
