export const INSTALLMENT_PAYMENTS_TYPES = {
  delay: 'delay',
  downPaymentAmount: 'down_payment_amount',
  prepayment: 'prepayment',
  monthly: 'monthly',
};

export const CREDITS_STATUSES_KEYS = {
  active: 'ACTIVE',
  closed: 'CLOSED',
  deleted: 'DELETED',
  overdue: 'OVERDUE',
  paused: 'PAUSED',
  problem: 'PROBLEM',
};
