import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import router from '@/router';

export default {
  install(app) {
    if (import.meta.env.PROD && import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_COMMIT_HASH) {
      Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
          new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: [
              /https:\/\/gw.alifnasiya\.uz/,
              /https:\/\/gw\.development\.k8s-dev\.alifnasiya\.uz/,
              /^\//,
            ],
          }),
        ],
        tracesSampleRate: 0.2,
        tracingOptions: {
          trackComponents: true,
        },
        logErrors: true,
        attachProps: true,
        attachStacktrace: true,
        ignoreErrors: [
          'Avoided redundant navigation to current location',
          /Loading chunk .* failed/,
          /Navigation cancelled from .* to .* with a new navigation/,
          'Navigation cancelled from',
        ],
        release: import.meta.env.VITE_COMMIT_HASH,
        beforeSend(event, hint) {
          if (hint.originalException?.$serverError) return;

          return event;
        },
      });
    }
  },
};
