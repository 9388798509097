<template>
  <div class="min-h-screen bg-white flex flex-col">
    <div class="bg-gray-100 mb-4 md:mb-6 md:bg-transparent md:space-y-6">
      <AtContainer v-if="!inIframe" class="w-full">
        <BaseLandingNavBar>
          <template #action>
            <BaseLangChange />

            <Menu v-if="$_at_user" as="div" class="ml-3 relative">
              <div>
                <MenuButton
                  class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <template v-if="$_at_user.avatar_link">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" :src="$_at_user.avatar_link" alt="avatar" />
                  </template>
                  <template v-else>
                    <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                      <span class="text-sm font-medium leading-none text-white">
                        {{ $_at_user.shortName }}
                      </span>
                    </span>
                  </template>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <MenuItem>
                    <a href="#" @click="logout" class="block px-4 py-2 text-sm text-gray-700">
                      {{ $t('system.btns.profile.exit') }}
                    </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </template>
        </BaseLandingNavBar>
      </AtContainer>
      <div v-if="isFormInit" class="pb-4 px-4 md:pb-0 sm:px-6 lg:px-8 max-w-md m-auto box-content">
        <div class="block md:hidden">
          <FormsStepsMobile :steps="stepsBadges" />
        </div>
        <div class="hidden md:block">
          <FormsStepsDesktop :steps="stepsBadges" />
        </div>
      </div>
    </div>
    <div class="space-y-2">
      <BaseAlert v-if="service.review.alert" :alert="service.review.alert" />
      <template v-if="isFormLoading">
        <div class="bg-white flex flex-col justify-center w-full items-center space-y-4">
          <AlifLoaderIcon class="w-16 h-16 text-primary-600" />
        </div>
      </template>
      <template v-else-if="isFormInit">
        <main class="flex-grow">
          <AtContainer short>
            <div class="max-w-md mx-auto">
              <AtAlert v-if="currentReasonsList.length" type="info" class="mb-2">
                <p v-for="reason in currentReasonsList" :key="reason.id">
                  {{ reason.body }}
                </p>
              </AtAlert>
            </div>
            <div class="pb-10 max-w-md mx-auto">
              <AtLoading v-if="service.review.loading" />
              <router-view v-else @previous-step="prevStep" @next-step="beforeNextStep" />
            </div>
          </AtContainer>
        </main>
      </template>
    </div>
  </div>
</template>
<script setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { computed, inject, reactive, ref, toRaw } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useRoute, useRouter } from 'vue-router';
  import * as Sentry from '@sentry/vue';

  import BaseLandingNavBar from '~/components/base/BaseLandingNavBar.vue';
  import BaseLangChange from '~/components/base/BaseLangChange.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import { useService } from '~/hooks/useService';
  import AtAlert from '~/plugins/aliftech-ui/components/AtAlert/AtAlert';
  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';
  import { logout as serviceLogout } from '~/services/auth.api';
  import { getMe, requestReview } from '~/services/profile.api';
  import { $_at_user } from '~/shared/at-user';
  import { $_clientRequirements, updateRequirements } from '~/shared/client-requirements';
  import { setStepsState, stepsState } from '~/shared/registration-steps';
  import { isSourceAlifshop } from '~/utils/isSourceAlifshop';
  import { $_at_app } from '~/shared/at-app';
  // TODO: утилита кажется общей для всех, но на самом деле только для роутов Forms.*
  import getFilesRouteName from '~/utils/getFilesRouteName';
  import { CLIENT_STATUSES } from '~/constants';
  import AlifLoaderIcon from '~/components/icons/AlifLoaderIcon';
  import { deepCopy } from '~/plugins/aliftech-ui/utils';
  import FormsStepsDesktop from '~/components/forms/FormsStepsDesktop.vue';
  import FormsStepsMobile from '~/components/forms/FormsStepsMobile.vue';
  import { FORMS_STEP_KEYS } from '~/constants/forms';
  import { ymReachGoal } from '~/plugins/ya-metrika/helpers';
  import { gaRegisterEvent } from '~/plugins/gtm';

  const router = useRouter();
  const route = useRoute();

  const inIframe = inject('inIframe');

  const { t } = useI18n();

  const $toast = inject('$toast');

  const service = {
    review: reactive(useService(requestReview)),
  };

  const SOURCE = sessionStorage.getItem('source');
  const isPartnerDomain = Boolean(SOURCE) && !isSourceAlifshop();

  const handleReview = () => {
    return service.review.execute().then(() => {
      ymReachGoal('request-review');
      gaRegisterEvent('request_review');
      getMe().then(res => {
        const me = res.data;
        const _me = { ...toRaw($_at_user.value) };
        $_at_user.value = {
          ..._me,
          ...me,
          clientId: me?.id,
        };
        Sentry.setUser({ id: $_at_user.value.clientId });

        if (inIframe) {
          window.top.postMessage('alifnasiya-registered', '*');
          window.top.postMessage(
            {
              type: 'alifnasiya-registered',
              clientId: $_at_user.value?.clientId,
              phone: $_at_user.value?.phone,
            },
            '*'
          );
        }

        if (isPartnerDomain) {
          return router.push({ name: 'Forms.Partner' });
        } else {
          return router.push({ name: 'App.Index' });
        }
      });
    });
  };

  const currentStep = computed(() => {
    if (!stepsState.value) return null;

    return Object.values(stepsState.value).find(value => value.routeName === route.name);
  });
  const currentReasonsList = computed(() => {
    if (!currentStep.value) return [];

    return (
      currentStep.value.reasons.map(reasonId => {
        return $_at_app.value?.fix_reasons?.find(reason => reason?.id === reasonId);
      }) ?? []
    );
  });

  const formsStepsLabels = computed(() => ({
    [FORMS_STEP_KEYS.questionnaire]: t('form.stepsLabels.questionnaire'),
    [FORMS_STEP_KEYS.cards]: t('form.stepsLabels.cards'),
  }));
  const stepsBadges = computed(() => {
    if (!stepsState.value) return [];

    const steps = deepCopy(stepsState.value);

    return Object.entries(steps)?.map(([key, value]) => {
      return {
        label: formsStepsLabels.value[key],
        isCompleted: value.completed || value.modifiers.skipped,
        isActive: value.routeName === route.name,
      };
    });
  });

  const logout = () => {
    serviceLogout();
  };

  const getRequirementsValues = requirements => {
    const { questionnaire, phones, card } = requirements;

    const hasQuestionnaire = (questionnaire?.value && phones?.value) ?? false;
    const hasCards = card?.value ?? false;

    // Порядок шагов задается здесь
    return {
      questionnaire: hasQuestionnaire,
      cards: hasCards,
    };
  };
  const generateStepsState = stepValues => {
    const steps = {};
    Object.keys(stepValues).forEach((step, index) => {
      const modifiers = {
        skipped: false,
        disabled: false,
        ...(stepsState.value?.[step]?.modifiers || {}),
      };
      steps[step] = {
        completed: stepValues[step],
        routeName: getFilesRouteName(step),
        reasons: [],
        orderIndex: index,
        modifiers,
      };
    });
    return steps;
  };

  // TODO: refactor
  const getStepsWithFixReasons = stepState => {
    const relationsFixToStep = {
      4: 'questionnaire',
      5: 'questionnaire',
      6: 'cards',
      7: 'cards',
      10: ['cards', 'questionnaire'],
      11: 'cards',
    };
    const fixReasons = $_at_user.value?.fix_reasons ?? [];
    const stepStateCopy = deepCopy(stepState);

    if (fixReasons.length) {
      fixReasons.forEach(reason => {
        const stepsForReason = relationsFixToStep[reason];
        if (Array.isArray(stepsForReason)) {
          stepsForReason.forEach(step => {
            stepStateCopy[step]?.reasons?.push(reason);
          });
        } else {
          stepStateCopy[stepsForReason]?.reasons?.push(reason);
        }
      });
    }

    return stepStateCopy;
  };
  const getRequirementsSteps = modifiers => {
    return updateRequirements().then(() => {
      const stepValues = getRequirementsValues($_clientRequirements.value);
      const stepState = getStepsWithFixReasons(generateStepsState(stepValues));

      // Модификаторы шагов
      if (modifiers?.stepKey && modifiers?.values) {
        stepState[modifiers.stepKey].modifiers = {
          ...stepState[modifiers.stepKey].modifiers,
          ...modifiers.values,
        };
      }

      return stepState;
    });
  };

  const findAvailableIncompleteStep = steps =>
    Object.values(steps).find(step => !step?.completed && !step.modifiers?.skipped && !step.modifiers?.disabled);

  const isFormLoading = ref(false);
  const isFormInit = ref(false);
  const initForm = async () => {
    isFormLoading.value = true;
    try {
      const stepState = await getRequirementsSteps();
      setStepsState(stepState);

      const incompleteStep = findAvailableIncompleteStep(stepsState.value);
      if (incompleteStep) {
        await redirectToStep(incompleteStep);
      } else {
        await handleReview().then(() => {
          $toast.success({
            title: t('auth.titles.registerSuccess'),
          });
        });
      }

      isFormInit.value = true;
    } finally {
      isFormLoading.value = false;
    }
  };

  // new steps control
  const previousSteps = computed(() => {
    if (!stepsState.value || !currentStep.value) return [];

    return (
      Object.values(stepsState.value)
        .reverse()
        .filter(step => step.orderIndex < currentStep.value.orderIndex) || []
    );
  });
  const nextSteps = computed(() => {
    if (!stepsState.value || !currentStep.value) return [];

    return Object.values(stepsState.value).filter(step => step.orderIndex > currentStep.value.orderIndex) || [];
  });

  const redirectToStep = async step => {
    return router.push({ name: step.routeName });
  };
  const prevStep = () => {
    if (!previousSteps.value.length) return;

    const targetStep = previousSteps.value.find(step => !step.modifiers.disabled);
    if (targetStep) {
      return redirectToStep(targetStep);
    }
  };
  const nextStep = () => {
    if (!nextSteps.value.length) return;

    const targetStep = nextSteps.value.find(step => !step.modifiers.disabled);
    if (targetStep) {
      return redirectToStep(targetStep);
    }
  };
  const isClientOnFixing = computed(() => $_at_user.value.status_key === CLIENT_STATUSES.fixed);
  const beforeNextStep = async modifiers => {
    if (currentStep.value.reasons.length) {
      $_at_user.value.fix_reasons = $_at_user.value.fix_reasons.filter(
        reasonId => !currentStep.value.reasons.includes(reasonId)
      );
    }

    // Обновление требований
    isFormLoading.value = true;
    const stepState = await getRequirementsSteps(modifiers);
    setStepsState(stepState);

    const incompleteStep = findAvailableIncompleteStep(stepsState.value);

    if (incompleteStep) {
      if (isClientOnFixing.value) {
        await redirectToStep(incompleteStep);
      } else {
        await nextStep();
      }
    } else {
      await handleReview().then(() => {
        $toast.success({
          title: t('auth.titles.registerSuccess'),
        });
      });
    }

    isFormLoading.value = false;
  };

  initForm();
</script>
