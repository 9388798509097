export default {
  dates: {
    months: {
      full: [
        'январь',
        'февраль',
        'март',
        'апрель',
        'май',
        'июнь',
        'июль',
        'август',
        'сентябрь',
        'октябрь',
        'ноябрь',
        'декабрь',
      ],
      short: ['янв.', 'фев.', 'мар.', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'],
    },
    weeks: {
      short: ['пн.', 'вт.', 'ср.', 'чт.', 'пт.', 'сб.', 'вс.'],
      full: ['понедельник', 'вторник', 'среда', 'чеверг', 'пятница', 'суббота', 'воскресенье'],
    },
    quarters: {
      short: {
        1: '1 кв.',
        2: '2 кв.',
        3: '3 кв.',
        4: '4 кв.',
      },
      full: { 1: '1 квартал', 2: '2 квартал', 3: '3 квартал', 4: '4 квартал' },
    },
    halfYears: {
      short: {
        1: '1/2',
        2: '2/2',
      },
      full: {
        1: 'Первое полугодие',
        2: 'Второе полугодие',
      },
    },
  },
};
