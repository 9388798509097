<template>
  <template v-if="isSuccess || !allowIdentification">
    <div class="relative flex-grow flex items-center justify-center">
      <div class="w-full m-auto" style="max-width: 353px">
        <div class="space-y-4">
          <div
            class="rounded-full w-16 h-16 flex justify-center items-center mx-auto"
            :class="{
              'bg-green-50 text-primary-600': isSuccess,
              'bg-yellow-50 text-yellow-500': !allowIdentification,
            }"
          >
            <CheckCircleIcon v-if="isSuccess" class="w-7 h-7" style="min-width: 28px" />
            <ExclamationCircleIcon v-else-if="!allowIdentification" class="w-7 h-7" style="min-width: 28px" />
          </div>
          <div class="space-y-6">
            <div class="space-y-2 text-center">
              <div class="text-base font-bold">
                <template v-if="isSuccess">
                  {{ $t('app.messages.profile.phone') }}
                </template>
                <template v-else-if="!allowIdentification">
                  {{ $t('system.errors.telegram') }}
                </template>
              </div>
            </div>
            <div class="space-y-3">
              <AtButton
                v-if="isSuccess"
                size="lg"
                color="primary"
                class="w-full"
                :to="{ name: 'Profile' }"
                data-testid="back_to_profile_button"
              >
                {{ $t('system.btns.back') }}
              </AtButton>
              <template v-else-if="!allowIdentification">
                <AtButton
                  size="lg"
                  color="primary"
                  class="w-full"
                  :to="{}"
                  href="https://t.me/alifazo"
                  target="_blank"
                  data-testid="go_telegram_button"
                >
                  {{ $t('system.btns.goTelegram') }}
                </AtButton>
                <AtButton size="lg" class="w-full" :to="{ name: 'Profile' }" data-testid="cancel_button">
                  {{ $t('system.btns.cancel') }}
                </AtButton>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="px-4 pb-12 min-h-screen">
      <div class="w-full m-auto" style="max-width: 440px">
        <div class="space-y-6">
          <div class="space-y-4">
            <a
              v-if="currentStep !== STEPS.phoneCheck"
              href="#"
              class="text-primary-500 flex items-center space-x-1"
              @click.prevent="goBack"
              data-testid="go_back_link"
            >
              <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
              <span data-testid="go_back_text"> {{ $t('system.btns.back') }} </span>
            </a>
            <div class="space-y-2">
              <AtTitle v-if="heading.title" data-testid="new_phone_title"> {{ heading.title }} </AtTitle>
              <div v-if="heading.description" class="text-sm text-gray-500">
                {{ heading.description }}
              </div>
              <template v-if="currentStep === STEPS.otpVerify">
                <button
                  class="text-primary-500 text-sm text-left"
                  :class="{
                    'opacity-50': service.verifyCode.loading,
                  }"
                  :disabled="service.verifyCode.loading"
                  @click="goBack"
                  data-testid="set_anothoer_phone_button"
                >
                  {{ $t('auth.titles.setPhone.another') }}
                </button>
              </template>
            </div>
          </div>
          <template v-if="currentStep === STEPS.phoneCheck">
            <BasePhoneCheckForm
              ref="phoneCheckFormRef"
              v-model="phoneFields.phone"
              @submit="onPhoneSubmit"
              data-testid="phone_check_form"
            />
          </template>
          <template v-else-if="currentStep === STEPS.myId">
            <BaseAlert :alert="service.getCode?.alert" :errors="service.getCode?.errors" />
            <BaseMyIdIdentification
              :service-check="services.identification.check"
              :service-check-payload="checkIdentificationPayload"
              :service-request="services.identification.request"
              has-skip
              @check-success="onIdentificationCheckSuccess"
              @check-error="onIdentificationCheckError"
              @skip="router.replace({ name: 'Profile' })"
            />
          </template>
          <template v-else-if="currentStep === STEPS.otpVerify">
            <form class="space-y-6" @submit.prevent="confirmCode" data-testid="confirm_code_form">
              <div>
                <AtInput
                  v-model="otpFields.code"
                  :disabled="service.verifyCode.loading || service.getCode.loading"
                  :label="$t('system.fields.smsCode')"
                  type="tel"
                  mask="####"
                  :error="Boolean($vOtp.code.$error || service?.verifyCode?.errors?.otp)"
                  data-testid="confirm_code_input"
                />
                <BaseFieldErrors
                  :errors="$vOtp.code.$errors.length ? $vOtp.code.$errors : service?.verifyCode?.errors?.otp"
                />
              </div>
              <div class="flex flex-col space-y-2 text-left">
                <BaseCountdown :time="phoneFields.finish" data-testid="timer">
                  <template #default="{ minutes, seconds }">
                    <div class="flex flex-col flex-wrap justify-start">
                      <p class="text-gray-500 text-sm">
                        {{ $t('system.fields.smsCodeExpire') }}: {{ minutes.toString().padStart(2, '0') }}:{{
                          seconds.toString().padStart(2, '0')
                        }}
                      </p>
                    </div>
                  </template>
                </BaseCountdown>
                <button
                  class="text-primary-500 text-sm text-left"
                  :class="{
                    'opacity-50': service.getCode.loading,
                  }"
                  :disabled="service.getCode.loading"
                  type="button"
                  @click="getOtpCode"
                  data-testid="resend_sms_button"
                >
                  {{ $t('system.btns.repeatSmsSend') }}
                </button>
              </div>
              <AtAlert
                v-if="identificationAliveHasError"
                type="danger"
                :title="$t('app.titles.profile.phone.errors.myIdTimeout.title')"
              >
                {{ $t('app.titles.profile.phone.errors.myIdTimeout.message') }}
              </AtAlert>
              <BaseAlert :alert="service.verifyCode?.alert" :errors="service.verifyCode?.errors" />
              <AtButton
                :loading="service.verifyCode.loading"
                :disabled="service.getCode.loading"
                color="primary"
                size="lg"
                class="w-full"
                type="submit"
                data-testid="change_phone_button"
              >
                {{ $t('app.btns.profile.changePhone') }}
              </AtButton>
            </form>
          </template>
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
  import { reactive, onBeforeMount, computed, ref, inject, watch } from 'vue';
  import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/20/solid';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength, helpers } from '@vuelidate/validators';
  import { useRoute, useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtAlert from '~/plugins/aliftech-ui/components/AtAlert/AtAlert';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import BaseCountdown from '~/components/base/BaseCountdown';
  import BaseAlert from '~/components/base/BaseAlert.vue';

  import { useService } from '~/hooks/useService';
  import { applyDataFromCookie } from '~/utils/cookie';

  import {
    getOtpCode as privateGetOtpCode,
    verifyOtpCode as privateVerifyOtpCode,
    requestIdentification as privateRequestIdentification,
    checkIdentification as privateCheckIdentification,
  } from '~/services/profile.api';
  import { $_at_user } from '~/shared/at-user';
  import BasePhoneCheckForm from '~/components/base/BasePhoneCheckForm.vue';
  import BaseMyIdIdentification from '~/components/base/BaseMyIdIdentification.vue';
  import { formatPhoneNumber } from '~/utils/formatPhoneNumber';

  const { t } = useI18n();
  const router = useRouter();
  const cookies = inject('$cookies');

  const IDENTIFICATION_LIFETIME = '10MIN';
  const IDENTIFICATION_COOKIE_KEY = 'update-phone-identification-active';

  // STEPS
  const STEPS = {
    phoneCheck: 'PHONE_CHECK',
    myId: 'MY_ID',
    otpVerify: 'OTP_VERIFY',
  };
  const currentStep = ref(STEPS.phoneCheck);

  const services = {
    identification: {
      check: privateCheckIdentification,
      request: privateRequestIdentification,
    },
    otp: {
      get: privateGetOtpCode,
      verify: privateVerifyOtpCode,
    },
  };
  const service = {
    getCode: reactive(useService(services.otp.get)),
    verifyCode: reactive(useService(services.otp.verify)),
  };

  // PHONE CHECK FORM
  const phoneFields = reactive({
    phone: null,
    finish: null,
  });
  const maskedPhone = computed(() => {
    let phone = phoneFields.phone;
    return formatPhoneNumber(phone, { masked: true });
  });
  watch(
    phoneFields,
    value => {
      if (new Date().getTime() < value.finish && value.phone) {
        currentStep.value = STEPS.otpVerify;
      }
    },
    { deep: true }
  );
  const onPhoneSubmit = () => {
    currentStep.value = STEPS.myId;
  };

  const payloadPhone = computed(() => (phoneFields.phone.length <= 9 ? '998' + phoneFields.phone : phoneFields.phone));

  // GET OTP CODE
  const getOtpCodePayload = computed(() => {
    return {
      phone: payloadPhone.value,
    };
  });
  const getOtpCode = () => {
    if (checkIdentificationAlive()) {
      return service.getCode.execute(getOtpCodePayload.value).then(res => {
        phoneFields.finish = res.data.finish;
        currentStep.value = STEPS.otpVerify;
        return res;
      });
    }
  };

  // MY ID FORM
  const onIdentificationCheckError = error => {
    if (error?.code === 'limit_exceeded') {
      allowIdentification.value = false;
    }
  };
  const onIdentificationCheckSuccess = () => {
    cookies.set(IDENTIFICATION_COOKIE_KEY, true, IDENTIFICATION_LIFETIME);

    getOtpCode();
  };

  const checkIdentificationPayload = computed(() => {
    return {
      phone: payloadPhone.value,
    };
  });

  // VERIFY OTP FORM
  const defaultOtpFields = {
    code: null,
  };
  const otpFieldRules = {
    code: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(t('validations.smsCodeMinLength', { number: 4 }), minLength(4)),
    },
  };
  const otpFields = reactive({ ...defaultOtpFields });
  const $vOtp = useVuelidate(otpFieldRules, otpFields);
  const verifyOtpCodePayload = computed(() => {
    return {
      otp: otpFields.code,
    };
  });
  const confirmCode = async () => {
    if (!(await $vOtp.value.$validate())) return;

    checkIdentificationAlive();
    service.verifyCode.execute(verifyOtpCodePayload.value).then(({ data }) => {
      isSuccess.value = true;
      phoneFields.finish = null;
      phoneFields.phone = null;
      cookies.remove('online-profile-update-phone');

      if ($_at_user.value?.phone) {
        $_at_user.value.phone = data.phone;
      }
    });
  };

  const isSuccess = ref(false);
  const allowIdentification = ref(true);

  const phoneCheckFormRef = ref(null);
  const goBack = () => {
    if (currentStep.value === STEPS.otpVerify) {
      cookies.remove('online-profile-update-phone');
      phoneFields.finish = null;
      phoneFields.phone = null;
      currentStep.value = STEPS.phoneCheck;
      phoneCheckFormRef.value.reset();
      Object.assign(otpFields, defaultOtpFields);
    } else if (currentStep.value === STEPS.phoneCheck) {
      router.push({
        name: 'Profile',
      });
    } else {
      currentStep.value = STEPS.phoneCheck;
    }
  };

  const identificationAliveHasError = ref(false);
  const checkIdentificationAlive = () => {
    if (!cookies.get(IDENTIFICATION_COOKIE_KEY)) {
      identificationAliveHasError.value = true;
      return false;
    }
    return true;
  };

  const heading = computed(() => {
    const result = {
      title: t('app.titles.profile.phone.newPhone'),
      description: '',
    };

    if (currentStep.value === STEPS.otpVerify) {
      result.title = t('auth.titles.smsConfirm');
      result.description = t('auth.titles.sendCode', { phone: maskedPhone.value });
    } else if (currentStep.value === STEPS.myId) {
      result.title = t('form.myid.title');
      result.description = t('app.titles.profile.phone.myIdDescription');
    }

    return result;
  });

  const route = useRoute();
  onBeforeMount(() => {
    applyDataFromCookie(phoneFields, ['phone', 'finish'], 'online-profile-update-phone');

    if (new Date().getTime() < phoneFields.finish) {
      currentStep.value = STEPS.otpVerify;
    }

    if (route.query?.phone) {
      phoneFields.phone = route.query?.phone;
    }
  });
</script>
