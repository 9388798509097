import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M2.00198 12.002C2.00315 16.9214 5.58039 21.1101 10.439 21.881V14.892H7.90198V12.002H10.442V9.80205C10.3284 8.75958 10.6845 7.72064 11.4136 6.96698C12.1427 6.21332 13.1693 5.82306 14.215 5.90205C14.9655 5.91417 15.7142 5.98101 16.455 6.10205V8.56105H15.191C14.7558 8.50405 14.3183 8.64777 14.0018 8.95171C13.6852 9.25566 13.5238 9.68693 13.563 10.124V12.002H16.334L15.891 14.893H13.563V21.881C18.8175 21.0506 22.5021 16.2518 21.9475 10.9611C21.3929 5.67041 16.7933 1.73997 11.4808 2.01722C6.16834 2.29447 2.00283 6.68235 2.00198 12.002Z',
          fill: 'currentColor',
        }),
      ]
    )
  );
}
