<template>
  <template v-if="loading">
    <AtSkeleton class="w-full h-14" />
  </template>
  <template v-else-if="contractLink">
    <a
      :href="contractLink"
      target="_blank"
      class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-2 hover:bg-gray-200"
    >
      <span class="flex items-center space-x-4">
        <DocumentTextIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
        <span class="font-bold">{{ $t('app.titles.installment.detail.act') }}</span>
      </span>
      <ArrowDownTrayIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
    </a>
  </template>
</template>

<script>
  export default {
    name: 'InstallmentsFileContract',
  };
</script>

<script setup>
  import { onBeforeMount, reactive, computed, ref } from 'vue';
  import { DocumentTextIcon } from '@heroicons/vue/24/outline';
  import { ArrowDownTrayIcon } from '@heroicons/vue/20/solid';

  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';

  import { useService } from '~/hooks/useService';
  import { getBnplContract, getBmlContract } from '~/services/credits.api';
  import { getFile } from '~/services/profile.api';

  const props = defineProps({
    application: {
      type: Object,
      default: () => ({}),
    },
    creditType: {
      type: String,
      default: null,
    },
    creditId: {
      type: Number,
      required: true,
    },
  });

  const service = {
    getBnplContract: reactive(useService(getBnplContract)),
    getBmlContract: reactive(useService(getBmlContract)),
    getProfileContractFile: reactive(useService(getFile)),
  };
  const loading = computed(
    () => service.getBnplContract.loading || service.getBmlContract.loading || service.getProfileContractFile.loading
  );

  const contractLink = ref(null);

  const getContractFromCreditsService = () => {
    let request = null;

    if (props.creditType === 'BML') {
      request = service.getBmlContract.execute;
    } else if (props.creditType === 'BNPL') {
      request = service.getBnplContract.execute;
    }

    if (request) {
      request(props.creditId).then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        contractLink.value = window.URL.createObjectURL(blob);
      });
    }
  };

  const fileId = computed(() => props.application?.file_id);
  const fileContract = computed(() => props.application?.contract);
  const hasFileContract = computed(() => Boolean(fileContract.value?.link));
  const getContract = () => {
    if (fileId.value) {
      return service.getProfileContractFile.execute(fileId.value).then(response => {
        contractLink.value = response.data?.link;
      });
    } else if (hasFileContract.value) {
      contractLink.value = fileContract.value.link;
    } else {
      getContractFromCreditsService();
    }
  };

  onBeforeMount(() => {
    getContract();
  });
</script>
