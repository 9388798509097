<template>
  <div class="flex items-center gap-1">
    <template v-for="(step, index) in steps" :key="index">
      <div
        class="rounded-md space-x-1 py-1 flex items-center justify-center"
        :class="[step.isActive ? 'bg-primary-50 text-primary-600' : 'bg-gray-100 text-gray-500']"
        style="flex-basis: 100%"
        data-testid="forms_step_desktop"
      >
        <CheckCircleIcon
          v-if="step.isCompleted && !step.isActive"
          class="w-4 h-4 text-gray-400"
          style="min-width: 16px"
        />
        <span class="text-sm">{{ step.label }}</span>
      </div>
      <ChevronRightIcon v-if="index + 1 !== steps.length" class="w-4 h-4 text-gray-400" style="min-width: 24px" />
    </template>
  </div>
</template>

<script>
  export default {
    name: 'FormsStepsDesktop',
  };
</script>

<script setup>
  import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';

  defineProps({
    steps: {
      type: Array,
      required: true,
    },
  });
</script>

<style scoped></style>
