import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '171',
        height: '42',
        viewBox: '0 0 171 42',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('g', { 'clip-path': 'url(#clip0_138_2720)' }, [
          _createElementVNode('path', {
            d: 'M18.76 41.909H18.7222H18.711C14.6023 41.4962 10.7097 39.8686 7.52988 37.234C4.35009 34.5994 2.02729 31.0772 0.857831 27.1169C-0.311623 23.1565 -0.274698 18.9375 0.963896 14.9982C2.20249 11.0589 4.58659 7.57794 7.81201 4.9994L7.85401 4.963C11.1204 2.27832 15.0755 0.565427 19.2682 0.0196L19.46 0L21.2464 4.3988L21.049 4.4142C17.3322 4.64546 13.7786 6.02385 10.878 8.3594L10.738 8.4686C10.6666 8.526 10.598 8.5848 10.5252 8.645L10.4706 8.6898C7.98969 10.7356 6.17233 13.4721 5.24905 16.5524C4.32577 19.6326 4.33818 22.9176 5.28472 25.9907C6.23126 29.0639 8.06927 31.7866 10.5656 33.8136C13.0619 35.8406 16.104 37.0804 19.306 37.3758C23.972 37.7648 28.6058 36.32 32.2238 33.348C32.3927 33.208 32.5603 33.0652 32.7264 32.9196L34.5156 37.3254C30.0273 40.7808 24.4015 42.4174 18.76 41.909ZM23.5312 10.3474H17.9886L10.5966 28.539H15.3384L20.7592 14.4886L26.18 28.539H30.9218L23.5312 10.3474Z',
            fill: '#39B980',
          }),
          _createElementVNode('path', {
            d: 'M64.3132 7.23521H60.8314V27.6024H64.3132V7.23521ZM69.1516 11.3078C70.4116 11.3078 71.3454 10.3964 71.3454 9.10841C71.3535 8.81792 71.3023 8.52883 71.195 8.25878C71.0876 7.98873 70.9264 7.74338 70.7211 7.53769C70.5158 7.33201 70.2708 7.17032 70.0009 7.06247C69.7311 6.95462 69.4421 6.90288 69.1516 6.91041C67.8916 6.91041 66.899 7.84981 66.899 9.10841C66.899 10.3964 67.8636 11.3078 69.1516 11.3078ZM70.8652 13.0508H67.354V27.6024H70.8652V13.0508ZM81.326 9.80001C81.97 9.80001 82.7498 9.98901 83.6598 10.3908L84.7588 7.65521C83.5538 7.01354 82.2109 6.67433 80.8458 6.66681C77.3878 6.66681 75.054 8.70101 75.054 12.3718V13.0452H72.7496V15.995H75.054V27.5968H78.54V16.0006H83.097V13.0508H78.54V12.46C78.54 10.7464 79.52 9.80001 81.3246 9.80001H81.326Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M54.5804 20.314C54.5804 21.574 54.1772 22.6198 53.3736 23.4766C52.57 24.3334 51.3254 24.7604 50.0668 24.7604C48.8082 24.7604 47.7806 24.3404 46.9868 23.4766C46.193 22.6128 45.8108 21.574 45.8108 20.314C45.8108 19.0792 46.2028 18.0292 46.9868 17.1766C47.7708 16.324 48.8068 15.8886 50.0668 15.8886C51.3268 15.8886 52.57 16.3198 53.3736 17.1766C54.1735 18.0226 54.6072 19.15 54.5804 20.314ZM54.502 14.4018C53.2 13.2818 51.6068 12.7022 49.756 12.7022C47.6938 12.7022 45.7226 13.4232 44.3576 14.847C42.9926 16.2708 42.3178 18.0866 42.3178 20.2608C42.3178 22.435 42.9912 24.2802 44.3576 25.753C45.724 27.2258 47.6644 27.951 49.756 27.951C51.66 27.951 53.074 27.3994 54.502 26.257V27.6024H57.82V13.0508H54.502V14.4018Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M91.56 27.5506V13.16H94.08V15.1998C94.9928 13.5898 96.5137 12.7848 98.6426 12.7848C100.253 12.7848 101.606 13.3037 102.703 14.3416C103.799 15.3795 104.345 16.8728 104.339 18.8216V27.5436H101.78V19.5538C101.78 18.1762 101.44 17.0935 100.759 16.3058C100.097 15.5535 99.2101 15.1802 98.0994 15.1858C97.5599 15.1709 97.0235 15.2736 96.5277 15.4869C96.0318 15.7001 95.5883 16.0187 95.228 16.4206C94.4944 17.2606 94.1276 18.3162 94.1276 19.5874V27.5576L91.56 27.5506Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M119.064 13.16H121.534V27.5506H119.064V25.5122C117.776 27.1045 116.086 27.9006 113.992 27.9006C111.916 27.9006 110.198 27.167 108.839 25.6998C107.48 24.2326 106.8 22.4163 106.799 20.251C106.799 18.0866 107.479 16.3156 108.839 14.938C110.2 13.524 111.917 12.817 113.992 12.817C116.032 12.817 117.722 13.6313 119.064 15.26V13.16ZM117.856 16.6768C116.945 15.6744 115.756 15.1732 114.288 15.1732C112.82 15.1732 111.637 15.6763 110.74 16.6824C109.845 17.6848 109.398 18.9103 109.399 20.3588C109.4 21.8073 109.847 23.0328 110.74 24.0352C111.635 25.0376 112.816 25.5388 114.283 25.5388C115.751 25.5388 116.94 25.0376 117.852 24.0352C118.765 23.0337 119.221 21.7994 119.221 20.3322C119.224 18.8827 118.769 17.6661 117.856 16.6824V16.6768Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M125.957 27.0956C124.986 26.5545 124.193 25.7433 123.675 24.7604L125.527 23.338C126.35 24.8593 127.54 25.62 129.097 25.62C129.843 25.62 130.46 25.4231 130.948 25.0292C131.166 24.8674 131.344 24.6563 131.465 24.413C131.587 24.1697 131.649 23.9012 131.648 23.6292C131.648 22.8592 131.209 22.2525 130.332 21.8092C130.099 21.6841 129.5 21.4158 128.534 21.0042C127.658 20.6309 127.067 20.3625 126.763 20.1992C125.296 19.3947 124.562 18.2317 124.562 16.7104C124.548 16.1659 124.657 15.6253 124.88 15.1283C125.103 14.6314 125.434 14.1908 125.85 13.839C126.69 13.069 127.827 12.684 129.259 12.684C131.313 12.684 132.833 13.4447 133.82 14.966L132.182 16.52C131.396 15.4093 130.369 14.8545 129.102 14.8554C128.475 14.8554 127.974 15.0253 127.599 15.365C127.418 15.5151 127.274 15.7042 127.177 15.9181C127.08 16.132 127.033 16.365 127.039 16.5998C127.039 17.3521 127.441 17.9158 128.246 18.291L129.829 18.991C130.493 19.2403 131.139 19.5364 131.761 19.8772C133.39 20.7004 134.204 21.9081 134.204 23.5004C134.204 24.7352 133.721 25.7726 132.755 26.6126C131.77 27.4526 130.527 27.8726 129.024 27.8726C127.951 27.8841 126.894 27.6164 125.957 27.0956Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M136.555 10.5896C136.377 10.4209 136.238 10.2166 136.145 9.98995C136.052 9.76335 136.009 9.51968 136.017 9.27501C136.01 9.03449 136.055 8.79528 136.147 8.57323C136.24 8.35118 136.379 8.15136 136.555 7.98701C136.734 7.81715 136.945 7.68453 137.176 7.59682C137.407 7.50911 137.653 7.46805 137.9 7.47601C138.14 7.47263 138.378 7.5186 138.6 7.61108C138.821 7.70356 139.022 7.84057 139.188 8.01361C139.362 8.17364 139.499 8.3687 139.592 8.5859C139.685 8.80311 139.73 9.0375 139.726 9.27361C139.734 9.51828 139.69 9.76195 139.598 9.98855C139.505 10.2152 139.365 10.4195 139.188 10.5882C139.022 10.7611 138.821 10.8979 138.6 10.9901C138.378 11.0823 138.14 11.128 137.9 11.1244C137.651 11.1321 137.403 11.0885 137.172 10.9965C136.94 10.9046 136.73 10.7661 136.555 10.5896ZM139.131 27.5506H136.555V13.16H139.131V27.5506Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M152.201 13.16H155.046L149.008 29.162C147.879 32.1673 146.215 33.67 144.015 33.67C143.102 33.67 142.333 33.4833 141.707 33.11L142.029 30.8546C142.574 31.1177 143.168 31.2641 143.773 31.2844C144.955 31.2844 145.903 30.4444 146.618 28.7644L147.155 27.5044L140.902 13.16H143.881L148.336 23.9498L152.201 13.16Z',
            fill: '#111827',
          }),
          _createElementVNode('path', {
            d: 'M167.702 13.16H170.171V27.5506H167.702V25.5122C166.414 27.1045 164.723 27.9006 162.63 27.9006C160.553 27.9006 158.835 27.167 157.476 25.6998C156.117 24.2326 155.438 22.4163 155.438 20.251C155.438 18.0866 156.117 16.3156 157.476 14.938C158.837 13.524 160.555 12.817 162.63 12.817C164.669 12.817 166.36 13.6313 167.702 15.26V13.16ZM166.495 16.6768C165.582 15.6744 164.392 15.1732 162.925 15.1732C161.458 15.1732 160.277 15.6744 159.383 16.6768C158.487 17.6792 158.039 18.9047 158.04 20.3532C158.041 21.8017 158.489 23.0272 159.383 24.0296C160.276 25.032 161.457 25.5332 162.925 25.5332C164.393 25.5332 165.583 25.032 166.495 24.0296C167.405 23.0365 167.86 21.8041 167.86 20.3322C167.86 18.8827 167.405 17.6661 166.495 16.6824V16.6768Z',
            fill: '#111827',
          }),
        ]),
        _createElementVNode('defs', null, [
          _createElementVNode('clipPath', { id: 'clip0_138_2720' }, [
            _createElementVNode('rect', {
              width: '170.625',
              height: '42',
              fill: 'white',
            }),
          ]),
        ]),
      ]
    )
  );
}
