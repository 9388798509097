export const getFilesRouteName = type => {
  const _type = type.replaceAll('_', '-').toLowerCase();
  const routeName = `Forms.${_type
    .split('-')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join('.')}`;

  return routeName;
};

export default getFilesRouteName;
