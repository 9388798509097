<template>
  <AtModal
    :model-value="modelValue"
    :title="$t('app.titles.installment.penalties.modalTitle')"
    size="sm"
    @update:model-value="val => $emit('update:modelValue', val)"
  >
    <template #default="{ close }">
      <div class="space-y-5 pt-3">
        <AtTable :head="tableHead" :elements="loading ? Array(3).fill(null) : items" class="overflow-hidden shadow">
          <template #element="{ element }">
            <AtTableItem>
              <template v-if="loading">
                <AtSkeleton />
              </template>
              <template v-else>
                <div class="text-sm font-medium text-gray-900">
                  {{ element.date }}
                </div>
              </template>
            </AtTableItem>
            <AtTableItem>
              <template v-if="loading">
                <AtSkeleton />
              </template>
              <template v-else>
                <div class="text-sm font-medium text-gray-500">
                  {{ element.amount }}
                </div>
              </template>
            </AtTableItem>
          </template>
          <template v-if="!items.length && !loading" #footer>
            <p class="text-center text-sm text-gray-500">
              {{ $t('app.titles.installment.penalties.noData') }}
            </p>
          </template>
        </AtTable>
        <AtButton class="w-full" size="lg" @click="close">
          {{ $t('system.btns.close') }}
        </AtButton>
      </div>
    </template>
  </AtModal>
</template>

<script setup>
  import { ref } from 'vue';

  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtTable from '~/plugins/aliftech-ui/components/AtTable/AtTable';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import AtTableItem from '~/plugins/aliftech-ui/components/AtTableItem/AtTableItem';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const tableHead = ref([
    { title: t('app.titles.installment.table.penalties[0]') },
    { title: t('app.titles.installment.table.penalties[2]') },
  ]);

  defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  });
  defineEmits(['update:modelValue']);
</script>
