import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M11.8688 9.02516C12.4394 8.89317 13.0233 9.18513 13.2601 9.72081C13.3221 9.8468 13.3594 9.98352 13.3699 10.1236C13.492 11.8566 13.7483 15.6522 13.8947 17.7025C13.9197 18.0718 14.0909 18.416 14.3703 18.6588C14.6498 18.9016 15.0144 19.023 15.3837 18.9962V18.9962L22.9138 18.5324C23.25 18.5122 23.5795 18.6318 23.8245 18.8629C24.0695 19.094 24.2081 19.4161 24.2075 19.7528V19.7528C23.9023 24.3 20.6353 28.1014 16.1857 29.0867C11.7361 30.0721 7.16967 28.0054 4.97328 24.0122C4.31761 22.8629 3.90239 21.5923 3.75284 20.2776C3.6889 19.8742 3.66438 19.4654 3.67961 19.0572C3.69256 14.2012 7.10296 10.0171 11.8566 9.02516',
          stroke: '#1F2937',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M28.6987 13.0038C27.8466 7.75928 23.3919 3.85763 18.0808 3.70404C17.8283 3.69414 17.5822 3.78506 17.3968 3.95675C17.2114 4.12845 17.1018 4.36684 17.0923 4.61937V4.61937V4.7048L17.6903 13.6506C17.7295 14.2528 18.2472 14.7106 18.8497 14.6758L27.82 14.0778C28.0727 14.0591 28.3076 13.9404 28.4725 13.748C28.6375 13.5555 28.7189 13.3053 28.6987 13.0526V13.0038Z',
          stroke: '#8B5CF6',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
      ]
    )
  );
}
