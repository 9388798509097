<template>
  <div class="rounded-xl border border-gray-100 shadow px-6 py-6">
    <dl class="divide-y divide-gray-200 -mt-4">
      <div class="py-4 md:grid md:grid-cols-4 md:gap-4">
        <dt class="text-sm font-medium text-gray-900 leading-5 md:col-span-2">№ {{ application.id }}</dt>
        <dd class="mt-1 text-sm md:text-right text-gray-700 md:mt-0 md:col-span-2">
          <ApplicationBadge rounded :status="application.status.key">
            {{ status?.body }}
          </ApplicationBadge>
        </dd>
      </div>

      <div class="py-4 md:grid md:grid-cols-4 md:gap-4">
        <dt class="text-sm font-medium text-gray-900 leading-5 md:col-span-2">
          {{ $t('app.fields.applications.amount') }}
        </dt>
        <dd class="mt-1 text-sm md:text-right text-gray-700 md:mt-0 md:col-span-2">
          {{ application._amount }}
        </dd>
      </div>

      <div class="py-4 md:grid md:grid-cols-4 md:gap-4">
        <dt class="text-sm font-medium text-gray-900 leading-5 md:col-span-2">
          {{ $t('app.fields.applications.date') }}
        </dt>
        <dd class="mt-1 text-sm md:text-right text-gray-700 md:mt-0 md:col-span-2">
          {{
            transformDate(application?.created_at, {
              dateFormat: 'dd MMM yyyy',
            })
          }}
        </dd>
      </div>
    </dl>
    <slot name="action" />
  </div>
</template>

<script setup>
  import { h, computed } from 'vue';

  import AtBadge from '~/plugins/aliftech-ui/components/AtBadge/AtBadge';
  import { transformDate } from '~/utils';
  import { $_at_app } from '~/shared/at-app';

  const props = defineProps({
    application: {
      type: Object,
      required: true,
    },
  });

  // Badge Component
  const STATUS_KEYS = {
    NEW: '',
    REVIEWING: 'blue',
    APPROVED: 'yellow',
    REJECTED: 'red',
    COMPLETED: 'green',
    CANCELLED: 'purple',
    DELIVERING: 'indigo',
    DELETED: 'pink',
    undefined: 'red',
  };

  const ApplicationBadge = (props, context) => {
    return h(
      AtBadge,
      {
        rounded: true,
        color: STATUS_KEYS[props.status],
      },
      () => context.slots.default()
    );
  };

  ApplicationBadge.props = {
    status: { type: [String], validator: status => status in STATUS_KEYS },
  };
  // Badge Component END

  const applicationsStatuses = computed(() => $_at_app.value?.application_statuses);
  const status = computed(() =>
    applicationsStatuses.value?.find(status => status?.id === props.application?.status?.id)
  );
</script>

<style lang="scss" scoped></style>
