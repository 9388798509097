<template>
  <div class="px-4 pb-12 min-h-screen">
    <div class="w-full m-auto" style="max-width: 440px">
      <div class="space-y-6">
        <AtTitle> {{ $t('app.titles.profile.cards.title') }} </AtTitle>
        <div class="flex flex-col space-y-2 md:space-y-4">
          <template v-if="service.cards.loading">
            <AtSkeleton style="height: 96px" />
          </template>
          <template v-else>
            <AppPaymentCard v-for="(card, index) in cards" :key="card?.id || index" :card="card">
              <template v-if="!card?.is_main" #end>
                <AtDropdown position="left">
                  <template #button>
                    <div class="h-6 w-6">
                      <template v-if="card.isLoading">
                        <AtLoading />
                      </template>
                      <template v-else>
                        <MenuButton>
                          <EllipsisVerticalIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
                        </MenuButton>
                      </template>
                    </div>
                  </template>
                  <AtDropdownItem @click="changeMainCard(card, index)">
                    {{ $t('system.btns.setMainCard') }}
                  </AtDropdownItem>
                </AtDropdown>
              </template>
            </AppPaymentCard>
            <router-link
              :to="{ name: 'App.Cards.Add' }"
              class="bg-gray-100 p-4 md:p-6 rounded-2xl flex items-center justify-between space-x-2 hover:bg-gray-200"
            >
              <div class="space-x-4 flex items-center">
                <div
                  class="flex items-center justify-center rounded-lg border border-gray-300 text-gray-500"
                  :style="{
                    width: '64px',
                    height: '48px',
                  }"
                >
                  <PlusCircleIcon class="h-6 w-6" style="min-width: 24px" />
                </div>
                <div class="flex justify-center flex-col space-y-1">
                  <div class="font-medium">
                    {{ $t('app.btns.profile.cards.add') }}
                  </div>
                </div>
              </div>
              <ChevronRightIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
            </router-link>
          </template>
        </div>
        <BaseAlert :alert="service.setCard.alert" :errors="service.setCard.errors" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { reactive, onBeforeMount, inject, ref } from 'vue';
  import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
  import { MenuButton } from '@headlessui/vue';
  import { PlusCircleIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
  import { useI18n } from 'vue-i18n';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import AtDropdown from '~/plugins/aliftech-ui/components/AtDropdown/AtDropdown';
  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';
  import AtDropdownItem from '~/plugins/aliftech-ui/components/AtDropdownItem/AtDropdownItem';
  import { getCards, setMainCard } from '~/services/profile.api';
  import { useService } from '~/hooks/useService';
  import AppPaymentCard from '~/components/app/AppPaymentCard.vue';
  import { $_at_user } from '~/shared/at-user';

  const { t } = useI18n();

  const service = {
    cards: reactive(useService(getCards)),
    setCard: reactive(useService(setMainCard)),
  };
  const $toast = inject('$toast');

  const cards = ref([]);

  const changeMainCard = (card, index) => {
    cards.value[index].isLoading = true;
    service.setCard
      .execute(card?.id, $_at_user.value.clientId)
      .then(() => {
        $toast.success({
          title: t('system.fields.card.mainCardChanged'),
          subTitle: t('system.fields.card.cardChangedForAll'),
        });

        cards.value = cards.value.map(cardItem => ({
          ...cardItem,
          is_main: card?.id === cardItem?.id ? true : false,
        }));

        service.cards.setResponse(cards.value);
      })
      .finally(() => (cards.value[index].isLoading = false));
  };

  onBeforeMount(async () => {
    await service.cards.execute($_at_user.value.clientId).then(res => {
      cards.value = res?.data?.map(card => ({
        ...card,
        isLoading: false,
      }));
    });
  });
</script>
