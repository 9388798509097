<template>
  <div class="space-y-4">
    <div class="space-y-3">
      <AtAlert v-if="mediaDevice.hasError" type="danger" class="mt-5">
        <p>
          {{ $t('form.myid.mediaDeviceError') }}
        </p>
      </AtAlert>
      <slot name="alert" />
    </div>
    <div class="space-y-6">
      <template v-if="previewFaceImageUrl">
        <img
          :src="previewFaceImageUrl"
          class="object-cover rounded-2xl transform scale-x-n1 h-80 md:h-40 mx-auto"
          alt=""
        />
      </template>
      <div v-show="!previewFaceImageUrl" class="text-sm space-y-6">
        <div>
          <img
            v-if="!isUserMediaStart && !error"
            class="w-full object-cover h-40 rounded-2xl"
            src="/images/files/MYID/example.png"
            alt="Example photo"
          />
          <video
            v-show="isUserMediaStart && !mediaDevice.hasError"
            ref="player"
            autoplay
            playsinline
            muted
            class="h-80 md:h-40 mx-auto rounded-md border-1 border-gray-500 transform scale-x-n1"
          ></video>
        </div>
        <div class="space-y-5">
          <div class="flex space-x-2">
            <UserIcon class="inline-block text-primary-600 w-6 h-6" style="min-width: 24px" />
            <span>
              {{ $t('form.myid.actions[0]') }}
            </span>
          </div>
          <div class="flex space-x-2">
            <EyeIcon class="inline-block text-primary-600 w-6 h-6" style="min-width: 24px" />
            <span>
              {{ $t('form.myid.actions[1]') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <input
    type="file"
    ref="myIdFile"
    id="myIdFile"
    capture="user"
    accept="image/*,.heic"
    class="sr-only"
    @change="onChangeFile"
    data-testid="my_id_file_input"
  />
</template>

<script>
  export default {
    name: 'BaseFaceShot',
  };
</script>

<script setup>
  import { reactive, ref, onBeforeUnmount, watch } from 'vue';
  import { EyeIcon, UserIcon } from '@heroicons/vue/24/outline';

  import { hasMobileDevice } from '~/utils/hasMobileDevice';
  import AtAlert from '~/plugins/aliftech-ui/components/AtAlert/AtAlert';

  defineProps({
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['submit', 'error']);

  const player = ref(null);
  const isUserMediaStart = ref(false);
  const previewFaceImageUrl = ref(null);
  const myIdFile = ref(null);
  const isCameraStarting = ref(false);

  const mediaDevice = reactive({
    isMobile: hasMobileDevice(),
    isSupport: 'mediaDevices' in navigator,
    hasError: false,
    error: null,
  });
  const initUserMedia = target => {
    isCameraStarting.value = true;
    const userMediaConstraints = {
      video: {
        facingMode: 'user',
        width: {
          ideal: mediaDevice.isMobile ? 852 : 1280,
        },
        height: {
          ideal: mediaDevice.isMobile ? 480 : 720,
        },
      },
      audio: false,
    };
    return navigator.mediaDevices
      .getUserMedia(userMediaConstraints)
      .then(stream => {
        target.srcObject = stream;
      })
      .catch(e => {
        mediaDevice.hasError = true;
        mediaDevice.error = e;
      });
  };
  const shot = (video, scaleFactor = 1) => {
    var w = video.videoWidth * scaleFactor;
    var h = video.videoHeight * scaleFactor;
    var canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  };
  const submitBlob = blob => {
    const fileFaceObject = new File([blob], 'face', { type: blob.type });
    previewFaceImageUrl.value = URL.createObjectURL(fileFaceObject);
    emit('submit', fileFaceObject);
  };
  const onChangeFile = e => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const imgElement = new Image();
    imgElement.src = URL.createObjectURL(e.target.files[0]);

    imgElement.onload = () => {
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;
      context.drawImage(imgElement, 0, 0);
      canvas.toBlob(submitBlob, 'image/jpeg', 0.5);
      myIdFile.value.value = null;
    };
  };
  const stopUserMedia = () => {
    (player.value?.srcObject?.getVideoTracks() ?? []).forEach(track => track.stop());
    isUserMediaStart.value = false;
  };
  const reset = () => {
    myIdFile.value.value = null;
    stopUserMedia();
  };
  const start = () => {
    previewFaceImageUrl.value = null;
    if (mediaDevice.isSupport) {
      initUserMedia(player.value)?.then(() => {
        isCameraStarting.value = false;
        isUserMediaStart.value = true;
      });
    } else if (myIdFile.value) {
      myIdFile.value.click();
    }
  };
  const capture = () => {
    const canvas = shot(player.value);
    canvas.toBlob(
      blob => {
        submitBlob(blob);
        reset();
      },
      'image/jpeg',
      0.5
    );
  };
  watch(
    () => mediaDevice.hasError,
    () => {
      emit('error');
    }
  );

  defineExpose({ isUserMediaStart, mediaDevice, isCameraStarting, previewFaceImageUrl, start, capture });

  onBeforeUnmount(reset);
</script>

<style scoped></style>
