import { updateConfig, checkConfig, loadScript, createMetrika, startTracking } from './helpers';
import config from './config';

export default function install(Vue, options = {}) {
  updateConfig(options); // Merge options and default config

  checkConfig(config); // Check if all required options are presented

  loadScript(() => {
    // Load Metrika script
    const metrika = createMetrika(Vue, config); // Create Metrika
    startTracking(metrika, config); // Start autotracking
  }, options.scriptSrc);
}
