export const CARDS_TYPES = {
  uzcard: 'uzcard',
  humo: 'humo',
  mastercard: 'mastercard',
  unionpay: 'unionpay',
  other: 'other',
};
export const CARDS_BACKGROUNDS = {
  [CARDS_TYPES.uzcard]: 'linear-gradient(102.75deg, #336FC9 0%, #1F3E79 100%)',
  [CARDS_TYPES.humo]: 'linear-gradient(105.99deg, #A78E31 0%, #D8BB5E 50.31%, #C0A64A 100%)',
  [CARDS_TYPES.mastercard]: '#111827',
  [CARDS_TYPES.unionpay]: '#111827',
  [CARDS_TYPES.other]: 'rgba(58, 186, 129, 1)',
};

const uzCard = 8600;
const humoCard = 9860;
const generateTemplate = (type, bank) => ({ type, bank });
export const CARDS_BANK_NAMES = {
  [humoCard]: {
    '01': generateTemplate('humo', 'Ipoteka bank'),
    '02': generateTemplate('humo', 'UzSanoatQurilish Bank'),
    '03': generateTemplate('humo', 'Agrobank'),
    '04': generateTemplate('humo', 'Asaka bank'),
    '06': generateTemplate('humo', 'Qishloq Qurilish Bank'),
    '08': generateTemplate('humo', 'Xalq bank'),
    '09': generateTemplate('humo', 'ASIA ALLIANCE BANK'),
    10: generateTemplate('humo', 'Kapital bank'),
    12: generateTemplate('humo', 'NBU'),
    13: generateTemplate('humo', 'Mikrokredit bank'),
    14: generateTemplate('humo', 'Savdogar bank'),
    15: generateTemplate('humo', 'Turon bank'),
    16: generateTemplate('humo', 'Hamkor bank'),
    17: generateTemplate('humo', "Ipak yo'li bank"),
    18: generateTemplate('humo', 'Trast bank'),
    19: generateTemplate('humo', 'Aloqabank'),
    20: generateTemplate('humo', 'KDB Bank Uzbekistan'),
    21: generateTemplate('humo', 'Turkiston bank'),
    23: generateTemplate('humo', 'Universal bank'),
    24: generateTemplate('humo', 'Ravnaq bank'),
    25: generateTemplate('humo', 'DAVR BANK'),
    26: generateTemplate('humo', 'InFin Bank'),
    27: generateTemplate('humo', 'Orient Finans Bank'),
    28: generateTemplate('humo', 'Hi-Tech Bank'),
    29: generateTemplate('humo', 'Ziraat Bank Uzbekistan'),
    30: generateTemplate('humo', 'Saderat Iran Tashkent Bank'),
    31: generateTemplate('humo', 'UzAgroExportBank'),
    32: generateTemplate('humo', 'Madad Invest Bank'),
    33: generateTemplate('humo', 'Poytaxt bank'),
    35: generateTemplate('humo', 'TBC bank'),
    60: generateTemplate('humo', 'Anor bank'),
  },
  [uzCard]: {
    33: generateTemplate('uzcard', 'Ipoteka bank'),
    '03': generateTemplate('uzcard', 'UzSanoatQurilish Bank'),
    '04': generateTemplate('uzcard', 'Agrobank'),
    13: generateTemplate('uzcard', 'Asaka bank'),
    '09': generateTemplate('uzcard', 'Qishloq Qurilish Bank'),
    '06': generateTemplate('uzcard', 'Xalq bank'),
    55: generateTemplate('uzcard', 'ASIA ALLIANCE BANK'),
    49: generateTemplate('uzcard', 'Kapital bank'),
    '02': generateTemplate('uzcard', 'NBU'),
    '05': generateTemplate('uzcard', 'Mikrokredit bank'),
    '08': generateTemplate('uzcard', 'Savdogar bank'),
    11: generateTemplate('uzcard', 'Turon bank'),
    12: generateTemplate('uzcard', 'Hamkor bank'),
    14: generateTemplate('uzcard', "Ipak yo'li bank"),
    30: generateTemplate('uzcard', 'Trast bank'),
    31: generateTemplate('uzcard', 'Aloqabank'),
    34: generateTemplate('uzcard', 'KDB Bank Uzbekistan'),
    38: generateTemplate('uzcard', 'Turkiston bank'),
    48: generateTemplate('uzcard', 'Universal bank'),
    50: generateTemplate('uzcard', 'Ravnaq bank'),
    51: generateTemplate('uzcard', 'DAVR BANK'),
    53: generateTemplate('uzcard', 'InFin Bank'),
    57: generateTemplate('uzcard', 'Orient Finans Bank'),
    56: generateTemplate('uzcard', 'Hi-Tech Bank'),
    20: generateTemplate('uzcard', 'Ziraat Bank Uzbekistan'),
    43: generateTemplate('uzcard', 'Saderat Iran Tashkent Bank'),
    59: generateTemplate('uzcard', 'UzAgroExportBank'),
    58: generateTemplate('uzcard', 'Madad Invest Bank'),
    60: generateTemplate('uzcard', 'Poytaxt bank'),
  },
};
