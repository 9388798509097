<template>
  <AtContainer short style="max-width: 440px">
    <div class="space-y-4">
      <router-link
        :to="{ name: 'Installments.Show', params: { id: installmentId } }"
        class="text-primary-500 flex items-center space-x-1"
      >
        <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
        <span> {{ $t('system.btns.back') }} </span>
      </router-link>
      <AtTitle> {{ $t('app.reject.title') }} </AtTitle>

      <InstallmentsRefundHintPanel />

      <div class="flex justify-between">
        <span class="text-sm leading-5 font-medium text-gray-700"> {{ $t('app.reject.form.title[0]') }} </span>
        <span class="text-sm leading-5 font-medium text-gray-400"
          >{{ $t('app.reject.form.selected') }} {{ modal.count }}</span
        >
      </div>
      <AtLoading v-if="service.reject.items.loading" />

      <div v-else class="space-y-4">
        <!-- <form @submit.prevent="handleRequest" class="space-y-8"> -->
        <div class="space-y-8">
          <div>
            <div class="space-y-2 mb-2" v-for="(item, index) in service.reject.items.response" :key="item.id">
              <AtCheckbox
                class="rounded-2xl p-5"
                :class="{
                  'bg-gray-100': isConsistId(rejectCaseFormId, item.id),
                }"
                @update:model-value="changeItem(item)"
              >
                <p class="text-sm leading-5 font-medium text-gray-900 mb-1">
                  {{ item.name }}
                </p>
                <p class="text-sm leading-5 font-normal text-gray-900">
                  {{ item._price }}
                </p>
              </AtCheckbox>

              <AtFileSelect
                v-if="isConsistId(rejectCaseFormId, item.id)"
                multiple
                :title="$t('app.reject.form.selectFile')"
                :title-more="$t('app.reject.form.selectMore')"
                :showSignature="true"
                :error="service.reject.create.errors?.[`items.${index}.files`]"
                @onSelect="changeFile($event, item)"
              />
            </div>
            <BaseFieldErrors v-if="service.reject.create.errors?.items" :errors="service.reject.create.errors.items" />
          </div>
          <div>
            <p class="mb-3 text-sm leading-5 font-medium text-gray-900">{{ $t('app.reject.form.reasonLabel') }}</p>
            <AtRadioGroup
              noBorder
              :items="rejectReasonOptions"
              v-model="rejectCaseForm.reason"
              :error="service.reject.create.errors?.reason"
            >
              <template #item="{ item }">
                <div class="w-full">
                  <span class="block w-full text-base leading-6 font-normal text-gray-900">{{ item.title }}</span>
                  <!-- <AtInput
                    placeholder="Введите причину"
                    v-if="rejectCaseForm.reason === 'OTHER' && item.value === 'OTHER'"
                    v-model="rejectCaseForm.note"
                    class="block"
                  /> -->
                </div>
              </template>
            </AtRadioGroup>
            <BaseFieldErrors
              v-if="service.reject.create.errors?.reason"
              :errors="service.reject.create.errors.reason"
            />
            <AtInput
              :placeholder="$t('app.reject.form.typeReason')"
              v-if="rejectCaseForm.reason === 'OTHER'"
              v-model="rejectCaseForm.note"
              class="block pl-6 -mt-2"
              :error="service.reject.create.errors?.note"
            />
          </div>

          <BaseAlert class="my-2" :alert="service.reject.create.alert" :errors="service.reject.create.errors" />

          <AtButton color="primary" @click="modal.isOpen = true" block>{{ $t('app.reject.form.btn') }}</AtButton>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </AtContainer>
  <AtModal size="xs" :title="$t('app.reject.modal.title')" v-model="modal.isOpen">
    <dl class="space-y-4 py-2">
      <div>
        <dt class="text-base leading-6 font-normal text-gray-500">{{ $t('app.reject.modal.itemsCount') }}</dt>
        <dd class="text-base leading-6 font-medium text-gray-900">{{ modal.count }}</dd>
      </div>
      <div>
        <dt class="text-base leading-6 font-normal text-gray-500">{{ $t('app.reject.modal.totalAmount') }}</dt>
        <dd class="text-base leading-6 font-medium text-gray-900">
          {{ formatMoney(Number(((modal.amount ?? 0) / 100).toFixed(2)), 0) }} {{ $t('system.titles.sum') }}
        </dd>
      </div>
    </dl>
    <AtButton color="primary" block class="mt-5" @click="handleRequest" :loading="service.reject.create.loading">
      {{ $t('app.reject.modal.btn') }}
    </AtButton>
    <AtButton block class="mt-5" @click="modal.isOpen = false"> {{ $t('system.btns.cancel') }} </AtButton>
  </AtModal>
</template>

<script setup>
  import { computed, reactive } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useService } from '~/hooks/useService';
  import { getCreditItems, createRejectCase } from '~/services/credits.api';

  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtCheckbox from '~/plugins/aliftech-ui/components/AtCheckbox/AtCheckbox';
  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';
  // eslint-disable-next-line no-unused-vars
  import AtRadioGroup from '~/plugins/aliftech-ui/components/AtRadioGroup/AtRadioGroup';
  import AtFileSelect from '~/plugins/aliftech-ui/components/AtFileSelect/AtFileSelect';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';

  import { checkType } from '~/plugins/aliftech-ui/utils';
  import { formatMoney } from '~/utils';
  import { $_at_app } from '~/shared/at-app';
  import { useI18n } from 'vue-i18n';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import InstallmentsRefundHintPanel from '~/components/installments/InstallmentsRefundHintPanel.vue';

  const route = useRoute();
  const router = useRouter();
  const { locale } = useI18n();
  const installmentId = computed(() => route?.params?.id);

  const rejectCaseForm = reactive({
    items: [],
    credit_id: installmentId.value,
    reason: '',
    note: '',
  });
  const rejectCaseFormId = computed(() => rejectCaseForm.items.map(item => item.id));

  const service = {
    reject: {
      items: reactive(useService(getCreditItems)),
      create: reactive(useService(createRejectCase)),
    },
  };

  const modal = reactive({
    isOpen: false,
    count: 0,
    amount: 0,
  });
  service.reject.items.execute(installmentId.value);

  const rejectReasonOptions = computed(
    () =>
      Object.values($_at_app.value?.reject_case_reasons ?? []).map(reason => ({
        value: reason.id,
        title: reason[`body_${locale.value}`],
      })) ?? []
  );

  const getItemIndex = (items, item) => items.findIndex(_item => _item.id === item.id);

  // const changeFile = (event, item) => {
  // const itemIndex = getItemIndex(rejectCaseForm.items, item);
  // const hasIndex = itemIndex !== -1 ? true : false;
  // if (hasIndex) rejectCaseForm.items[itemIndex].files.push(event.target.files[0]);
  // };

  const changeItem = item => {
    const itemIndex = getItemIndex(rejectCaseForm.items, item);
    const hasIndex = itemIndex !== -1 ? true : false;
    if (hasIndex) {
      rejectCaseForm.items.splice(itemIndex, 1);
      modal.count--;
      modal.amount -= parseInt(item.price, 10);
    } else {
      rejectCaseForm.items.push({ id: item.id, files: [] });
      modal.count++;
      modal.amount += parseInt(item.price, 10);
    }
  };

  const handleRequest = () => {
    if (rejectCaseForm.reason !== 'OTHER') rejectCaseForm.note = '';
    const formData = new FormData();

    function createFormData(formData, key, data) {
      if (checkType(data, 'Object') || Array.isArray(data)) {
        for (var i in data) {
          createFormData(formData, key + '[' + i + ']', data[i]);
        }
      } else {
        formData.append(key, data);
      }
    }

    Object.keys(rejectCaseForm).forEach(k => createFormData(formData, k, rejectCaseForm[k]));

    service.reject.create
      .execute(formData)
      .then(() => {
        router.replace({ name: 'Installments.Show.CreateRejectSuccess' });
      })
      .finally(() => {
        modal.isOpen = false;
      });
  };

  const changeFile = (event, item) => {
    const itemIndex = getItemIndex(rejectCaseForm.items, item);
    const hasIndex = itemIndex !== -1 ? true : false;
    const files = event.getAll('files[]');
    if (files.length) {
      if (hasIndex) rejectCaseForm.items[itemIndex].files = [...files];
    } else {
      rejectCaseForm.items[itemIndex].files = [];
    }
  };

  const isConsistId = (array, id) => array.includes(id);
</script>

<style lang="scss" scoped></style>
