import { heroiconsV1toV2 } from '../helpers/heroicons';
import * as HeroIconsOutline24 from '@heroicons/vue/24/outline';
import * as HeroIconsSolid24 from '@heroicons/vue/24/solid';
import * as HeroIconsSolid20 from '@heroicons/vue/20/solid';

/**
 * Icon names
 * @type {Object}
 */
const iconNames = new Proxy(heroiconsV1toV2, {
  get: (obj, property) => {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      return obj[property];
    }
    return property;
  },
});

/**
 * Icon types
 * @type {{outline: string, solid: string, mini: string}}
 */
const iconTypes = {
  outline: 'outline',
  solid: 'solid',
  mini: 'solid',
};

/**
 * Icon sizes
 * @type {{outline: string, solid: string, mini: string}}
 */
const iconSizes = {
  outline: '24',
  solid: '24',
  mini: '20',
};

/**
 * Icon components
 */
const iconComponents = {
  '24': {
    outline: HeroIconsOutline24,
    solid: HeroIconsSolid24,
  },
  '20': {
    // Assuming only 'solid' type is available for size '20'.
    solid: HeroIconsSolid20,
  },
};

/**
 * Returns icon name from HeroIcons
 * @param {string} name
 * @returns {string}
 */
const getIconName = name => {
  const normalizedName = `${name
    .split('-')
    .map(part => part.charAt(0).toUpperCase() + part.slice(1))
    .join('')}Icon`;
  return iconNames[normalizedName];
};

/**
 * Returns async Icon component from HeroIcons
 * @param {string|Object} icon
 * @returns {{Icon}}
 */
export const getIconComponent = icon => {
  const iconName = getIconName(icon?.name || icon || '');
  const iconType = iconTypes[icon?.type || 'solid'];
  const iconSize = iconSizes[icon?.type] || '24';

  const Icon = iconComponents?.[iconSize]?.[iconType]?.[iconName];

  return { Icon: Icon };
};
