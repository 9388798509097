import AppLayout from '~/layouts/AppLayout.vue';
import AppIndexPage from '~/views/app/AppIndexPage.vue';
import AppApplicationsPage from '~/views/app/AppApplicationsPage.vue';
import InstallmentsIndexPage from '~/views/app/installments/InstallmentsIndexPage.vue';
import ProfileIndexPage from '~/views/app/profile/ProfileIndexPage.vue';
import ProfileChangePasswordPage from '~/views/app/profile/ProfileChangePasswordPage.vue';
import ProfileChangePhonePage from '~/views/app/profile/ProfileChangePhonePage.vue';
import CardsPage from '~/views/app/AppCardsPage.vue';
import CardsAddPage from '~/views/app/AppCardsAddPage.vue';
import InstallmentsShowPage from '~/views/app/installments/InstallmentsShowPage.vue';
import InstallmentsCardsPage from '~/views/app/installments/InstallmentsCardsPage.vue';
import InstallmentsCreateDelayPage from '~/views/app/installments/InstallmentsCreateDelayPage.vue';
import InstallmentsCreateRejectPage from '~/views/app/installments/InstallmentsCreateRejectPage.vue';
import InstallmentsCreateRejectSuccessPage from '~/views/app/installments/InstallmentsCreateRejectSuccessPage.vue';
import InstallmentsCustomAmountPage from '~/views/app/installments/InstallmentsCustomAmountPage.vue';
import InstallmentsCreateProblemCasePage from '~/views/app/installments/InstallmentsCreateProblemCasePage.vue';

export default [
  {
    path: 'app',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'App.Index',
        component: AppIndexPage,
      },
      {
        path: 'applications',
        name: 'App.Applications',
        component: AppApplicationsPage,
      },
      {
        path: 'installments',
        name: 'Installments.Index',
        component: InstallmentsIndexPage,
      },
      {
        path: 'installments/:id',
        name: 'Installments.Show',
        component: InstallmentsShowPage,
      },
      {
        path: 'installments/:id/cards',
        name: 'Installments.Show.Cards',
        component: InstallmentsCardsPage,
      },
      {
        path: 'installments/:id/create-delay',
        name: 'Installments.Show.CreateDelay',
        component: InstallmentsCreateDelayPage,
      },
      {
        path: 'installments/:id/create-reject',
        name: 'Installments.Show.CreateReject',
        component: InstallmentsCreateRejectPage,
      },
      {
        path: 'installments/:id/create-problem-case',
        name: 'Installments.Show.CreateProblemCase',
        component: InstallmentsCreateProblemCasePage,
      },
      {
        path: 'installments/:id/create-reject/success',
        name: 'Installments.Show.CreateRejectSuccess',
        component: InstallmentsCreateRejectSuccessPage,
      },
      {
        path: 'installments/:id/custom-amount',
        name: 'Installments.Show.Custom',
        component: InstallmentsCustomAmountPage,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: ProfileIndexPage,
      },
      {
        path: 'profile/change-password',
        name: 'Profile.ChangePassword',
        component: ProfileChangePasswordPage,
      },
      {
        path: 'profile/change-phone',
        name: 'Profile.ChangePhone',
        component: ProfileChangePhonePage,
      },
      {
        path: 'cards',
        name: 'App.Cards',
        component: CardsPage,
      },
      {
        path: 'cards/add',
        name: 'App.Cards.Add',
        component: CardsAddPage,
      },
      {
        path: 'partner',
        name: 'Forms.Partner',
        component: () => import('~/views/success/PartnerPage.vue'),
        beforeEnter: () => {
          if (sessionStorage.getItem('source')) return true;
          else return { name: 'App.Index' };
        },
      },
    ],
  },
];
