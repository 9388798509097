import { h, defineComponent, ref, reactive, watch, onBeforeMount } from 'vue';
import { intervalToDuration } from 'date-fns';

export default defineComponent({
  name: 'BaseCountdown',
  emits: ['end'],
  props: {
    time: { type: [String, Number], default: () => new Date().getTime() },
  },
  setup(props, { emit }) {
    const count = reactive({
      years: 0,
      months: 0,
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    const timer = ref(-1);

    const stop = () => {
      if (timer.value !== -1) {
        clearInterval(timer.value);
        timer.value = -1;
        emit('end');
      }
    };
    const start = () => {
      clearInterval(timer.value);
      timer.value = setInterval(() => {
        const result = updateTime();
        if (result <= 1) {
          stop();
        }
      }, 1000);
    };
    const updateTime = () => {
      count.value = intervalToDuration({ start: new Date(), end: new Date(props.time) });
      return (props.time - new Date().getTime()) / 1000;
    };

    watch(
      () => props.time,
      value => {
        if (value && new Date().getTime() < value) {
          start();
        }
      }
    );
    onBeforeMount(() => {
      updateTime();
      if (props.time && new Date().getTime() < props.time) {
        start();
      }
    });

    return {
      count,
    };
  },
  render() {
    return h(
      'div',
      { class: 'inline-block' },
      this.$slots?.default?.({
        ...this.count.value,
        // TODO:
        // locale: this?.$t?.bind?.(this) ?? new Function("", ""),
      })
    );
  },
});
