export const InputEventsTypes = [
  'focus',
  'focusin',
  'focusout',
  'blur',
  'input',
  'change',
  'select',
  'search',
  'cut',
  'paste',
  'copy',
];
