import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '32',
        height: '32',
        viewBox: '0 0 32 32',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M6.39238 9.40773C6.39238 7.7424 7.74305 6.39173 9.40705 6.39173H10.779C11.5737 6.39173 12.3377 6.07707 12.903 5.51573L13.8617 4.55573C15.0364 3.37573 16.9457 3.3704 18.1257 4.54507L18.1377 4.55573L19.0977 5.51573C19.6617 6.07707 20.4257 6.39173 21.2217 6.39173H22.5924C24.2577 6.39173 25.6084 7.7424 25.6084 9.40773V10.7771C25.6084 11.5744 25.923 12.3371 26.4844 12.9024L27.4444 13.8624C28.6244 15.0371 28.6311 16.9451 27.4564 18.1264L27.4444 18.1384L26.4844 19.0984C25.923 19.6611 25.6084 20.4264 25.6084 21.2211V22.5931C25.6084 24.2584 24.2577 25.6077 22.5924 25.6077H21.2217C20.4257 25.6077 19.6617 25.9237 19.0977 26.4851L18.1377 27.4437C16.9644 28.6251 15.055 28.6304 13.8737 27.4557C13.8697 27.4517 13.8657 27.4477 13.8617 27.4437L12.903 26.4851C12.3377 25.9237 11.5737 25.6077 10.779 25.6077H9.40705C7.74305 25.6077 6.39238 24.2584 6.39238 22.5931V21.2211C6.39238 20.4264 6.07638 19.6611 5.51505 19.0984L4.55638 18.1384C3.37505 16.9637 3.36972 15.0544 4.54438 13.8744L4.55638 13.8624L5.51505 12.9024C6.07638 12.3371 6.39238 11.5744 6.39238 10.7771V9.40773',
          stroke: '#1F2937',
          'stroke-width': '2.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          d: 'M12.5734 19.426L19.4267 12.5727L12.5734 19.426Z',
          fill: '#10B981',
        }),
        _createElementVNode('path', {
          d: 'M12.5689 14.0801C12.3681 14.0801 12.1874 14.038 12.0066 13.9576C11.8259 13.8773 11.6451 13.7789 11.5046 13.6383C11.364 13.4777 11.2636 13.317 11.1832 13.1363C11.1029 12.9535 11.0627 12.7748 11.0627 12.5739C11.0627 12.3711 11.1029 12.1723 11.1832 11.9916C11.2636 11.8108 11.364 11.6301 11.5046 11.5096C12.0869 10.9453 13.071 10.9453 13.6333 11.5096C13.9145 11.7887 14.0751 12.1723 14.0751 12.5739C14.0751 12.7748 14.055 12.9535 13.9747 13.1363C13.8944 13.317 13.7739 13.4777 13.6333 13.6383C13.4927 13.7789 13.3321 13.8773 13.1513 13.9576C12.9706 14.038 12.7698 14.0801 12.5689 14.0801Z',
          fill: '#EC4899',
        }),
        _createElementVNode('path', {
          d: 'M19.4266 20.9334C19.2258 20.9334 19.045 20.8912 18.8643 20.8109C18.6835 20.7306 18.5028 20.6322 18.3622 20.4916C18.2216 20.3309 18.1212 20.1703 18.0409 19.9895C17.9606 19.8068 17.9204 19.628 17.9204 19.4272C17.9204 19.2244 17.9606 19.0256 18.0409 18.8448C18.1212 18.6641 18.2216 18.4833 18.3622 18.3628C18.9446 17.7985 19.9287 17.7985 20.491 18.3628C20.7721 18.642 20.9328 19.0256 20.9328 19.4272C20.9328 19.628 20.9127 19.8068 20.8324 19.9895C20.752 20.1703 20.6315 20.3309 20.491 20.4916C20.3504 20.6322 20.1897 20.7306 20.009 20.8109C19.8282 20.8912 19.6274 20.9334 19.4266 20.9334Z',
          fill: '#EC4899',
        }),
        _createElementVNode('path', {
          'fill-rule': 'evenodd',
          'clip-rule': 'evenodd',
          fill: '#10B981',
          d: 'M12.5734 19.426L19.4267 12.5727',
          'stroke': '#EC4899',
          'stroke-width': '1.5',
          'stroke-linecap': 'round',
          'stroke-linejoin': 'round',
        }),
      ]
    )
  );
}
