import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'currentColor',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M19.2936 3.717L1.56357 10.554C0.353574 11.04 0.360574 11.715 1.34157 12.016L5.89357 13.436L16.4256 6.791C16.9236 6.488 17.3786 6.651 17.0046 6.983L8.47157 14.684H8.46957L8.47157 14.685L8.15757 19.377C8.61757 19.377 8.82057 19.166 9.07857 18.917L11.2896 16.767L15.8886 20.164C16.7366 20.631 17.3456 20.391 17.5566 19.379L20.5756 5.151C20.8846 3.912 20.1026 3.351 19.2936 3.717Z',
        }),
      ]
    )
  );
}
