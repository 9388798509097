<template>
  <div class="px-4 pb-12 min-h-screen">
    <div class="w-full m-auto" style="max-width: 440px">
      <div class="space-y-6">
        <div class="space-y-4">
          <a href="#" class="text-primary-500 flex items-center space-x-1" @click.prevent="goBack">
            <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
            <span> {{ $t('system.btns.back') }} </span>
          </a>
          <template v-if="isOtpStep">
            <div class="space-y-2">
              <AtTitle> {{ $t('auth.titles.smsConfirm') }} </AtTitle>
              <div class="text-sm text-gray-500">
                {{ $t('auth.titles.sendCode', { phone: maskedPhone }) }}
              </div>
            </div>
          </template>
          <template v-else>
            <AtTitle> {{ $t('system.titles.card.title') }} </AtTitle>
          </template>
        </div>
        <template v-if="isOtpStep">
          <form class="space-y-6" @submit.prevent="confirmCode">
            <div>
              <AtInput
                v-model="otpFields.code"
                :disabled="service.confirmCode.loading || service.getCode.loading"
                :label="$t('system.fields.smsCode')"
                type="tel"
                mask="######"
                :error="$vOtp.code.$error || service?.confirmCode?.errors?.code"
              />
              <BaseFieldErrors
                :errors="$vOtp.code.$errors.length ? $vOtp.code.$errors : service?.confirmCode?.errors?.code"
              />
            </div>
            <div class="flex flex-col space-y-2 text-left">
              <BaseCountdown :time="data.finish">
                <template #default="{ minutes, seconds }">
                  <div class="flex flex-col flex-wrap justify-start">
                    <p class="text-gray-500 text-sm">
                      {{ $t('system.fields.smsCodeExpire') }}: {{ minutes.toString().padStart(2, '0') }}:{{
                        seconds.toString().padStart(2, '0')
                      }}
                    </p>
                  </div>
                </template>
              </BaseCountdown>
              <button
                class="text-primary-500 text-sm text-left"
                :disabled="service.getCode.loading"
                type="button"
                @click="repeatCode"
              >
                {{ $t('system.btns.repeatSmsSend') }}
              </button>
            </div>
            <BaseAlert :alert="service?.confirmCode?.alert" :errors="service?.confirmCode?.errors" />
            <AtButton
              :loading="service.confirmCode.loading"
              :disabled="service.getCode.loading"
              color="primary"
              size="lg"
              class="w-full"
              type="submit"
            >
              {{ $t('app.btns.profile.cards.add') }}
            </AtButton>
          </form>
        </template>
        <template v-else>
          <form class="space-y-6" @submit.prevent="getCode(false)">
            <div class="space-y-4">
              <div class="flex flex-col space-y-4">
                <div class="w-full">
                  <AtInput
                    v-model="cardFields.card_number"
                    :disabled="service.getCode.loading"
                    :label="$t('system.fields.card.label')"
                    type="tel"
                    placeholder="0000 0000 0000 0000"
                    mask="#### #### #### ####"
                    :error="$v.card_number?.$error || service?.getCode?.errors?.card_number"
                  />
                  <BaseFieldErrors
                    :errors="
                      $v.card_number?.$errors.length ? $v.card_number?.$errors : service?.getCode?.errors?.card_number
                    "
                  />
                </div>
                <div class="w-full">
                  <AtInput
                    v-model="cardFields.expire"
                    style="max-width: 150px"
                    :disabled="service.getCode.loading"
                    :label="$t('system.fields.card.expires')"
                    type="tel"
                    :placeholder="$t('system.fields.card.expirePlaceholder')"
                    mask="##/##"
                    :error="$v.expire?.$error || service?.getCode?.errors?.expire"
                  />
                  <BaseFieldErrors
                    :errors="$v.expire?.$errors.length ? $v.expire?.$errors : service?.getCode?.errors?.expire"
                  />
                </div>
              </div>
            </div>
            <BaseAlert :alert="service?.getCode?.alert" :errors="service?.getCode?.errors" />
            <AtButton :loading="service.getCode.loading" color="primary" size="lg" class="w-full" type="submit">
              {{ $t('system.btns.next') }}
            </AtButton>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    beforeRouteEnter: (_, from, next) => {
      next(vm => {
        vm.$nextTick(function () {
          const { name, params, query } = from;
          this.prevRoute = {
            name,
            params,
            query,
          };
        });
      });
    },
  };
</script>

<script setup>
  import { reactive, onBeforeMount, computed, ref, inject } from 'vue';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import useVuelidate from '@vuelidate/core';
  import { required, minLength, helpers } from '@vuelidate/validators';
  import VueCookies from 'vue-cookies';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import BaseCountdown from '~/components/base/BaseCountdown';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import AtInput from '~/plugins/aliftech-ui/components/AtInput/AtInput';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import { useService } from '~/hooks/useService';
  import { addCardGetCode, addCard } from '~/services/profile.api';
  import { applyDataFromCookie } from '~/utils/cookie';
  import { $_at_user } from '~/shared/at-user';

  const $toast = inject('$toast');
  const { t } = useI18n();

  const defaultFields = {
    card_number: null,
    expire: null,
  };
  const cardFieldRules = {
    card_number: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(t('validations.cardLength'), minLength(16)),
    },
    expire: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(t('validations.cardExpireLength'), minLength(4)),
      validDate: helpers.withMessage(t('validations.cardExpireWrongDate'), value => {
        if (value.length === 4) {
          const month = ('0' + Number(value.slice(0, 2))).slice(-2);
          const year = Number(value.slice(2)) + 2000;

          return month > 0 && month <= 12 && new Date().getTime() < new Date(`${year}-${month}-01`).getTime();
        }
        return true;
      }),
    },
  };
  const otpFieldRules = {
    code: {
      required: helpers.withMessage(t('validations.required'), required),
      minLength: helpers.withMessage(t('validations.smsCodeMinLength', { number: 6 }), minLength(6)),
    },
  };

  const service = {
    getCode: reactive(useService(addCardGetCode)),
    confirmCode: reactive(useService(addCard)),
  };
  const router = useRouter();

  const cardFields = reactive({ ...defaultFields });
  const otpFields = reactive({ code: null });
  const data = reactive({
    card_number: null,
    expire: null,
    finish: null,
    phone: null,
  });
  const prevRoute = ref({});

  const isOtpStep = computed(() => new Date().getTime() < data.finish || data.phone);
  const maskedPhone = computed(() => {
    let phone = data.user_phone || data.phone;
    if (phone.length === 12) {
      phone = phone.slice(3);
    }
    if (phone) {
      return '+998' + ' (' + phone.slice(0, 2) + ') ' + phone.slice(2, 3) + '**-**-' + phone.slice(-2);
    }
    return '';
  });

  const $v = useVuelidate(cardFieldRules, cardFields);
  const $vOtp = useVuelidate(otpFieldRules, otpFields);

  const repeatCode = () => {
    return getCode(true).then(({ data }) =>
      $toast.success({
        title: t('form.cards.repeatCodeSuccess', { phone: data?.phone }),
      })
    );
  };
  const getCode = async (force = false) => {
    if (!(await $v.value.$validate()) && !force) return;

    return service.getCode.execute({ ...cardFields, client_id: $_at_user.value.clientId }).then(res => {
      data.finish = res.data.finish;
      data.phone = res?.data?.phone ?? res?.data?.payload?.phone ?? '';
      data.card_number = cardFields.card_number;
      data.name = cardFields.name;

      return res;
    });
  };
  const confirmCode = async () => {
    if (!(await $vOtp.value.$validate())) return;

    service.confirmCode.execute({ otp: otpFields.code, client_id: $_at_user.value.clientId }).then(() => {
      $toast.success({
        title: t('app.titles.profile.cards.attached'),
      });
      router.push({
        name: 'App.Cards',
      });
    });
  };
  const goBack = () => {
    if (isOtpStep.value) {
      VueCookies.remove('online-form-cards-data');
      data.finish = null;
      data.phone = null;
    } else {
      const { name, params, query } = prevRoute.value;
      if (['Installments.Show.Cards'].includes(name)) {
        router.go(-1);
      } else {
        router.push({
          name: 'App.Cards',
          params,
          query,
        });
      }
    }
  };

  onBeforeMount(() => {
    applyDataFromCookie(data, ['card_number', 'expire', 'phone', 'finish'], 'online-form-cards-data');

    if (data.finish) {
      const { card_number, expire } = data;
      cardFields.card_number = card_number;
      cardFields.expire = expire;
    }
  });
</script>
