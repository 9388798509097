import footer from './footer';
import auth from './auth';
import system from './system';
import form from './form';
import app from './app';
import juma from './juma';
import landing from './landing';
import store from './store';
import validations from './validations';
export default { footer, auth, system, form, juma, app, landing, store, validations };
