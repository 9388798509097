<template>
  <a
    :href="link"
    target="_blank"
    class="w-full text-left bg-gray-100 px-4 py-3 lg:px-6 lg:py-4 rounded-2xl flex items-center justify-between space-x-2 hover:bg-gray-200"
  >
    <span class="flex items-center space-x-4">
      <ClipboardDocumentCheckIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
      <span class="font-bold">{{ $t('app.titles.installment.detail.certificateOfClosure') }}</span>
    </span>
    <ArrowTopRightOnSquareIcon class="h-5 w-5 text-gray-500" style="min-width: 24px" />
  </a>
</template>

<script>
  export default {
    name: 'InstallmentsCertificateOfClosure',
  };
</script>

<script setup>
  import { ClipboardDocumentCheckIcon } from '@heroicons/vue/24/outline';
  import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';

  defineProps({
    link: {
      type: String,
      required: true,
    },
  });
</script>
