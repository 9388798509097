import { checkType, deepCopy } from '../plugins/aliftech-ui/utils';
import { CARDS_BANK_NAMES, CARDS_BACKGROUNDS } from '~/constants/cards';

export const getCardBackground = cardType => {
  return CARDS_BACKGROUNDS?.[cardType] ?? CARDS_BACKGROUNDS.other;
};

export const getCardsBankName = code => {
  const template = { type: undefined, bank: undefined };

  if (checkType(code, 'string')) {
    if (code.length > 5) {
      const [type, bank] = (() => {
        const result = [];
        for (let i = 0; i < code.length; i++) {
          if (i < 4) {
            if (result[0]) {
              result[0] += code[i];
            } else {
              result[0] = code[i];
            }
          } else if (i > 3 && i < 6) {
            if (result[1]) {
              result[1] += code[i];
            } else {
              result[1] = code[i];
            }
          }
        }
        return result;
      })();
      return CARDS_BANK_NAMES?.[type]?.[bank] ?? deepCopy(template);
    }
    return deepCopy(template);
  }
  return deepCopy(template);
};
