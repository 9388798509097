import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        xmlns: 'http://www.w3.org/2000/svg',
        'xmlns:xlink': 'http://www.w3.org/1999/xlink',
        preserveAspectRatio: 'xMidYMid meet',
        width: '256',
        height: '256',
        viewBox: '0 0 256 256',
        fill: 'currentColor',
      },
      [
        _createElementVNode('defs', null, [
          _createElementVNode('animateTransform', {
            repeatCount: 'indefinite',
            dur: '2s',
            begin: '0s',
            'xlink:href': '#_R_G_L_1_G',
            fill: 'freeze',
            attributeName: 'transform',
            from: '0',
            to: '720',
            type: 'rotate',
            additive: 'sum',
            keyTimes: '0;0.8349121;1',
            values: '0;720;720',
            keySplines: '0.497 0 0.332 1;0 0 0 0',
            calcMode: 'spline',
          }),
          _createElementVNode('animateTransform', {
            repeatCount: 'indefinite',
            dur: '2s',
            begin: '0s',
            'xlink:href': '#_R_G_L_1_G',
            fill: 'freeze',
            attributeName: 'transform',
            from: '-128.25 -128.11',
            to: '-128.25 -128.11',
            type: 'translate',
            additive: 'sum',
            keyTimes: '0;1',
            values: '-128.25 -128.11;-128.25 -128.11',
            keySplines: '0 0 1 1',
            calcMode: 'spline',
          }),
          _createElementVNode('animate', {
            attributeType: 'XML',
            attributeName: 'opacity',
            dur: '2s',
            from: '0',
            to: '1',
            'xlink:href': '#time_group',
          }),
        ]),
        _createElementVNode('g', { id: '_R_G' }, [
          _createElementVNode(
            'g',
            {
              id: '_R_G_L_1_G',
              transform: ' translate(128, 128)',
            },
            [
              _createElementVNode('path', {
                id: '_R_G_L_1_G_D_0_P_0',
                'fill-opacity': '1',
                'fill-rule': 'nonzero',
                d: ' M119.72 0.25 C53,4.64 0.25,60.15 0.25,127.97 C0.25,198.66 57.56,255.97 128.25,255.97 C159.88,255.97 188.84,244.5 211.17,225.48 C211.17,225.48 200.24,198.56 200.24,198.56 C181.95,217.22 156.45,228.8 128.25,228.8 C72.56,228.8 27.42,183.66 27.42,127.97 C27.42,72.28 72.56,27.14 128.25,27.14 C129.05,27.14 129.85,27.15 130.65,27.17 C130.65,27.17 119.72,0.25 119.72,0.25z ',
              }),
            ]
          ),
          _createElementVNode(
            'g',
            {
              id: '_R_G_L_0_G',
              transform: ' translate(128.111, 118.253) translate(-62.167, -55.66)',
            },
            [
              _createElementVNode('path', {
                id: '_R_G_L_0_G_D_0_P_0',
                'fill-opacity': '1',
                'fill-rule': 'nonzero',
                d: ' M79.05 0.25 C79.05,0.25 45.29,0.25 45.29,0.25 C45.29,0.25 0.25,111.07 0.25,111.07 C0.25,111.07 29.15,111.07 29.15,111.07 C29.15,111.07 62.17,25.48 62.17,25.48 C62.17,25.48 95.2,111.07 95.2,111.07 C95.2,111.07 124.08,111.07 124.08,111.07 C124.08,111.07 79.05,0.25 79.05,0.25z ',
              }),
            ]
          ),
        ]),
        _createElementVNode('g', { id: 'time_group' }),
      ]
    )
  );
}
