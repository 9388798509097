import { apiClient } from './apiClient';
import { checkType } from '~/plugins/aliftech-ui/utils/checkType';
import { arrayToObjectResponse } from '~/utils/arrayToObjectResponse';

const getApp = () =>
  apiClient.get('/app').then(response => {
    const propertiesArrayKeys = ['client_statuses', 'file_types', 'questionnaire_incomes', 'phone_contacts', 'regions'];
    const _response = { ...response };

    propertiesArrayKeys.forEach(key => {
      _response.data[`_${key}`] = checkType(_response.data[key], 'array')
        ? arrayToObjectResponse(_response.data[key])
        : _response.data[key];
    });

    return _response;
  });

export { getApp };
