import {
  createElementVNode as _createElementVNode,
  openBlock as _openBlock,
  createElementBlock as _createElementBlock,
} from 'vue';

export default function render() {
  return (
    _openBlock(),
    _createElementBlock(
      'svg',
      {
        width: '24',
        height: '24',
        viewBox: '0 0 24 24',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      [
        _createElementVNode('path', {
          d: 'M20.947 8.30496C20.9369 7.54758 20.7951 6.79773 20.528 6.08896C20.2963 5.49111 19.9425 4.94816 19.4892 4.49479C19.0358 4.04142 18.4928 3.68761 17.895 3.45596C17.1953 3.19331 16.4562 3.0513 15.709 3.03596C14.747 2.99296 14.442 2.98096 12 2.98096C9.55799 2.98096 9.24499 2.98096 8.28999 3.03596C7.54315 3.05141 6.80434 3.19343 6.10499 3.45596C5.50705 3.68745 4.96402 4.0412 4.51063 4.49459C4.05724 4.94798 3.70348 5.49101 3.47199 6.08896C3.20882 6.78809 3.06712 7.52707 3.05299 8.27396C3.00999 9.23696 2.99699 9.54196 2.99699 11.984C2.99699 14.426 2.99699 14.738 3.05299 15.694C3.06799 16.442 3.20899 17.18 3.47199 17.881C3.70387 18.4787 4.05789 19.0215 4.51143 19.4747C4.96497 19.9279 5.50806 20.2815 6.10599 20.513C6.80343 20.7862 7.54237 20.9383 8.29099 20.963C9.25399 21.006 9.55899 21.019 12.001 21.019C14.443 21.019 14.756 21.019 15.711 20.963C16.4581 20.9482 17.1974 20.8066 17.897 20.544C18.4947 20.312 19.0375 19.9581 19.4908 19.5048C19.9442 19.0515 20.2981 18.5086 20.53 17.911C20.793 17.211 20.934 16.473 20.949 15.724C20.992 14.762 21.005 14.457 21.005 12.014C21.003 9.57196 21.003 9.26196 20.947 8.30496ZM11.994 16.602C9.43999 16.602 7.37099 14.533 7.37099 11.979C7.37099 9.42496 9.43999 7.35596 11.994 7.35596C13.2201 7.35596 14.396 7.84302 15.2629 8.71C16.1299 9.57698 16.617 10.7529 16.617 11.979C16.617 13.2051 16.1299 14.3809 15.2629 15.2479C14.396 16.1149 13.2201 16.602 11.994 16.602ZM16.801 8.26296C16.204 8.26296 15.723 7.78096 15.723 7.18496C15.723 7.04346 15.7509 6.90334 15.805 6.77262C15.8592 6.64189 15.9385 6.5231 16.0386 6.42305C16.1386 6.32299 16.2574 6.24363 16.3882 6.18948C16.5189 6.13533 16.659 6.10746 16.8005 6.10746C16.942 6.10746 17.0821 6.13533 17.2128 6.18948C17.3436 6.24363 17.4623 6.32299 17.5624 6.42305C17.6625 6.5231 17.7418 6.64189 17.796 6.77262C17.8501 6.90334 17.878 7.04346 17.878 7.18496C17.878 7.78096 17.396 8.26296 16.801 8.26296Z',
          fill: '#111827',
        }),
        _createElementVNode('path', {
          d: 'M11.994 14.9821C13.6525 14.9821 14.997 13.6376 14.997 11.9791C14.997 10.3206 13.6525 8.97607 11.994 8.97607C10.3355 8.97607 8.99101 10.3206 8.99101 11.9791C8.99101 13.6376 10.3355 14.9821 11.994 14.9821Z',
          fill: '#111827',
        }),
      ]
    )
  );
}
