<template>
  <svg width="142" height="115" viewBox="0 0 142 115" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" clip-path="url(#clip0_9468_15540)">
      <path
        d="M15.2992 100.377C17.5184 98.4549 20.5283 97.375 23.6667 97.375H59.1667C62.3051 97.375 65.3149 98.4549 67.5341 100.377C69.7533 102.299 71 104.907 71 107.625V112.75H11.8333V107.625C11.8333 104.907 13.0801 102.299 15.2992 100.377Z"
        fill="white"
      />
      <path
        d="M128.54 103.136L76.6032 54.3531L84.1772 47.1373L88.0725 50.7452C88.573 51.2162 89.1675 51.59 89.8219 51.845C90.4764 52.1 91.178 52.2312 91.8865 52.2312C92.5951 52.2312 93.2967 52.1 93.9512 51.845C94.6056 51.59 95.2001 51.2162 95.7006 50.7452L99.5418 47.1373C100.043 46.6671 100.441 46.1087 100.713 45.494C100.984 44.8793 101.124 44.2203 101.124 43.5548C101.124 42.8892 100.984 42.2302 100.713 41.6155C100.441 41.0008 100.043 40.4424 99.5418 39.9723L68.9209 11.2107C68.4204 10.7396 67.8259 10.3659 67.1714 10.1109C66.5169 9.85587 65.8153 9.72461 65.1068 9.72461C64.3983 9.72461 63.6967 9.85587 63.0422 10.1109C62.3877 10.3659 61.7932 10.7396 61.2927 11.2107L57.4516 14.8186C55.3416 16.8004 55.3416 20.0018 57.4516 21.9836L61.2927 25.4898L53.6104 32.7565C53.1099 32.2854 52.5154 31.9117 51.861 31.6567C51.2065 31.4017 50.5049 31.2704 49.7964 31.2704C49.0878 31.2704 48.3862 31.4017 47.7317 31.6567C47.0773 31.9117 46.4828 32.2854 45.9823 32.7565C43.8723 34.7383 43.8723 37.9905 45.9823 39.9723L38.3541 47.1373L34.513 43.5294C32.403 41.5476 28.9406 41.5476 26.8307 43.5294L23.0437 47.1373C20.9337 49.1191 20.9337 52.3713 23.0437 54.3531L53.6104 83.0638C55.7204 85.0456 59.1828 85.0456 61.2927 83.0638L65.0797 79.5067C65.5934 79.0379 66.0018 78.477 66.2806 77.8573C66.5595 77.2376 66.7032 76.5716 66.7032 75.8988C66.7032 75.2261 66.5595 74.5601 66.2806 73.9404C66.0018 73.3207 65.5934 72.7598 65.0797 72.2909L61.2927 68.683L68.9209 61.518L120.911 110.352C121.412 110.823 122.006 111.197 122.661 111.452C123.315 111.707 124.017 111.838 124.726 111.838C125.434 111.838 126.136 111.707 126.79 111.452C127.445 111.197 128.039 110.823 128.54 110.352C130.65 108.37 130.65 105.118 128.54 103.136Z"
        fill="white"
      />
      <rect
        width="4.79263"
        height="39.9167"
        transform="matrix(0.728721 -0.68481 0.728721 0.68481 35.2419 44.2168)"
        fill="#F97316"
      />
      <rect
        width="4.79263"
        height="39.9167"
        transform="matrix(0.728721 -0.68481 0.728721 0.68481 54.6858 25.9443)"
        fill="#F97316"
      />
    </g>
    <defs>
      <clipPath id="clip0_9468_15540">
        <rect width="142" height="123" fill="white" transform="matrix(-1 0 0 1 142 0)" />
      </clipPath>
    </defs>
  </svg>
</template>
