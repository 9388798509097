<template>
  <AtContainer short>
    <div class="space-y-12 lg:space-y-18 mb-12 lg:mb-18">
      <div>
        <AwaitingApproveCard v-if="$_at_user.status_key === CLIENT_STATUSES.reviewed" />
        <div class="flex justify-between lg:space-x-20 flex-col space-y-6 md:space-y-0 lg:flex-row mb-24" v-else>
          <div class="space-y-6 w-full">
            <p class="lg:text-3xl text-2xl font-semibold">{{ $t('app.titles.app.title') }}</p>
            <BaseAlert :errors="service.recover.request.errors" :alert="service.recover.request.alert" />
            <BaseAlert :errors="service.increase.request.errors" :alert="service.increase.request.alert" />
            <AtLoading v-if="service.limits.loading || service.recover.limits.loading" />
            <template v-else>
              <RecoverInfo v-if="service.recover.limits.response?.status === 'NEW'">
                <p>
                  {{ $t('app.titles.app.limit.waiting') }}
                </p>
              </RecoverInfo>
              <RecoverInfo v-else-if="service.recover.limits.response?.status === 'REJECTED'">
                <p>
                  {{ $t('app.titles.app.limit.rejected') }}
                </p>
              </RecoverInfo>
              <RecoverInfo v-if="service.increase.limits.response?.status === 'NEW'">
                <p>
                  {{ $t('app.titles.app.limit.increaseLimitWaiting') }}
                </p>
              </RecoverInfo>
              <RecoverInfo v-else-if="service.increase.limits.response?.status === 'REJECTED'">
                <p>
                  {{ $t('app.titles.app.limit.increaseLimitRejected') }}
                </p>
              </RecoverInfo>

              <BalanceCard :limits="service.limits.response" :recover-limits="service.recover.limits.response">
                <BaseAlert :alert="service.recover.request.alert" :errors="service.recover.request.errors" />
                <AtButton
                  block
                  v-if="isRecoverLimitButtonVisible"
                  :loading="isRecoverLimitLoading"
                  @click="requestRecoverLimit"
                >
                  {{ $t('app.titles.app.limit.recoverBtn') }}
                </AtButton>
                <AtButton
                  block
                  v-if="isIncreaseLimitButtonVisible"
                  :loading="isIncreaseLimitLoading"
                  @click="requestIncreaseLimit"
                >
                  {{ $t('app.titles.app.limit.increaseBtn') }}
                </AtButton>
                <div class="flex items-center">
                  <h3 class="text-xl leading-7 font-bold">
                    {{ $t('app.titles.app.status.title') }}:
                    {{ $_at_app._client_statuses[$_at_user.status_key]?.body }}
                  </h3>
                  <CheckCircleIcon class="w-4 h-4 ml-2" />
                </div>
              </BalanceCard>
            </template>
          </div>
          <div class="space-y-6 w-full">
            <p class="lg:text-3xl text-2xl font-semibold">{{ $t('app.titles.app.installments.title') }}</p>
            <AtLoading v-if="service.credits.loading" />
            <template v-else-if="credits.length">
              <Disclosure v-slot="{ open }">
                <DisclosurePanel static class="text-gray-500 space-y-6">
                  <PaymentCard
                    v-for="credit in credits.slice(0, DEFAULT_CREDITS_CARD)"
                    :key="credit.id"
                    :credit="credit"
                  />
                </DisclosurePanel>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <div v-show="open">
                    <DisclosurePanel static class="text-gray-500 space-y-6">
                      <PaymentCard
                        v-for="credit in credits.slice(DEFAULT_CREDITS_CARD, credits.length)"
                        :key="credit.id"
                        :credit="credit"
                      />
                    </DisclosurePanel>
                  </div>
                </transition>
                <DisclosureButton
                  v-if="credits.length > DEFAULT_CREDITS_CARD"
                  class="text-primary-600 flex items-center mx-auto"
                >
                  <span v-if="open">{{ $t('app.titles.app.installments.hide') }}</span>
                  <span v-else>{{ $t('app.titles.app.installments.show') }}</span>
                  <ChevronDownIcon class="inline-block w-5 h-5" :class="open ? 'transform rotate-180' : ''" />
                </DisclosureButton>
              </Disclosure>
            </template>
            <template v-else>
              <div class="text-center space-y-4 py-14">
                <div class="rounded-full w-16 h-16 flex justify-center items-center bg-gray-100 mx-auto">
                  <CircleStackIcon class="w-7 h-7 text-gray-500" />
                </div>
                <p class="text-gray-500">
                  {{ $t('app.titles.app.installments.notFound.active') }}
                </p>
              </div>
            </template>
          </div>
        </div>
        <div>
          <div class="lg:mb-9 mb-7">
            <div v-if="$_at_user.status_key === CLIENT_STATUSES.reviewed">
              <h3 class="lg:text-3xl text-xl text-center font-semibold max-w-lg m-auto">
                {{ $t('app.titles.app.reviewProgress.awaiting') }}
              </h3>
              <h3 class="lg:text-3xl text-xl text-center font-semibold max-w-lg m-auto">
                {{ $t('app.titles.app.limit.question') }}
              </h3>
            </div>
            <div v-else class="text-center">
              <div
                v-if="service.limits?.response?.current_amount"
                class="lg:text-3xl text-xl text-center font-semibold lg:max-w-lg m-auto"
              >
                <h3>
                  {{ $t('app.titles.app.reviewProgress.hasLimit', { amount: usersCurrentAmount }) }}
                </h3>
                <h3>
                  {{ $t('app.titles.app.limit.question') }}
                </h3>
              </div>
              <div v-else class="lg:text-3xl text-xl text-center font-semibold lg:max-w-lg m-auto">
                <h3>
                  {{ $t('app.titles.app.reviewProgress.noLimit') }}
                </h3>
              </div>
            </div>
          </div>
          <div class="lg:bg-none mx-0 lg:space-y-6 w-full lg:m-auto">
            <div
              class="lg:rounded-2xl space-y-6 lg:space-y-0 -mx-4 sm:-mx-6 lg:mx-0 lg:px-28 flex flex-col lg:flex-row justify-center bg-gray-50 pt-0 lg:p-0 pb-9 lg:pb-0 p-4"
            >
              <div class="flex justify-center">
                <img :src="phoneImage" alt="phone" class="max-h-72" />
              </div>
              <div class="flex flex-col justify-center space-y-6 lg:ml-6">
                <img :src="alifLogo" alt="logo" class="w-20 h-6" />
                <div>
                  <p class="text-xl font-semibold leading-7" data-testid="alifshop_title">
                    {{ $t('app.titles.banners.alifshop.title') }}
                  </p>
                  <p class="text-gray-500 text-sm mt-1 font-medium max-w-sm">
                    {{ $t('app.titles.banners.alifshop.subTitle') }}
                  </p>
                  <p class="text-gray-500 text-sm mt-1 font-medium max-w-sm">
                    {{ $t('app.titles.banners.alifshop.subTitle2') }}
                  </p>
                </div>
                <BaseAlifshopButton class="w-full lg:w-48" data-testid="to_alifshop_button" />
              </div>
            </div>
            <div
              class="lg:rounded-2xl space-y-6 lg:space-y-0 -mx-4 sm:-mx-6 lg:mx-0 lg:px-28 flex flex-col lg:flex-row-reverse justify-center bg-gray-50 lg:border-none border-t border-gray-200 pt-0 p-4 pb-9 lg:pb-0"
            >
              <div class="flex justify-center">
                <img :src="shopsImage" alt="shops" class="max-h-72" />
              </div>
              <div class="flex flex-col justify-center space-y-6 lg:mr-6">
                <div>
                  <p class="text-xl font-semibold leading-7">{{ $t('app.titles.banners.partners.title') }}</p>
                  <p class="text-sm text-gray-500 mt-1 max-w-sm">
                    {{ $t('app.titles.banners.partners.subTitle') }}
                  </p>
                  <p class="text-sm text-gray-500 mt-1 max-w-md">
                    {{ $t('app.titles.banners.partners.subTitle2') }}
                  </p>
                </div>
                <AtButton
                  class="lg:mt-auto lg:self-start"
                  color="primary"
                  :to="{ name: 'Shops.Index' }"
                  data-testid="shops_link"
                >
                  {{ $t('app.titles.banners.partners.button') }}
                </AtButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFAQ />
      <AppContacts />
    </div>
  </AtContainer>
</template>

<script setup>
  import { reactive, computed, h, ref, onMounted } from 'vue';
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
  import { ChevronDownIcon, CircleStackIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';

  import AtLoading from '~/plugins/aliftech-ui/components/AtLoading/AtLoading';

  import AtContainer from '~/components/ui/AtContainer.vue';
  import AtButton from '~/components/aliftech-ui/AtButton/AtButton';
  import BalanceCard from '~/components/app/BalanceCard.vue';
  import PaymentCard from '~/components/app/PaymentCard.vue';
  import { getCredits } from '~/services/credits.api';
  import { useService } from '~/hooks/useService';
  import {
    getLimits,
    getRecoverLimitsStatus,
    recoverLimits,
    getUpgradeLimitsStatus,
    upgradeLimits,
  } from '~/services/profile.api';
  import { $_at_app } from '~/shared/at-app';
  import { $_at_user } from '~/shared/at-user';
  import { CREDITS_STATUSES_KEYS } from '~/constants/installments';
  import AwaitingApproveCard from '~/components/app/AwaitingApproveCard.vue';
  import phoneImage from '~/assets/images/show-mobile.png';
  import alifLogo from '~/assets/icons/alifshop-logo.svg';
  import shopsImage from '~/assets/images/show-shops.png';
  import AppFAQ from '~/components/app/AppFAQ.vue';
  import AppContacts from '~/components/app/AppContacts.vue';
  import { CLIENT_STATUSES } from '~/constants';
  import BaseAlifshopButton from '~/components/base/BaseAlifshopButton.vue';
  import BaseAlert from '~/components/base/BaseAlert.vue';

  const DEFAULT_CREDITS_CARD = 3;

  const RecoverInfo = (props, context) => {
    return h(
      'div',
      {
        class: 'bg-primary-100 p-6 rounded-2xl font-medium text-base leading-5 text-primary-700 space-y-1',
      },
      context.slots.default()
    );
  };

  const service = {
    credits: reactive(useService(getCredits)),
    limits: reactive(useService(getLimits)),
    recover: {
      limits: reactive(useService(getRecoverLimitsStatus)),
      request: reactive(useService(recoverLimits)),
    },
    increase: {
      limits: reactive(useService(getUpgradeLimitsStatus)),
      request: reactive(useService(upgradeLimits)),
    },
  };

  service.credits.execute();
  service.limits.execute();
  service.recover.limits.execute();
  service.increase.limits.execute();

  const isLimitFrozen = computed(() => {
    return service.limits.response?.frozen;
  });

  const LIMIT_INCREASE_THRESHOLD = 15_000_000;
  const isIncreaseLimitButtonVisible = computed(() => {
    const currentLimitAmount = service.limits.response?.current_amount / 100;
    const increaseStatus = service.increase.limits.response?.status;
    return (
      increaseStatus !== 'NEW' &&
      !isLimitFrozen.value &&
      currentLimitAmount >= 0 &&
      currentLimitAmount < LIMIT_INCREASE_THRESHOLD
    );
  });

  const OVERDUE_CREDIT_STATUSES = [CREDITS_STATUSES_KEYS.overdue, CREDITS_STATUSES_KEYS.problem];
  const hasOverdueCredit = computed(() => {
    return service.credits.response?.data?.some(credit => OVERDUE_CREDIT_STATUSES.includes(credit?.status_key));
  });
  const isRecoverLimitButtonVisible = computed(() => {
    const recoverStatus = service.recover.limits.response?.status;

    return recoverStatus !== 'NEW' && isLimitFrozen.value && !hasOverdueCredit.value;
  });

  const usersCurrentAmount = computed(() => {
    return service.limits?.response?._current_amount;
  });

  const isIncreaseLimitLoading = ref(false);
  const requestIncreaseLimit = async () => {
    isIncreaseLimitLoading.value = true;
    await service.increase.request.execute().catch(() => (isIncreaseLimitLoading.value = false));
    await Promise.all([service.increase.limits.execute()]).finally(() => (isIncreaseLimitLoading.value = false));
  };

  const isRecoverLimitLoading = ref(false);
  const requestRecoverLimit = async () => {
    isRecoverLimitLoading.value = true;
    await service.recover.request.execute().catch(() => (isRecoverLimitLoading.value = false));
    await Promise.all([service.limits.execute(), service.recover.limits.execute()]).finally(
      () => (isRecoverLimitLoading.value = false)
    );
  };

  const EXCLUDED_CREDIT_STATUSES = [CREDITS_STATUSES_KEYS.deleted, CREDITS_STATUSES_KEYS.closed];
  const credits = computed(() =>
    service.credits.response?.data?.filter(credit => !EXCLUDED_CREDIT_STATUSES.includes(credit?.status_key))
  );

  onMounted(() => {
    const inFrame = window.location !== window.parent.location;
    const isRegistered = $_at_user.value?.is_identified || $_at_user.value?.status_key === 'REVIEWED' || false;

    if (inFrame && isRegistered) {
      window.top.postMessage('alifnasiya-authorized', '*');
      window.top.postMessage(
        {
          type: 'alifnasiya-authorized',
          clientId: $_at_user.value?.clientId,
        },
        '*'
      );
    }
  });
</script>
