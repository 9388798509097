<template>
  <template v-if="requestDelayStatus === STATUSES.consideration">
    <div class="relative flex-grow flex items-center justify-center">
      <div class="w-full m-auto" style="max-width: 353px">
        <div class="space-y-4">
          <div class="bg-green-50 rounded-full w-16 h-16 flex justify-center items-center text-primary-600 mx-auto">
            <CheckCircleIcon class="w-7 h-7" style="min-width: 28px" />
          </div>
          <div class="space-y-2 text-center">
            <div class="text-base font-bold">
              {{ $t('app.fields.delays.accepted') }}
            </div>
            <div class="text-sm">
              {{ $t('app.fields.delays.instruction') }}
            </div>
          </div>
          <AtButton size="lg" color="primary" class="w-full" @click="goBack">
            {{ $t('system.btns.understand') }}
          </AtButton>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="px-4 pb-20 min-h-screen">
      <div class="w-full m-auto" style="max-width: 440px">
        <div class="space-y-6">
          <div class="space-y-4">
            <router-link
              :to="{ name: 'Installments.Show', params: { id: installmentId } }"
              class="text-primary-500 flex items-center space-x-1"
            >
              <ChevronLeftIcon class="w-5 h-5" style="min-width: 20px" />
              <span> {{ $t('system.btns.back') }} </span>
            </router-link>
            <AtTitle> {{ $t('app.fields.delays.title') }} </AtTitle>
          </div>
          <div class="space-y-4">
            <template v-if="service.credit.loading">
              <AtSkeleton style="height: 82px" />
            </template>
            <template v-else>
              <div>
                <AtPopover
                  :offset="[16, -8]"
                  placement="bottom"
                  alignment="start"
                  :max-width="296"
                  button-class="w-full"
                  panel-class="border-2 border-gray-100 rounded-md bg-white"
                >
                  <template #button>
                    <div class="text-left w-full shadow border border-gray-100 rounded-2xl p-4 bg-white">
                      <div class="flex flex-col space-y-1">
                        <span class="text-sm">{{ $t('app.titles.installment.dates.delay') }}</span>
                        <span class="text-base text-primary-600">
                          {{ displayedDate }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template #default="{ close }">
                    <AtCalendar
                      v-model="fields.dateTo"
                      :locale="locale"
                      :allowed-dates="allowedDates"
                      @update:model-value="close"
                    />
                  </template>
                </AtPopover>
                <BaseFieldErrors
                  :errors="$v.dateTo.$errors.length ? $v.dateTo.$errors : service?.delay?.errors?.date_to"
                />
              </div>
              <div class="inline-flex items-center space-x-3">
                <AtCheckbox
                  v-model="fields.withShift"
                  :disabled="service.delay.loading"
                  :label="$t('app.fields.shift')"
                />
                <button @click="modals.shift.isOpen = true">
                  <QuestionMarkCircleIcon class="w-5 h-5 text-gray-400" style="min-width: 20px" />
                </button>
              </div>
            </template>
          </div>
          <div>
            <AtSelect
              v-model="fields.reasonId"
              :options="reasons"
              :disabled="service.delay.loading"
              :label="$t('app.fields.cause')"
              :placeholder="$t('system.fields.selectValue')"
            />
            <BaseFieldErrors
              :errors="$v.reasonId.$errors.length ? $v.reasonId.$errors : service?.delay?.errors?.request_reason_id"
            />
          </div>
          <div>
            <AtTextarea v-model="fields.note" :disabled="service.delay.loading" :label="$t('app.fields.delays.why')" />
            <BaseFieldErrors :errors="$v.note.$errors.length ? $v.note.$errors : service?.delay?.errors?.note" />
          </div>
          <BaseAlert :alert="service?.delay.alert" :errors="service.delay.errors" />
          <AtButton
            color="primary"
            size="lg"
            class="w-full"
            :disabled="service.credit.loading"
            :loading="service.delay.loading"
            @click="requestCreditDelay"
          >
            {{ $t('app.btns.request') }}
          </AtButton>
        </div>
      </div>
    </div>
    <AtModal v-model="modals.shift.isOpen" :title="$t('app.titles.installment.postponementWithShift')" size="sm">
      <template #default="{ close }">
        <div class="space-y-5 pt-1">
          <div class="space-y-6">
            <div class="text-sm text-gray-500">
              {{ $t('app.titles.installment.shift.info[0]') }}
              <span class="text-gray-900">{{ $t('app.titles.installment.shift.info[1]') }}</span>
              {{ $t('app.titles.installment.shift.info[2]') }}
            </div>
            <div class="text-sm text-gray-500">
              {{ $t('app.titles.installment.shift.instruction') }}
            </div>
          </div>
          <AtButton class="w-full" size="lg" @click="close">{{ $t('system.btns.close') }}</AtButton>
        </div>
      </template>
    </AtModal>
  </template>
</template>

<script setup>
  import { computed, reactive, onBeforeMount, ref, inject } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { QuestionMarkCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';
  import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import useVuelidate from '@vuelidate/core';
  import { required, helpers } from '@vuelidate/validators';
  import { useI18n } from 'vue-i18n';

  import AtTitle from '~/components/ui/AtTitle.vue';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import AtCalendar from '~/plugins/aliftech-ui/components/AtCalendar/AtCalendar';
  import AtSkeleton from '~/plugins/aliftech-ui/components/AtSkeleton/AtSkeleton';
  import AtSelect from '~/plugins/aliftech-ui/components/AtSelect/AtSelect';
  import AtCheckbox from '~/plugins/aliftech-ui/components/AtCheckbox/AtCheckbox';
  import AtTextarea from '~/plugins/aliftech-ui/components/AtTextarea/AtTextarea';
  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtPopover from '~/plugins/aliftech-ui/components/AtPopover/AtPopover';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import { transformDate } from '~/utils';
  import { getCredit, requestDelay } from '~/services/credits.api';
  import { useService } from '~/hooks/useService';
  import BaseFieldErrors from '~/components/base/BaseFieldErrors.vue';
  import { $_at_app } from '~/shared/at-app';
  import { checkType } from '~/plugins/aliftech-ui/utils';

  const { t, locale } = useI18n();

  // in days
  const ALLOWED_DELAY_DURATION = 30;

  const STATUSES = {
    success: 'seccess',
    consideration: 'consideration',
  };

  const defaultFields = {
    dateTo: null,
    reasonId: null,
    withShift: false,
    note: null,
  };

  const rules = {
    dateTo: {
      required: helpers.withMessage(t('validations.required'), required),
    },
    reasonId: {
      required: helpers.withMessage(t('validations.required'), required),
    },
    note: {
      required: helpers.withMessage(t('validations.required'), required),
    },
  };

  const $toast = inject('$toast');

  const route = useRoute();
  const router = useRouter();
  const service = {
    credit: reactive(useService(getCredit)),
    delay: reactive(useService(requestDelay)),
  };

  const fields = reactive({ ...defaultFields });
  const modals = reactive({
    shift: {
      isOpen: false,
    },
  });
  const requestDelayStatus = ref(null);

  const installmentId = computed(() => route?.params?.id);
  const displayedDate = computed(() => {
    return transformDate(fields.dateTo, { dateFormat: 'dd MMMM' }) || t('system.btns.selectDate');
  });
  const nearUnpaidPayment = computed(() => {
    if (Object.prototype.toString.call(service?.credit?.response?.schedule_cup_payments) === '[object Array]') {
      return service?.credit?.response?.schedule_cup_payments?.find(payment => payment._canPay);
    }
    return null;
  });
  const addDayToDate = (date, day) => {
    date.setDate(date.getDate() + day);
    return date;
  };
  const allowedDates = computed(() => {
    if (!nearUnpaidPayment.value && !nearUnpaidPayment.value?._payment_date) return;
    const currentDate = new Date();
    const payment_date = new Date(nearUnpaidPayment.value._payment_date);
    const fromDate = payment_date > currentDate ? addDayToDate(payment_date, 1) : currentDate;

    return {
      from: fromDate,
      to: new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate() + ALLOWED_DELAY_DURATION),
    };
  });
  const reasons = computed(() => {
    if (!checkType($_at_app.value?.delay_request_reasons, 'array')) return [];

    return $_at_app.value?.delay_request_reasons.map(reason => ({
      title: reason?.[`body_${locale.value}`],
      value: reason?.id,
    }));
  });

  const requestCreditDelay = async () => {
    if (!(await $v.value.$validate())) return;

    const { dateTo, reasonId, withShift, note } = fields;

    const payload = {
      date_to: transformDate(dateTo, { dateFormat: 'yyyy-MM-dd' }),
      request_reason_id: reasonId,
      is_shifted: withShift,
      note,
    };
    service.delay.execute(installmentId.value, payload).then(() => {
      // TODO: set status from response;
      requestDelayStatus.value = STATUSES.success;

      if (requestDelayStatus.value === STATUSES.success) {
        goBack();
        $toast.success({
          title: t('app.titles.installment.delayed.success'),
        });
      }
    });
  };
  const goBack = () => {
    router.push({
      name: 'Installments.Show',
      params: { id: installmentId.value },
    });
  };

  const $v = useVuelidate(rules, fields);

  onBeforeMount(() => {
    service.credit.execute(installmentId.value);
  });
</script>
