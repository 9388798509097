<template>
  <div v-if="hasAlert || hasErrors" class="space-y-3" ref="baseAlertRef">
    <AtAlert v-if="hasAlert" :type="alert?.type" :title="alert?.title" :dismissible="alert?.dismissible">
      {{ alert?.message ?? 'Что-то пошло не так, попробуйте позже' }}
    </AtAlert>
    <AtAlert v-if="hasErrors" type="danger" dismissible>
      <li v-for="(value, index) in errors" :key="index">{{ index }}: {{ value }}</li>
    </AtAlert>
  </div>
</template>

<script setup>
  import { computed, nextTick, ref, watch } from 'vue';

  import AtAlert from '~/plugins/aliftech-ui/components/AtAlert/AtAlert';
  import { scrollToElement } from '~/utils/scrollToElement';

  const props = defineProps({
    alert: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: [Array, Object],
      default: null,
    },
  });

  watch(
    () => props.alert,
    value => {
      if (value && Object.keys(value).length)
        nextTick(() => {
          scrollToElement(baseAlertRef.value);
        });
    },
    { deep: true }
  );
  watch(
    () => props.errors,
    value => {
      if (value && value?.length)
        nextTick(() => {
          scrollToElement(baseAlertRef.value);
        });
    },
    { deep: true }
  );

  const baseAlertRef = ref(null);

  const hasAlert = computed(() => {
    return Object.keys(props.alert || {})?.length;
  });
  const hasErrors = computed(() => {
    return props.errors?.length || Object.keys(props.errors || {})?.length;
  });
</script>
