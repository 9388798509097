<template>
  <div
    class="flex-grow bg-gray-100 p-4 md:p-6 rounded-2xl flex items-center justify-between space-x-2 hover:bg-gray-200"
  >
    <div class="space-x-4 flex items-center">
      <div
        class="flex items-center justify-center rounded-lg"
        :style="{
          width: '64px',
          height: '48px',
          background: backgroundColor,
        }"
      >
        <img :src="paymentSystemIcon" alt="" />
      </div>
      <div class="flex justify-center flex-col space-y-1">
        <div v-if="cardName" class="font-medium">
          {{ cardName }}
        </div>
        <div class="space-x-2 text-sm text-gray-500">
          <span> **** {{ card?.card_number.slice(-4) }} </span>
          <template v-if="card?.is_main">
            <span> • </span>
            <span> {{ $t('system.btns.cardIsMain') }} </span>
          </template>
        </div>
      </div>
    </div>
    <slot name="end"></slot>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  import { getCardBackground, getCardsBankName } from '~/shared/card';
  import * as paymentSystemIcons from '~/assets/icons/paymentSystems';
  import { CARDS_TYPES } from '~/constants/cards';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const props = defineProps({
    card: {
      type: Object,
      default: () => ({}),
    },
  });

  const bank = computed(() => {
    return getCardsBankName(props.card?.card_number);
  });
  const cardType = computed(() => {
    return bank.value?.type || props.card?.type;
  });
  const backgroundColor = computed(() => {
    return getCardBackground(cardType.value);
  });
  const paymentSystemIcon = computed(() => {
    return Object.keys(paymentSystemIcons).includes(cardType.value)
      ? paymentSystemIcons[cardType.value]
      : paymentSystemIcons.other;
  });
  const cardName = computed(() => {
    return (
      props.card?.full_name ||
      bank.value?.bank ||
      (cardType.value !== CARDS_TYPES.other ? cardType.value : t('system.fields.noName'))
    );
  });
</script>
