import { createI18n } from 'vue-i18n';
import ru from './ru';
import uz from './uz';

const DEFAULT_LOCALE = 'uz';

const customRUPluralRule = (choice, choicesLength) => {
  if (choice === 0) {
    return 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};

const i18n = createI18n({
  legacy: false,
  locale: DEFAULT_LOCALE,
  fallbackLocale: DEFAULT_LOCALE,
  pluralRules: {
    ru: customRUPluralRule,
  },
  messages: {
    ru,
    uz,
  },
});

export default i18n;
