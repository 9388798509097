<template>
  <router-link
    :to="{ name: 'Installments.Show', params: { id: credit.id } }"
    class="min-h-176 flex flex-col group bg-white text-gray-900 overflow-hidden rounded-2xl border border-gray-100 shadow"
  >
    <div class="h-full px-4 py-5 sm:p-6 rounded-b-2xl flex flex-col">
      <div class="flex items-center justify-between">
        <p class="text-sm leading-5 font-medium text-gray-500">{{ credit.contract_number }}</p>
        <span class="text-sm leading-5 font-medium" :class="creditColors[credit.status_key]">{{
          $_at_app?.credit_statuses?.[credit.status_key]?.[`body_${globalState.locale}`]
        }}</span>
      </div>
      <div class="mt-4">
        <div
          class="text-2xl leading-8 text-gray-900 font-bold group-hover:text-primary-600 transition ease-out duration-300 cursor-pointer"
        >
          <template v-if="credit.next_payment?._payment_amount">
            {{ credit.next_payment._payment_amount }}
            {{ $t('system.titles.sum') }}
          </template>
          <template v-else-if="credit.status_key === 'CLOSED'">
            {{ $t('app.titles.app.installments.fullyPaid') }}
          </template>
        </div>
        <div class="flex-1 mt-6" v-if="credit.next_payment?.payment_date">
          <p class="text-gray-900 text-sm">
            {{ $t('app.titles.app.next_payment') }} —
            {{ transformDate(credit.next_payment?.payment_date, { dateFormat: 'dd MMMM yyyyг' }) }}
          </p>
          <p class="text-gray-900 text-sm" v-if="(credit.meta?.completion ?? 0) === 100">
            {{ $t('app.titles.app.installments.fullyPaid') }}
          </p>
        </div>
      </div>
      <div class="flex space-x-3 mt-auto">
        <div class="flex-1">
          <p class="text-gray-900 text-sm">
            {{ credit.info_credit.store_name }}
          </p>
        </div>
        <div
          class="flex-shrink-0 self-center group-hover:text-primary-600 transition ease-out duration-300 cursor-pointer"
        >
          <ArrowRightIcon class="w-5 h-5" />
        </div>
      </div>
    </div>
    <div class="bg-gray-100 h-2 w-full mt-auto">
      <div class="bg-primary-600 h-2 rounded-r" :style="`width:${credit?.meta?.completion ?? 0}%`"></div>
    </div>
  </router-link>
</template>

<script setup>
  import { transformDate } from '~/utils';
  import { ArrowRightIcon } from '@heroicons/vue/20/solid';
  import { reactive } from 'vue';
  import { $_at_app } from '~/shared/at-app';
  import { globalState } from '~/shared/globalState';

  defineProps({
    credit: {
      type: Object,
      required: true,
    },
  });

  const creditColors = reactive({
    ACTIVE: 'text-primary-600',
    CLOSED: 'text-gray-900',
    OVERDUE: 'text-yellow-600',
    PAUSED: 'text-gray-900',
    PROBLEM: 'text-red-600',
  });
</script>

<style lang="scss" scoped>
  .min-h-176 {
    min-height: 176px;
  }
</style>
