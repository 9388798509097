<template>
  <AtModal
    :model-value="modelValue"
    :title="title"
    size="sm"
    @update:model-value="val => $emit('update:modelValue', val)"
  >
    <div class="flex flex-col space-y-5">
      <div class="space-y-4">
        <div class="flex flex-col space-y-1">
          <span class="text-gray-500">
            {{ $t('app.titles.installment.payment.amount') }}
          </span>
          <span class="text-gray-900 font-medium">
            {{ payment?._formatted_payment_amount || 0 }}
            {{ $t('system.titles.sum') }}
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="text-gray-500">
            {{ $t('system.fields.card.payment') }}
          </span>
          <span class="text-gray-900 font-medium"> {{ cardName }} *{{ card?.card_number?.slice(-4) }} </span>
        </div>
      </div>
      <div>
        <router-link
          :to="{ name: 'Installments.Show.Custom' }"
          class="flex-grow space-x-2 bg-gray-100 p-4 flex items-center justify-between rounded-2xl hover:bg-gray-200"
        >
          <span class="text-gray-900">
            {{ $t('app.fields.payment.customAmount') }}
          </span>
          <ChevronRightIcon class="h-6 w-6 text-gray-500" style="min-width: 24px" />
        </router-link>
      </div>
      <BaseAlert :alert="alert" :errors="errors" />
      <AtButton color="primary" size="lg" :loading="loading" @click="pay">
        {{ $t('app.btns.pay') }}
      </AtButton>
    </div>
  </AtModal>
</template>

<script setup>
  import { computed } from 'vue';
  import { ChevronRightIcon } from '@heroicons/vue/20/solid';

  import AtModal from '~/plugins/aliftech-ui/components/AtModal/AtModal';
  import AtButton from '~/plugins/aliftech-ui/components/AtButton/AtButton';
  import BaseAlert from '~/components/base/BaseAlert.vue';
  import { transformDate } from '~/utils';
  import { getCardsBankName } from '~/shared/card';
  import { useService } from '~/hooks/useService';
  import { payCredit } from '~/services/credits.api';
  import { CARDS_TYPES } from '~/constants/cards';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const { execute, loading, alert, errors } = useService(payCredit);

  const title = computed(() => {
    const date = transformDate(props.payment?._payment_date, {
      dateFormat: 'd MMMM',
    });
    return t('app.titles.installment.payment.payFor', { date });
  });
  const bank = computed(() => {
    return getCardsBankName(props.card?.card_number);
  });
  const cardType = computed(() => {
    return bank.value?.type || props.card?.type;
  });
  const cardName = computed(() => {
    return (
      props.card?.full_name ||
      bank.value?.bank ||
      (cardType.value !== CARDS_TYPES.other ? cardType.value : t('system.fields.noName'))
    );
  });

  const pay = () => {
    execute(props.installmentId, Number(props.payment?._payment_amount) * 100).then(() => {
      emit('update:modelValue', false);
      emit('pay');
    });
  };

  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object,
      default: () => ({}),
    },
    card: {
      type: Object,
      default: () => ({}),
    },
    installmentId: {
      type: [String, Number],
      default: null,
    },
  });
  const emit = defineEmits(['update:modelValue', 'pay']);
</script>
